import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import Empty from '@/components/empty'
import beijing from '@/assets/images/bgc.png'

import { Tabs } from 'antd';

 const Coupon = () => {
   document.title = '我的优惠券'
   const [coupon, setCoupon] = useState({})
  useEffect(() => {
   let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 1
    })
    axios.post('Coupon/myCoupon', params).then((res) => {
      console.log(res.result.list)
      setCoupon(res.result)
    })
  }, [])
   
   const { TabPane } = Tabs;

  function callback(key) {
    console.log(key);
  }

   return (
     <div className="coupon">
       <div className="coupon-content">
        <Tabs defaultActiveKey="1" onChange={callback} className="content">
          <TabPane tab="可使用" key="1" className="content-top">
             <div className="available">
                <div>
                  {
                    Array.isArray(coupon.list) && coupon.list.map((element,index) => {
                      return (
                        <div className="available-content" key={index}>
                          <div className="available-content-top">
                            <img src={beijing} alt=""/>
                            <p className="money">￥ <span className="money-word">{element.money}</span></p>
                            <p className="condition">满{element.condition}使用</p>
                          </div>
                          <div className="available-content-nva">
                            <p className="nav-rule">使用规则：全场通用</p>
                            <p className="nav-time">有效时间：<span>{element.use_start_time}</span>-<span>{element.use_end_time}</span></p>
                          </div>
                          <div className="available-content-bottom">
                            <p>立即使用</p>
                          </div>
                        </div>
                      )
                    })
                  }
                  {(!Array.isArray(coupon.list) || !coupon.list.length) && (
                    <div>
                      <Empty text="暂无订单" />
                    </div>
                  )}
                </div>
                
             </div>
          </TabPane>
          <TabPane tab="已使用" key="2" className="content-top">
             <div>
               <Empty text='暂无优惠券' className="deliver "/>
             </div>
          </TabPane>
          <TabPane tab="已失效" key="3" className="content-top">
             <div>
               <Empty text='暂无优惠券' className="deliver "/>
             </div>
          </TabPane>
        </Tabs>
       </div>
     </div>
   )
 }

 export default Coupon