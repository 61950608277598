import React, {useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookie from 'js-cookie'
import Empty from '@/components/empty'


const Transaction = () => {
  document.title = '交易记录'
  const [transaction, setTransaction] = useState({})
  useEffect(() => {
    let token =Cookie.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('User/rebateList', params).then((res) => {
      console.log(res);
      setTransaction(res.result)
    })
  }, []);

  return (
    <div className="transaction">
      <div className="transaction-content">
        {/* 头部 */}
        <div className="transaction-top">
          <span className="transaction-number">好友购买记录 : {transaction.total}人</span>
          <span className="transaction-reward">奖励 : ￥ {transaction.total_money}</span>
          <span className="transaction-money">佣金</span>
        </div>
        {/* 标题部分 */}
        <div className="transaction-title">
          <ul className="title">
            <li>推荐客户</li>
            <li>电话</li>
            <li>订单信息</li>
            <li>分享时间</li>
            <li>购买时间</li>
            <li>获取佣金</li>
          </ul>
        </div>
        {/* 交易内容 */}
        <div className="share-thing">
          <Empty text='暂无交易记录'/>
        </div>
      </div>
    </div>
  )
}

export default Transaction





