import React, { memo } from 'react'
import './index.scss'

const Recommend = ({ list }) => {
  return (
    <>
      {
        list && list.map((element, index) => {
          return (
            <div className='recommend' key={index}>
              <div className='recommend-top'>
                <div className='title'>{element.name}</div>
                <div className='recommend-type'>
                  {
                    Array.isArray(element.goods_category) && element.goods_category.map((el, i) => {
                      return (
                        <div className='recommend-type-item' key={i}>
                          {el.name}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className='recommend-context'>
                <div className='main-img'>
                  <div className='show-more'>
                    <span className='recommend-introduce'>温润生活，尽享品质</span>
                    <span className='show-more-text'>
                      查看更多
              <i className='iconfont icon-right-double'></i>
                    </span>
                  </div>
                  <img src={element.goodsList[0].original_img} alt='推荐' />
                </div>
                <div className='more-img'>
                  <div className='more-img1'>
                    <img src={element.goodsList[1].original_img} alt='推荐' />
                  </div>
                  <div className='more-img2'>
                    <img src={element.goodsList[2].original_img} alt='推荐' />
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </>
  )
}

export default memo(Recommend)