import React, { memo } from 'react'
import './index.scss'

import room from '@/assets/images/room.jpg'

const Intelligence = () => {
  return (
    <div className='intelligence'>
      <div className='intelligence-left'>
        <div className='intelligence-left-img'>
          <p>3000+模型量，80套软装</p>
          <p>70套VR样板间</p>
          <p>一键匹配，随心所得</p>
        </div>
      </div>
      <div className='intelligence-right'>
        <div className='intelligence-right-top'>
          <div className='intelligence-describe'>
            5D案例设计是一款以设计为导向，匹配 用户实用方案所得的5D软件设计，软件 同步匹配在线商品模型化、样板间匹配化， 硬装材料同步化等功能，展示给直接用户。
          </div>
          <img src={room} alt='人工智能时代' />
        </div>
        <div className='intelligence-right-bottom'>
          <img src={room} alt='人工智能时代' />
          <div className='intelligence-describe'>
            5D案例设计是一款以设计为导向，匹配 用户实用方案所得的5D软件设计，软件 同步匹配在线商品模型化、样板间匹配化， 硬装材料同步化等功能，展示给直接用户。
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Intelligence)