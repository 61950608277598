import React, { useState, useEffect } from 'react'
import axios from '@/api'
import { Table, Modal, message, InputNumber } from 'antd';
import Lnk from '@/components/lnk'
import Qs from 'qs'
import Cookies from 'js-cookie'
import './index.scss'
// import Empty from '@/components/empty'

const ShopCar = (props) => {
  document.title = '购物车'
  let token = Cookies.get('token')
  const [isDeleteModal, setIsDeleteModal] = useState(false) // 是否显示删除弹框
  const [deleteId, setDeleteId] = useState('') // 当前需要删除的id
  const [orderPriceInfo, setOrderPriceInfo] = useState({}) // 订单价格相关信息
  const [selectCommodityLength, setSelectCommodityLength] = useState(0)
  const [isCleanModal, setIsCleanModal] = useState(false) // 购物车弹框
  const [nowSelectCommodity, setNowSelectCommodity] = useState([]) // 当前选择的商品
  const [shopCartColumns] = useState([
    {
      title: '',
      dataIndex: 'pic',
      render: pic => (
        <img style={{ width: '100px', height: '60px', borderRadius: '5px' }} src={pic} alt="商品" />
      )
    },
    {
      title: '商品名称',
      dataIndex: 'goods_name'
    },
    {
      title: '单价',
      dataIndex: 'goods_price'
    },
    {
      title: '数量',
      render: event => (
        <InputNumber min={1} precision={0} defaultValue={event.goods_num} onChange={(value) => $onChangeCommodityNumber(value, event)} />
      )
    },
    {
      title: '小计(元)',
      render: event => (
        <span>{(event.goods_price * event.goods_num).toFixed(2)}</span>
      )
    },
    {
      title: '操作',
      dataIndex: 'cart_id',
      render: cart_id => (
        <i className='iconfont icon-delete' onClick={() => {
          setIsDeleteModal(true)
          setDeleteId(cart_id)
        }} style={{ fontSize: '24px', cursor: 'pointer' }} />
      )
    }
  ])
  const [shopCartData, setShopCartData] = useState([])

  useEffect(() => {
    $getShopCartList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  // 获取购物车列表
  const $getShopCartList = () => {
    axios.post('Cart/cartList', Qs.stringify({
      token,
      channel: 1
    })).then(res => {
      let shopList = res.result.cartShop || []
      let commodityList = []
      shopList.forEach(element => {
        commodityList = commodityList.concat(element.goodsList)
      })
      setShopCartData(commodityList)
      console.log(res.result)
    })
  }

  // 改变商品数量
  const $onChangeCommodityNumber = (value, event) => {
    let params = Qs.stringify({
      token,
      channel: 1,
      cart_id: event.cart_id,
      num: value
    })
    axios.post('Cart/updateNum', params).then(res => {
      let totalPrice = res.result.total_price
      setOrderPriceInfo(totalPrice)
      $getShopCartList()
    })
  }

  // 选择商品
  const $onChangeSelectCommodity = (event) => {
    let cart_id = event.join(',')
    setNowSelectCommodity(event)
    let params = Qs.stringify({
      token,
      cart_id,
      channel: 1,
      selected: 1 // 选择 1 取消全选 0
    })
    axios.post('Cart/updateSelect', params).then(res => {
      let totalPrice = res.result.total_price
      setSelectCommodityLength(event.length)
      setOrderPriceInfo(totalPrice)
    })
  }

  // 删除商品
  const $onClickDeleteCommodity = () => {
    setIsDeleteModal(false)
    let params = Qs.stringify({
      token,
      channel: 1,
      cart_id: deleteId
    })
    axios.post('Cart/delCart', params).then(res => {
      message.info(res.msg)
      $getShopCartList()
    })
  }

  // 清空购物车
  const $onCleanShopCart = () => {
    setIsCleanModal(false)
    let temp = []
    shopCartData.forEach(element => {
      temp.push(element.cart_id)
    })
    let params = Qs.stringify({
      token,
      channel: 1,
      cart_id: temp.join(',')
    })
    axios.post('Cart/delCart', params).then(res => {
      message.info(res.msg)
      $getShopCartList()
    })
  }

  // 下单
  const $onClickPreOrder = () => {
    if(nowSelectCommodity.length) {
      props.history.push(`/order?type=shopChart`)
    } else {
      message.info('请选择需要下单的商品')
    }
  }

  return (
    <div className="context">
      {/* 删除提示 */}
      <Modal
        centered
        visible={isDeleteModal}
        closable={false}
        footer={null}
        width={270}
        getContainer={false}
      >
        <div className='modal-login'>
          <div className='modal-login-content'>是否删除此商品</div>
          <div className='modal-btn-list'>
            <div className='modal-btn-item modal-btn-1' onClick={$onClickDeleteCommodity}>
              确定
              </div>
            <div className='modal-btn-item modal-btn-2' onClick={() => { setIsDeleteModal(false) }}>
              取消
            </div>
          </div>
        </div>
      </Modal>
      {/* 清空购物车提示 */}
      <Modal
        centered
        visible={isCleanModal}
        closable={false}
        footer={null}
        width={270}
        getContainer={false}
      >
        <div className='modal-login'>
          <div className='modal-login-content'>是否清空购物车</div>
          <div className='modal-btn-list'>
            <div className='modal-btn-item modal-btn-1' onClick={$onCleanShopCart}>
              确定
              </div>
            <div className='modal-btn-item modal-btn-2' onClick={() => { setIsCleanModal(false) }}>
              取消
            </div>
          </div>
        </div>
      </Modal>
      {/* 面包屑导航 */}
      <div className="crumb">
        <span className="sy"><Lnk target='_blank' to='/' className="sy">首页</Lnk></span>
        <i className="iconfont icon-right"></i>
        <span>购物车</span>
      </div>

      {/* 购物车头部 */}
      <div className='nav'>
        <div className="nav-c">
          <div className="sp-car">
            <Table
              rowKey='cart_id'
              pagination={false}
              rowSelection={{
                type: 'Checkbox',
                onChange: $onChangeSelectCommodity
              }}
              columns={shopCartColumns}
              dataSource={shopCartData}
            />
          </div>
          <div className="bt-car">
            <div className="btn refresh" onClick={$getShopCartList}>刷新购物车</div>
            <div className="btn" onClick={() => setIsCleanModal(true)}>清空购物车</div>
          </div>
        </div>
      </div>

      {/* 结算 */}
      <div className="pay">
        <div className="pay-top">
          <span>已选择 <span className='theme-span'>{selectCommodityLength}</span> 件商品</span>
          <div>商品合计: {Number(orderPriceInfo.total_fee || 0).toFixed(2)}</div>
        </div>
        <div className="pay-bottom">
          <span>商品数量:  {orderPriceInfo.num || 0}个</span>
          <span className="amount">付款金额：<span className='theme-span' style={{ fontSize: '20px' }}>{Number(orderPriceInfo.total_fee || 0).toFixed(2)}</span></span>
          <span className="pb-btn" onClick={$onClickPreOrder}>下单</span>
        </div>
      </div>
    </div>
  )
}

export default ShopCar
