import React, { useState, useEffect } from 'react'
import { Pagination } from 'antd'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import banner from '@/assets/images/soft.png'

import Title from '@/components/title'
import Type from '@/components/type'
import RoomList from '@/components/room-list'

const Soft = () => {
  document.title = '软装馆'

  const [softTypeList, setSoftTypeList] = useState([])
  const [softType, setSoftType] = useState('0')
  const [softTypeIndex, setSoftTypeIndex] = useState(0)
  const [softStyleList, setSoftStyleList] = useState([])
  const [softStyle, setStyle] = useState('0')
  const [softSeries, setSoftSeries] = useState(0)
  const [softList, setSoftList] = useState([])
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(1)

  useEffect(() => {
    axios.post('Goods/hallCate', Qs.stringify({
      channel: 3,
      type: 7
    })).then(res => {
      Array.isArray(res.result.cateList) && res.result.cateList.forEach((element, index) => {
        if (index === 0) {
          setSoftType(element.id)
        }
        element.seriesList && Array.isArray(element.seriesList) && element.seriesList.forEach(el => {
          if (index === 0) {
            
          }
          el.id = el.series_item_id
          el.style_name = el.name
        })
      })
      Array.isArray(res.result.styleList) && res.result.styleList.forEach((element, index) => {
        if (index === 0) {
          setStyle(element.id)
        }
        element.name = element.style_name
      })
      setSoftTypeList(res.result.cateList)
      setSoftStyleList(res.result.styleList)
    })
  }, [])

  useEffect(() => {
    axios.post('Goods/hallGoods_hard', Qs.stringify({
      channel: 3,
      cate_id: softType,
      series_item_id: softSeries,
      style_id: softStyle,
      page: current
    })).then(res => {
      setTotal(res.result.pages)
      setSoftList(res.result.goods_list || [])
    })
  }, [softSeries, softType, softStyle, current])

  return (
    <div className='soft'>
      <img className='banner-img' src={banner} alt='星推荐' />
      <div className='context'>
        <Title title='软装馆' describe='生活方式由你原创，万般风情，没有压力' />
        <div className='soft-type-list'>
          <div className='soft-type'>
            <span className='soft-type-label'>类目：</span>
            <Type list={softTypeList} type={softType} button onChange={(value, i) => {
              setSoftType(value)
              setSoftTypeIndex(i)
              setSoftSeries(0)
            }} />
          </div>
          {
            softTypeList && softTypeList[softTypeIndex] && softTypeList[softTypeIndex].seriesList.length > 0 && (<div className='soft-type'>
              <span className='soft-type-label'>系列：</span>
              <Type list={softTypeList[softTypeIndex].seriesList} type={softSeries} button onChange={(value) => { setSoftSeries(value) }} />
            </div>)
          }
          <div className='soft-type'>
            <span className='soft-type-label'>风格：</span>
            <Type list={softStyleList} type={softStyle} button onChange={(value) => { setStyle(value) }} />
          </div>
        </div>
        <RoomList list={softList} />
        <Pagination className='soft-page' showSizeChanger={false} current={current} total={total * 10} onChange={(page) => {
          setCurrent(page)
          window.scrollTo(0, 0)
        }} />
      </div>
    </div>
  )
}

export default Soft