import React, {useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'
import Lnk from '../../components/lnk'

import { Form, Rate, Input } from 'antd'

const FinishDetail = (props) => {
  document.title = '已评价-详情'

  const { TextArea } = Input
  const [contentDetails, setContentDetails] = useState([])
  const [goodsListDetails, setGoodsListDetails] = useState([])
  const [commentInfoDetails, setCommentInfoDetails] = useState([])
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...propsParams,
    })
    axios.post('User/commentInfo',params).then((res) => {
      console.log('////', res.result)
      setContentDetails(res.result.orderInfo)
      setGoodsListDetails(res.result.goodsList)
      setCommentInfoDetails(res.result.commentInfo)
    })
  }, [propsParams]);
  return (
    <div className="finish-details">
      <div className="details-information">
        {/* 订单信息 */}
        <div className="details-number">
          <span>下单时间: {contentDetails.add_time}</span>
          <span>订单号: {contentDetails.order_sn}</span>
        </div>
        {/* 收货信息 */}
        <div className="goods-information">
          <div className="goods-left">
            <p>收货人 :  {contentDetails.consignee}</p>
            <p>联系方式 :  {contentDetails.mobile}</p>
            <p>收货地址 :  {contentDetails.province}{contentDetails.city}{contentDetails.district}{contentDetails.address}</p>
          </div>
          <div className="goods-right">
            <p>商品合计 :  ￥{contentDetails.goods_price}</p>
            <p>运费 :  ￥{contentDetails.shipping_price}</p>
          </div>
        </div>
        <div className="shop-name">
          <div className="shop-content">
            <span>商品名称</span>
            <span>单价</span>
            <span>数量</span>
            <span>实付</span>
            <span>收货人</span>
            <span>状态</span>
          </div>
          {Array.isArray(goodsListDetails) && goodsListDetails.map((el,index) => {
            return (
              <div className="specific-shop" key={index}>
                <div className="shop-pic">
                  <img src={el.original_img} alt=""/>
                  <span className="shop-pic-name">{el.goods_name}</span>
                </div>
                <p>￥{el.really_price}</p>
                <p>{el.goods_num}</p>
                <p>￥{el.really_price}</p>
                <p>{contentDetails.consignee}</p>
                <p>{el.status}</p>
              </div>
            )
          })}
          
        </div>
        {/* 留言信息 */}
        <div className="message">
          {/* 订单信息 */}
          <div className="details-number">
            <span>评论时间: {commentInfoDetails.commemt_time}</span>
          </div>
          <div className="message-content">
            <Form>
              <div className="message-evaluate">
                <div className="evaluate-content">
                  <span className="word">综合:</span>
                  <Rate
                    className="evaluation-icon"
                    disabled
                    value={commentInfoDetails.goods_rank}
                  />
                </div>
                <div className="evaluate-content">
                  <span className="word">商品:</span>
                  <Rate
                    className="evaluation-icon"
                    disabled
                    value={commentInfoDetails.describe_score}
                  />
                </div>
                <div className="evaluate-content">
                  <span className="word">物流:</span>
                  <Rate
                    className="evaluation-icon"
                    disabled
                    value={commentInfoDetails.logistics_score}
                  />
                </div>
                <div className="evaluate-content">
                  <span className="word">服务:</span>
                  <Rate
                    className="evaluation-icon"
                    disabled
                    value={commentInfoDetails.seller_score}
                  />
                </div>
              </div>
              <p className="evaluation-information">评价内容：</p>
                <TextArea disabled value={commentInfoDetails.content}></TextArea>
              <button className="return-btn">
                <Lnk target="_blank" to="/manage/finish-evaluation" className="see-details">返回</Lnk>
              </button>
            </Form>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default FinishDetail