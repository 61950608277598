import React, {useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Lnk from '../../components/lnk'

import Empty from '@/components/empty'

const Refund = () => {

  document.title = '退货退款'

  const [refundDetails, setRefundDetails] = useState([])
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 'all'
    })
    axios.post('User/return_goods_lists', params).then((res) => {
      console.log('List>>>', res)
      setRefundDetails(res.result.list)
    })
  }, [])
  // let role = Cookies.get('role')
  return (
    <div className="refund">
      <div className="refund-detail">
        {
          Array.isArray(refundDetails) && refundDetails.map((element, index) => {
            return (
              <div key={index} >
                {/* 订单详情 */}
                <div className="refund-number">
                  <span>下单时间: {element.add_time}</span>
                  <span>订单号: {element.order_sn}</span>
                </div>
                {/* 商品名称 */}
                <div className="refund-content">
                  <span className="goods-name">商品名称</span>
                  <span className="goods-price">单价</span>
                  <span className="goods-price">数量</span>
                  <span className="goods-price">实付</span>
                  <span className="goods-price">包裹</span>
                  <span className="goods-price">状态</span>
                  <span className="goods-price">操作</span>
                </div>
                {/* 商品内容 */}
                <div className="specific-content">
                  <div className="content-pic">
                    <img src={element.goods_pic} alt=""/>
                    <h4 className="rn-number">{element.goods_name}</h4>
                  </div>
                  <p>￥{element.really_price}</p>
                  <p>{element.goods_num}</p>
                  <p>￥{element.return_money}</p>
                  <p>{element.status}</p>
                  <p>{element.status_desc}</p>
                  <div className="operation">
                    <Lnk target="_blank" to={`/manage/details?order_id=${element.order_id}`} className="see">查看详情</Lnk>
                  </div>
                </div>
              </div>
            )
          })
        }
        {(!Array.isArray(refundDetails) || !refundDetails.length) && (
          <div>
            <Empty text="暂无订单" />
          </div>
        )}
      </div>
    </div>
  )
}
export default Refund