import React, { memo } from 'react'
import 'react-area-linkage/dist/index.css'
import { pcaa } from 'area-data'
import { AreaSelect } from 'react-area-linkage'
import { Input, Form } from 'antd'
import PropTypes from 'prop-types'
import './index.scss'

pcaa[86][100000] = '全部省份'
pcaa[100000] = {
  '100100': '全部城市'
}

const StoreSearch = props => {
  const [form] = Form.useForm()

  const $onSearchStoreList = () => {
    let params = {
      store_name: form.getFieldsValue().store_name || '',
      province_id: form.getFieldsValue().address[0] === '100000' ? '' : form.getFieldsValue().address[0],
      city_id: form.getFieldsValue().address[1] === '100100' ? '' : form.getFieldsValue().address[1]
    }
    props.onSearch(params)
  }

  return (
    <Form className="store-search" form={form}>
      <span className="store-search-title">体验店查询</span>
      <Form.Item name="address" style={{ marginBottom: 0 }}>
        <AreaSelect
          defaultArea={['100000', '100100']}
          data={pcaa}
        />
      </Form.Item>
      <div className="store-search-item">
        <Form.Item name="store_name" style={{ marginBottom: 0 }}>
          <Input placeholder="请输入店铺名称" style={{ width: 210 }} />
        </Form.Item>
      </div>
      <div className="store-search-item">
        <div className="search-btn" onClick={$onSearchStoreList}>查询</div>
      </div>
    </Form>
  )
}

StoreSearch.propTypes = {
  onSearch: PropTypes.func
}

export default memo(StoreSearch)
