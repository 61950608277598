import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import banner from '@/assets/images/recommend.jpg'

import Title from '@/components/title'
import Designer from './components/designer'
import Works from '@/components/works'

const Recommend = () => {
  document.title = '星推荐'

  const [designerList, setDesignerList] = useState([])
  const [worksList, setWorksList] = useState([])

  useEffect(() => {
    axios.post('Designer/levelDesignerList', Qs.stringify({
      channel: 3,
      num: 6
    })).then(res => {
      setDesignerList(res.result)
    })

    axios.post('product/productList', Qs.stringify({
      channel: 3,
      style: 0,
      limit: 6,
      page: 1
    })).then(res => {
      res.result && Array.isArray(res.result.list) && setWorksList(res.result.list)
    })
  }, [])

  return (
    <div className='recommended'>
      <img className='banner-img' src={banner} alt='星推荐' />
      <div className='context'>
        <Title title='专业设计师，您的户型全能画师' describe='让好的设计师为您的家添加一抹色彩' />
        <Designer list={designerList} />
        <Title title='人气作品' describe='生活方式由你原创，万般风情，没有压力' />
        <Works list={worksList} />
        <Button className='more'>查看更多</Button>
      </div>
    </div>
  )
}

export default Recommend