import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import Lnk from '@/components/lnk'
import { message } from 'antd'

const MyWorks = () => {
  document.title = '我的作品'


  const [worksStyle, setWorkStyle] = useState({})
  const [worksStatus, setWorksStatus] = useState({})
  const [worksStyleTab, setStyleTab] = useState(0)  //风格切换
  const [workStatus,setWorkStatus] = useState(1)  //状态切换
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 'all',
    })
    axios.post('product/getStyle', params).then((res) => {
      // console.log('>>>',res.result)
      // let firstId = ''
      // if (res.result.length) {
      //   firstId=res.result[0].id
      // }
      setWorkStyle(res.result)
      //setStyleTab(firstId)
      // let list = document.getElementsByClassName('worksStatus')
      
    })
  }, [])
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 'all',
      page: '',
      status: workStatus,
      style: worksStyleTab
    })
    axios.post('designer/productList', params).then((res) => {
      console.log('>>>1',res.result)
      setWorksStatus(res.result.list)
    })
  }, [workStatus, worksStyleTab])

  const productList = (status) =>{
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 3,
      page: '',
      status: status,
      style: worksStyleTab
    })
    axios.post('designer/productList', params).then((res) => {
      console.log('>>>2',res.result)
      setWorkStatus(status)
      setWorksStatus(res.result.list)
    })
  }

  // 删除作品
  const deleteInvoiceInformation = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 3,
      id: id,
    })
    axios.post('designer/del_designer_product', params).then((res) => {
      message.success(res.msg)
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        token,
        type: 3,
        page: 1,
        status: workStatus,
      })
      axios.post('designer/productList', params).then((res) => {
        // console.log('>>>3',res.result)
        setWorksStatus(res.result.list)
      })
    })
  }
  // 上下架作品
  const InvoiceInformation = (id,status) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 3,
      id: id,
      status:status
    })
    axios.post('designer/changeStatus', params).then((res) => {
      let nowstatus = 1;
      if(status===1){
        nowstatus = 2
      }
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        token,
        type: 3,
        page: 1,
        status: nowstatus,
      })
      axios.post('designer/productList', params).then((res) => {
        // console.log('>>>4',res.result)
        setWorksStatus(res.result.list)
      })
    })
  }

  return (
    <div className="myWorks">
      <div className="my-works">
        {/* 作品头部 */}
        <div className="works-head">
          <div className="header">
            <div className="works-head-left">
              <span className="works">我的作品</span>
              <i></i>
              <span className="works">作品数：</span>
            </div>
            <div className="works-head-right">
              <Lnk target="_blank" to="/manage/publishing-works" className="publishing-works">
                发布作品
              </Lnk>
            </div>
          </div>
        </div>
        {/* 作品风格 */}
        <div className="works-style">
          <span className="works-style-title">风格</span>
          <ul className={`worksStyle ${worksStyleTab===0 ? 'active' : ''}`} onClick={() => setStyleTab(0)} key={0}>
            <li>全部</li>
          </ul>
            {
              Array.isArray(worksStyle) && worksStyle.map((element) => {
                return (
                  <ul className={`worksStyle ${element.id === worksStyleTab ? 'active' : ''}`} onClick={() => setStyleTab(element.id)} key={element.id}>
                    <li>{element.style_name}</li>
                  </ul>
                )
              })
            }
          {/* <span className="works-more">更多>></span> */}
            
        </div>
        {/* 作品状态 */}
        <div className="works-status">
          <span className="works-status-title">状态</span>
          <ul className={`worksStatus`} onClick= {() =>{

          }}>
            <li className={`${workStatus===1?'active':'defaultstatus'}`} onClick={() => productList(1)}>上架</li>
            <li className={`${workStatus===2?'active':'defaultstatus'}`} onClick={() => productList(2)}>下架</li>
          </ul>
        </div>


        {/* 作品展示 */}
        <div className="works-display">
          {
            Array.isArray(worksStatus) && worksStatus.map((element,id) => {
              return (
                <div className="my-works-item" key={element.product_id}>
                  <div className="myWorks-top">
                    <span className="top-title">{element.product_style}</span>
                    <span >{element.title}</span>
                    <span className="top-title">点赞 : {element.love_num}</span>
                    <span className="top-title buy-title">购买 : {element.sale_num}</span>
                  </div>
                  <div className="myWorks-center">
                    {element.status===1?(
                    <Lnk className='myWorks-item-img' target='_blank' to={`/home-design-details?id=${element.product_id}`}>
                      <img src={element.image} alt=''/>
                    </Lnk>
                        ):(
                        <Lnk className='myWorks-item-img' to={`/manage/publishing-works?product_id=${element.product_id}`}>
                        <img src={element.image} alt=''/>
                        </Lnk>
                        )}
                  </div>
                  <div className="myWorks-bottom">
                    <Lnk to={`/manage/publishing-works?product_id=${element.product_id}`} className="works-edit">
                      <span className="worksEdit-btn">编辑</span>
                    </Lnk>
                    <span className="worksDelete-btn" onClick={() => deleteInvoiceInformation(element.product_id)}>删除</span>
                    {element.status === '1' ?(
                        <span className="worksEdit-tab" onClick={() => InvoiceInformation(element.product_id,2)}>下架</span>
                    ):(
                        <span className="worksEdit-tab" onClick={() => InvoiceInformation(element.product_id,1)}>上架</span>
                    )}

                  </div>
                </div>
              )
            })
          }
          

        </div>
      </div>
    </div>
  )
}

export default MyWorks