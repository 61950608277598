import React, { useState, useEffect, memo, useImperativeHandle } from 'react'
import { Pagination } from 'antd'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import Lnk from '@/components/lnk'

const SetMenu = ({ worksType, cRef }) => {
  const [setMenuList, setSetMenuList] = useState([])
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(1)

  useEffect(() => {
    axios.post('Index/packageList', Qs.stringify({
      channel: 3,
      page: current,
      style_id: worksType
    })).then(res => {
      setTotal(res.result.pages || 0)
      setSetMenuList(res.result.list || [])
    })
  }, [current, worksType])

  useImperativeHandle(cRef, () => ({
    // changeVal 就是暴露给父组件的方法
    changeCurrent: (newVal) => {
      setCurrent(newVal)
    }
  }))

  return (
    <>
      <div className='set-menu'>
        {
          Array.isArray(setMenuList) && setMenuList.map((element, index) => {
            return (
              <div className='set-menu-item' key={index}>
                <Lnk target='_blank' to={`/set-meal-details?package_id=${element.package_id}`} className='set-menu-pic'>
                  <img src={element.pic} alt='全屋套餐' />
                </Lnk>
                <div className="set-menu-title">{element.package_name}</div>
                <div className='set-menu-content'>
                  <div className='set-menu-info'>
                    <p style={{ margin: '5px 0' }}>
                      <span className='set-menu-type'>
                        {element.style_name}
                      </span>
                      <span className="set-menu-line">|</span>
                      <span className='set-menu-num'>
                        {element.plan_num || 0}套方案可选
                      </span>
                    </p>
                    <p className='set-menu-price'>
                      ￥{element.min_amount}~{element.max_amount}
                    </p>
                  </div>
                  {/* <div className='goods-list'>
                    {
                      Array.isArray(element.goodsList) && element.goodsList.map((el, i) => {
                        return (
                          <div className='goods-item' key={i}>
                            <img src={el.pic} alt='全屋套餐' />
                          </div>
                        )
                      })
                    }
                  </div> */}
                </div>
              </div>
            )
          })
        }
      </div>
      <Pagination className='set-menu-page' showSizeChanger={false} current={current} total={total * 10} onChange={(page) => {
        setCurrent(page)
        window.scrollTo(0, 0)
      }} />
    </>
  )
}

export default memo(SetMenu)