import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Lnk from '../../components/lnk'


import banner from '../../assets/images/integral_banner.jpg'
import Title from '@/components/title'


const IntegralMall = () => {
  document.title = '积分商城'

  const [integralList, setIntegralList] = useState({});
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      page: 1
    })
    axios.post('Integral/integralList', params).then((res) => {
      console.log('???', res.result)
      setIntegralList(res.result.integral_list)
    })
  }, []);
  return (
    <div className="integral-mall">
      <img className="banner-img" src={banner} alt=""/>
      <div className="integral-mall-content">
        <div className='context'>
          <Title title='积分商城' describe='生活方式由你原创，万般风情，没有压力' />
          <div className='malls-content'>
            {
              Array.isArray(integralList) && integralList.map((element) => {
                return (
                  <div className='mall-item' key={element.integral_id}>
                    <Lnk target='_blank' to={`/integral-details?integral_id=${element.integral_id}`}>
                      <div className='item-img'>
                        <img src={element.original_img} alt=""/>
                      </div>
                      <div className="item-bottom">
                        <div className="item-title">{element.integral_name}</div>
                        <div className="item-config">
                          <span className="item-num">{element.exchange_integral}积分</span>
                          <span className="exchange">立即兑换</span>
                        </div>
                      </div>
                    </Lnk>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntegralMall