import React, { memo } from 'react'
import './index.scss'

import Lnk from '../lnk'


import logo from '@/assets/images/footer-logo.png'

const Footer = () => {
  const menuList = [
    [
      {
        id: 0,
        name: '购物中心',
        path: 'none'
      },
      {
        id: 1,
        name: '购物流程',
        path: '/about?id=1&parent=0&children=0',
      },
      {
        id: 2,
        name: '支付方式',
        path: '/about?id=2&parent=0&children=1',
      },
      {
        id: 3,
        name: '发票制度',
        path: '/about?id=3&parent=0&children=2'
      }
    ],
    [
      {
        id: 0,
        name: '服务保障',
        path: 'none'
      },
      {
        id: 1,
        name: '免费配送',
        path: '/about?id=4&parent=1&children=0'
      },
      {
        id: 2,
        name: '七天退换',
        path: '/about?id=5&parent=1&children=1'
      },
      {
        id: 3,
        name: '一年质保',
        path: '/about?id=6&parent=1&children=2'
      }
    ],
    [
      {
        id: 0,
        name: '配送方式',
        path: 'none'
      },
      {
        id: 1,
        name: '物流配送',
        path: '/about?id=7&parent=2&children=0'
      },
      {
        id: 2,
        name: '安装说明',
        path: '/about?id=8&parent=2&children=1'
      },
      {
        id: 3,
        name: '收货指南',
        path: '/about?id=9&parent=2&children=3'
      }
    ],
    [
      {
        id: 0,
        name: '关于我们',
        path: 'none'
      },
      {
        id: 1,
        name: '公司简介',
        path: '/about?id=10&parent=3&children=0'
      },
      {
        id: 2,
        name: '加入我们',
        path: '/about?id=11&parent=3&children=1'
      },
      {
        id: 3,
        name: '招商合作',
        path: '/about?id=12&parent=3&children=2'
      }
    ]
  ]

  const $onClickService = () => {
    window.open('http://wpa.qq.com/msgrd?v=3&uin=2992124446&site=qq&menu=yes');
  }

  return (
    <div className='footer'>
      <div className='footer-context'>
        <div className='footer-top'>
          <div className='logo'>
            <img src={logo} alt="logo" />
          </div>
          <div className='menu'>
            {
              menuList && menuList.map((element, index) => {
                return (
                  <ul key={index} className='list'>
                    {
                      element && element.map(el => {
                        return (
                          <li className='item' key={el.id}>
                            <Lnk target='_blank' to={el.path}>
                              {el.name}
                            </Lnk>
                          </li>
                        )
                      })
                    }
                  </ul>
                )
              })
            }
          </div>
          <ul className='about'>
            <li className='about-item'>联系我们</li>
            <li className='about-item'>400-026-1998</li>
            <li className='about-item' onClick={$onClickService}>在线客服</li>
            {/* <li className='about-item'>御秀全国实体店</li> */}
          </ul>
        </div>
        <div className='tooter-bottom'>
          <span>Copyright©2019 御秀 .All Rights Reserved</span>
          <a className='beian' target='_blank' rel='noopener noreferrer' href="http://beian.miit.gov.cn/">浙ICP备16043438号-3</a>
          <a target='_blank' rel='noopener noreferrer' href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33040202000554">浙公网安备 33040202000554号</a>
        </div>
      </div>
    </div>
  )
}

export default memo(Footer)