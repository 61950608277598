import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Lnk from '../../components/lnk'

import { Tabs, message } from 'antd'

import Empty from '@/components/empty'

const Order = () => {
  document.title = '我的订单'
  const { TabPane } = Tabs
  function callback(key) {
    // console.log(key);
  }

  const [contentDetails, setContentDetails] = useState([])
  const [orderList, setOrderList] = useState([])
  const [waitPayDetails, setWaitPayDetails] = useState([])
  const [waitPayList, setWaitPayList] = useState([])
  const [deliverGoodsDetails, setDeliverGoodsDetails] = useState([])
  const [deliverGoodsList, setDeliverGoodsList] = useState([])
  const [waitRecipientDetails, setWaitRecipientDetails] = useState([])
  const [waitRecipientList, setWaitRecipientList] = useState([])

  // const [total, setTotal] = useState(1)
  const [current, setCurrent] = useState(1)

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      page: current,
      token,
      type: 'all'
    })
    axios.post('User/order_list', params).then((res) => {
      console.log('orderList>>>', res.result)
      setOrderList(res.result.order_count)
      setContentDetails(res.result.order_list)
    })
  }, [current])
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      page: current,
      token,
      type: 'waitpay'
    })
    axios.post('User/order_list', params).then((res) => {
      console.log('>>>', res.result)
      setWaitPayList(res.result.order_count)
      setWaitPayDetails(res.result.order_list)
    })
  }, [current])
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      page: current,
      token,
      type: 'waitsend'
    })
    axios.post('User/order_list', params).then((res) => {
      setDeliverGoodsList(res.result.order_count)
      setDeliverGoodsDetails(res.result.order_list)
    })
  }, [])
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      page: current,
      token,
      type: 'waitreceive'
    })
    axios.post('User/order_list', params).then((res) => {
      setWaitRecipientList(res.result.order_count)
      setWaitRecipientDetails(res.result.order_list)
    })
  }, [current])

  // 提醒发货
  const onClickRemind = (e, id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...e
    })
    axios.post('User/remind', params).then((res) => {
      message.success(res.msg)
    })
  }
  // 取消订单
  const onClickCancel = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      id: id
    })
    axios.post('User/cancel_order', params).then((res) => {
      message.success(res.msg)
      let params = Qs.stringify({
        channel: 1,
        page: 1,
        token,
        type: 'all'
      })
      axios.post('User/order_list', params)
    })
  }
  // 确认收货
  const onClickConfirm = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      order_id: id
    })
    axios.post('User/order_confirm', params).then((res) => {
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        page: 1,
        token,
        type: 'waitreceive'
      })
      axios.post('User/order_list', params)
    })
  }
  return (
    <div className="order">
      <div className="title-writ">
        <Tabs
          defaultActiveKey="1"
          onChange={callback}
          type="card"
          tabBarGutter="0"
        >
          <TabPane tab="全部订单" key="1" className="title">
            <div className="order-detail">
              {Array.isArray(contentDetails) &&
                contentDetails.map((element, index) => {
                  return (
                    <div key={index}>
                      {/* 订单详情 */}
                      <div className="order-number">
                        <span>下单时间: {element.add_time}</span>
                        <span>订单号: {element.order_sn}</span>
                      </div>
                      {/* 商品名称 */}
                      <div className="order-content">
                        <span>商品名称</span>
                        <span>单价</span>
                        <span>数量</span>
                        <span>实付</span>
                        <span>收货人</span>
                        <span>状态</span>
                        <span>操作</span>
                      </div>
                      {/* 商品内容 */}
                      <div className="context-box">
                        <div className="operation-left">
                          {Array.isArray(element.goods_list) &&
                            element.goods_list.map((el, id) => {
                              return (
                                <div className="specific-content" key={id}>
                                  <div className="content-pic">
                                    <img src={el.pic} alt="" />
                                    <div className="shop-size">
                                      <h4>{el.goods_name}</h4>
                                      <h4>{el.spec_key_name}</h4>
                                    </div>
                                  </div>
                                  {/* 单价 */}
                                  <p>{el.goods_price}</p>
                                  {/* 数量 */}
                                  <p>{el.goods_num}</p>
                                  {/* 实付 */}
                                  <p>
                                    {el.really_price === null
                                      ? el.member_goods_price
                                      : el.really_price}
                                  </p>
                                  {/* 收货人 */}
                                  <p>{element.consignee}</p>
                                  {/* 状态 */}
                                  <p>{element.order_status_desc}</p>
                                </div>
                              )
                            })}
                        </div>
                        <div className="operation-right">
                          {element.order_status_desc === '已取消' ? (
                            <span className="cancelled">订单已取消</span>
                          ) : (
                            <></>
                          )}
                          {element.receive_btn === 1 ? (
                            <span
                              className="remind"
                              onClick={() => onClickConfirm(element.order_id)}
                            >
                              确认收货
                            </span>
                          ) : (
                            <></>
                          )}
                          {element.pay_btn === 1 ? (
                            <>
                              <span className="remind">
                                <Lnk
                                  target="_blank"
                                  to={`/payment?order_id=${element.order_id}`}
                                  className="works-edit"
                                >
                                  支付
                                </Lnk>
                              </span>
                              {element.cancel_btn === 1 ? (
                                <span
                                  className="cancel"
                                  onClick={() => {
                                    onClickCancel(element.order_id)
                                  }}
                                >
                                  取消订单
                                </span>
                              ) : (
                                <span></span>
                              )}
                              <span className="see">
                                <Lnk
                                  target="_blank"
                                  to={`/manage/details?order_id=${element.order_id}`}
                                  className="see-word"
                                >
                                  查看详情
                                </Lnk>
                              </span>
                            </>
                          ) : (
                            // (<span className="see">
                            //   <Lnk target="_blank" to={`/manage/back-orders-details?order_id=${element.order_id}`} className={element.order_status_desc === '已取消' ? 'see-word' : 'see-word-style'}>查看详情</Lnk></span>
                            // )
                            <span className="see">
                              <Lnk
                                target="_blank"
                                to={`/manage/back-orders-details?order_id=${element.order_id}`}
                                className="see-word"
                              >
                                查看详情
                              </Lnk>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
              {(!Array.isArray(contentDetails) || !contentDetails.length) && (
                <div>
                  <Empty text="暂无订单" className="status" />
                </div>
              )}
            </div>
          </TabPane>
          <TabPane
            tab={`待付款${(waitPayList && waitPayList.wait_pay_count) || 0}`}
            key="2"
            className="title"
          >
            <div className="order-detail">
              {Array.isArray(waitPayDetails) &&
                waitPayDetails.map((element, index) => {
                  return (
                    <div key={index}>
                      <div className="order-number">
                        <span>下单时间: {element.add_time}</span>
                        <span>订单号: {element.order_sn}</span>
                      </div>
                      {/* 商品名称 */}
                      <div className="order-content">
                        <span>商品名称</span>
                        <span>单价</span>
                        <span>数量</span>
                        <span>实付</span>
                        <span>收货人</span>
                        <span>状态</span>
                        <span>操作</span>
                      </div>
                      {/* 商品内容 */}
                      <div className="context-box">
                        <div className="operation-left">
                          {Array.isArray(element.goods_list) &&
                            element.goods_list.map((el, id) => {
                              return (
                                <div className="specific-content" key={id}>
                                  <div className="content-pic">
                                    <img src={el.pic} alt="" />
                                    <div className="shop-size">
                                      <h4>{el.goods_name}</h4>
                                      <h4>{el.spec_key_name}</h4>
                                    </div>
                                  </div>
                                  {/* 单价 */}
                                  <p>{el.goods_price}</p>
                                  {/* 数量 */}
                                  <p>{el.goods_num}</p>
                                  {/* 实付 */}
                                  <p>
                                    {el.really_price === null
                                      ? el.member_goods_price
                                      : el.really_price}
                                  </p>
                                  {/* 收货人 */}
                                  <p>{element.consignee}</p>
                                  {/* 状态 */}
                                  <p>{element.order_status_desc}</p>
                                </div>
                              )
                            })}
                        </div>
                        <div className="operation-right">
                          {element.order_status_desc === '已取消' ? (
                            <span>订单已取消</span>
                          ) : (
                            <>
                              <span className="remind">
                                <Lnk
                                  target="_blank"
                                  to={`/payment?order_id=${element.order_id}`}
                                  className="works-edit"
                                >
                                  支付
                                </Lnk>
                              </span>
                              <span
                                className="cancel"
                                onClick={() => {
                                  onClickCancel(element.order_id)
                                }}
                              >
                                取消订单
                              </span>
                            </>
                          )}
                          <span className="see">
                            <Lnk
                              target="_blank"
                              to={`/manage/details?order_id=${element.order_id}`}
                              className="see-word"
                            >
                              查看详情
                            </Lnk>
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              {(!Array.isArray(waitPayDetails) || !waitPayDetails.length) && (
                <div>
                  <Empty text="暂无订单" className="status" />
                </div>
              )}
            </div>
          </TabPane>
          <TabPane
            tab={`待发货${
              (deliverGoodsList && deliverGoodsList.wait_send_count) || 0
            }`}
            key="3"
            className="title"
          >
            <div className="order-detail">
              {Array.isArray(deliverGoodsDetails) &&
                deliverGoodsDetails.map((element, index) => {
                  return (
                    <div key={index}>
                      {/* 订单详情 */}
                      <div className="order-number">
                        <span>下单时间: {element.add_time}</span>
                        <span>订单号: {element.order_sn}</span>
                      </div>
                      {/* 商品名称 */}
                      <div className="order-content">
                        <span>商品名称</span>
                        <span>单价</span>
                        <span>数量</span>
                        <span>实付</span>
                        <span>收货人</span>
                        <span>状态</span>
                        <span>操作</span>
                      </div>
                      {/* 商品内容 */}
                      <div className="context-box">
                        <div className="operation-left">
                          {Array.isArray(element.goods_list) &&
                            element.goods_list.map((el, id) => {
                              return (
                                <div className="specific-content" key={id}>
                                  <div className="content-pic">
                                    <img src={el.pic} alt="" />
                                    <div className="shop-size">
                                      <h4>{el.goods_name}</h4>
                                      <h4>{el.spec_key_name}</h4>
                                    </div>
                                  </div>
                                  {/* 单价 */}
                                  <p>{el.goods_price}</p>
                                  {/* 数量 */}
                                  <p>{el.goods_num}</p>
                                  {/* 实付 */}
                                  <p>
                                    {el.really_price === null
                                      ? el.member_goods_price
                                      : el.really_price}
                                  </p>
                                  {/* 收货人 */}
                                  <p>{element.consignee}</p>
                                  {/* 状态 */}
                                  <p>{element.order_status_desc}</p>
                                </div>
                              )
                            })}
                        </div>
                        <div className="operation-right">
                          <span
                            className="remind"
                            onClick={() => onClickRemind(element)}
                          >
                            提醒发货
                          </span>
                          <span className="see">
                            <Lnk
                              target="_blank"
                              to={`/manage/back-orders-details?order_id=${element.order_id}`}
                              className="see-word"
                            >
                              查看详情
                            </Lnk>
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              {(!Array.isArray(deliverGoodsDetails) ||
                !deliverGoodsDetails.length) && (
                <div>
                  <Empty text="暂无订单" className="status" />
                </div>
              )}
            </div>
          </TabPane>
          <TabPane
            tab={`待收货${
              (waitRecipientList && waitRecipientList.wait_receive) || 0
            }`}
            key="4"
            className="title"
          >
            {Array.isArray(setWaitRecipientDetails) ||
            !setWaitRecipientDetails.length ? (
              <Empty text="暂无订单" />
            ) : (
              <div className="order-detail">
                {Array.isArray(waitRecipientDetails) &&
                  waitRecipientDetails.map((element, index) => {
                    return (
                      <div key={index}>
                        {/* 订单详情 */}
                        <div className="order-number">
                          <span>下单时间: {element.add_time}</span>
                          <span>订单号: {element.order_sn}</span>
                        </div>
                        {/* 商品名称 */}
                        <div className="order-content">
                          <span>商品名称</span>
                          <span>单价</span>
                          <span>数量</span>
                          <span>实付</span>
                          <span>收货人</span>
                          <span>状态</span>
                          <span>操作</span>
                        </div>
                        {/* 商品内容 */}
                        <div className="context-box">
                          <div className="operation-left">
                            {Array.isArray(element.goods_list) &&
                              element.goods_list.map((el, id) => {
                                return (
                                  <div className="specific-content" key={id}>
                                    <div className="content-pic">
                                      <img src={el.pic} alt="" />
                                      <div className="shop-size">
                                      <h4>{el.goods_name}</h4>
                                      <h4>{el.spec_key_name}</h4>
                                    </div>
                                    </div>
                                    {/* 单价 */}
                                    <p>{el.goods_price}</p>
                                    {/* 数量 */}
                                    <p>{el.goods_num}</p>
                                    {/* 实付 */}
                                    <p>
                                      {el.really_price === null
                                        ? el.member_goods_price
                                        : el.really_price}
                                    </p>
                                    {/* 收货人 */}
                                    <p>{element.consignee}</p>
                                    {/* 状态 */}
                                    <p>{element.order_status_desc}</p>
                                  </div>
                                )
                              })}
                          </div>
                          <div className="operation-right">
                            <span
                              className="remind"
                              onClick={() => onClickConfirm(element.order_id)}
                            >
                              确认收货
                            </span>
                            <span className="see">
                              <Lnk
                                target="_blank"
                                to={`/manage/details?order_id=${element.order_id}`}
                                className="see-word"
                              >
                                查看详情
                              </Lnk>
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            )}
            {(!Array.isArray(waitRecipientDetails) ||
              !waitRecipientDetails.length) && (
              <div>
                <Empty text="暂无订单" className="status" />
              </div>
            )}
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default Order
