import React, { memo } from 'react'
import { useDispatch } from 'redux-react-hook'
import Cookie from 'js-cookie'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import Lnk from '@/components/lnk'

const Works = ({ list }) => {
  const dispatch = useDispatch()
  let token = Cookie.get('token')

  const $onClickStar = (id) => {
    if (token) {
      let params = Qs.stringify({
        channel: 1,
        token,
        id: id
      })
      console.log('token', token)
      console.log('id', id)
      axios.post('Product/collectProduct', params)
    } else {
      dispatch({ type: 'SHOW' })
    }
  }

  const $onClickLike = (id) => {
    if (token) {
      let token = Cookie.get('token')
      let params = Qs.stringify({
        channel: 1,
        token,
        id: id
      })
      axios.post('Product/loveProduct', params)
    } else {
      dispatch({ type: 'SHOW' })
    }
  }

  return (
    <div className="works-box">
      <div className="works">
        {list &&
          list.map((element, index) => {
            return (
              <div className="works-item" key={index}>
                <div className="works-item-top">
                  <div className="works-img-block">
                    <div style={{ overflow: 'hidden', height: '100%' }}>
                      <Lnk
                        className="works-item-img"
                        target="_blank"
                        to={`/home-design-details?id=${element.product_id}`}
                      >
                        <img src={element.image} alt="作品" />
                      </Lnk>
                    </div>
                  </div>
                </div>
                <div className="works-item-bottom">
                  <div className="user-info">
                    <img className="head" src={element.head_pic} alt="头像" />
                    <span className="username">{element.nickname}</span>
                  </div>
                  <div className="commodity-option">
                    <span>{element.product_style}</span>
                    <div className="options">
                      <span className="options-star">
                        <i
                          className={`iconfont icon-like1 star ${element.is_collect === 1 ? 'active' : ''
                            }`}
                          onClick={() => {
                            $onClickStar(element.product_id)
                            // console.log('love',element.is_collect)
                          }}
                        ></i>
                        {element.collect_num}
                      </span>
                      <span className="options-like">
                        <i
                          className={`iconfont icon-like star ${element.is_love === 1 ? 'active' : ''
                            }`}
                          onClick={() => $onClickLike(element.product_id)}
                        ></i>
                        {element.love_num}
                      </span>
                    </div>
                  </div>
                  <div className="room-info">
                    <span>{element.skills}</span>
                    <span className="middle">{element.room_type}</span>
                    <span>{element.room_size}㎡</span>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default memo(Works)
