import React, { useState, useEffect } from 'react'
import { IMAGE_URL } from '@/api/config'
import axios from '@/api'
import { Row, Col, message } from 'antd'
import { saveAs } from 'file-saver'
import './index.scss'

import banner from '@/assets/images/sd.png'

import Title from '@/components/title'

const SourceDownload = () => {
  document.title = '资料下载'
  const [sourceList, setSourceList] = useState([]) // 资料列表

  useEffect(() => {
    axios.post('Article/download_list?parent_id=21').then(res => {
      setSourceList(res.result || [])
    })
  }, [])

  const $onClickDownloadFile = (url) => {
    if(/^http/.test(url)) {
      saveAs(url)
    } else {
      message.info('暂时没有下载链接~~~')
    }
  }

  return (
    <div className="source-download">
      <img className='banner-img' src={banner} alt='资料下载' />
      <div className="context">
        <Title title='资料下载' describe='这里有几乎所有御秀相关资料的收录' />
        <Row gutter={[50, 50]} style={{ marginTop: 20 }}>
          {
            sourceList.map(element => {
              return (
                <Col key={element.article_id} className="gutter-row" span={6}>
                  <div className="gutter-row-block" onClick={() => $onClickDownloadFile(element.link)}>
                    <div className="gutter-row-content">
                      <img className="file-img" src={IMAGE_URL + element.thumb} alt="文件" />
                      <div className="file-title">{element.title}</div>
                      <div className="file-title">提取码：{element.description}</div>
                    </div>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </div>
    </div>
  )
}

export default SourceDownload