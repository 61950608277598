import React, { memo, useRef } from 'react'
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import './index.scss'

import aqhb from '@/assets/images/aqhb.png'
import zzsj from '@/assets/images/zzsj.png'
import zcps from '@/assets/images/zcps.png'
import yzscg from '@/assets/images/yzscg.png'

const ServiceBanner = () => {
  const ref = useRef(null);

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev();
    }
  };
  const bannerList = [
    {
      id: 0,
      icon: aqhb,
      text: '安全环保'
    },
    {
      id: 1,
      icon: zzsj,
      text: '整装设计'
    },
    {
      id: 2,
      icon: zcps,
      text: '专车配送'
    },
    {
      id: 3,
      icon: yzscg,
      text: '一站式采购'
    }
  ]

  const params = {
    slidesPerView: 3,
    spaceBetween: 30,
    slidesPerGroup: 1,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }
  }

  return (
    <div className='service-banner'>
      <div className='context service-banner-context'>
        <Swiper {...params} ref={ref}>
          {
            bannerList && bannerList.map(element => {
              return (
                <div className='swiper-item' key={element.id}>
                  <div className='swiper-item-context'>
                    <div className='top-white'></div>
                    <img src={element.icon} alt="icon" />
                  </div>
                </div>
              )
            })
          }
        </Swiper>
        <i className='iconfont icon-left-double' onClick={goPrev}></i>
        <i className='iconfont icon-right-double' onClick={goNext}></i>
      </div>
    </div>
  )
}

export default memo(ServiceBanner)