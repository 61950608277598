/*
 * @Description: 描述
 * @Author: WaynePeng
 * @Date: 2020-09-11 10:37:45
 * @LastEditTime: 2020-11-18 17:07:41
 * @LastEditors: WaynePeng
 */
// export const API = 'http://case02.tiancekj.com:8080/public/index.php?s='

// export const IMAGE_URL = 'http://case02.tiancekj.com:8080/public/storage/'

// export const API = 'http://localhost:3000/public/index.php?s='

// export const IMAGE_URL = 'http://localhost:3000/public/storage/'

// export const API = 'http://www.rs2012.com/index.php/WXAPI/'
export const API = 'http://api.rs2012.com/index.php/WXAPI/'
export const URL = 'http://api.rs2012.com/index.php'
export const IMAGE_URL = 'https://wxxcx1.rs2012.com'