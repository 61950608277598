import React, { useState, useEffect, useRef } from 'react'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import banner from '@/assets/images/set-meal.jpg'

import Title from '@/components/title'
import Type from '@/components/type'
import SetMenu from './components/set-menu'

const SetMeal = () => {
  document.title = '全屋套餐'

  const [setMealTypeList, setSetMealTypeList] = useState([])
  const [setMealType, setSetMealType] = useState('0')

  const childRef = useRef()

  useEffect(() => {
    axios.post('Index/packageStyle', Qs.stringify({
      channel: 3
    })).then(res => {
      Array.isArray(res.result) && res.result.forEach(element => {
        element.id = element.style_id
      })
      setSetMealTypeList(res.result)
    })
  }, [])

  return (
    <div className='set-meal'>
      <img className='banner-img' src={banner} alt='全屋套餐' />
      <div className='context'>
        <Title title='全屋套餐' describe='生活方式由你原创，万般风情，没有压力' />
        <Type list={setMealTypeList} type={setMealType} onChange={(value) => {
          setSetMealType(value)
          childRef.current.changeCurrent(1)
        }} />
        <SetMenu setMealType={setMealType} cRef={childRef} />
      </div>
    </div>
  )
}

export default SetMeal