import React, { useEffect, useState, memo } from 'react'
import { Button } from 'antd'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import Lnk from '@/components/lnk'

const Designer = () => {
  const [designerList, setDesignerList] = useState([])

  useEffect(() => {
    axios.post('Article/designer_tuijian', Qs.stringify({
      channel: 3
    })).then(res => {
      setDesignerList(res.result)
    })
  }, [])

  return (
    <>
      <div className='designer'>
        <div className='designer1 designer-item'>
          <div className='designer-item-context mouse'>
            <div className='top'>金牌</div>
            <Lnk target='_blank' to={`/designer-details?designer=${designerList[0] && designerList[0].designer_id}`}>
              <img src={designerList[0] && designerList[0].pic} alt="金牌设计师" />
            </Lnk>
            <div className='bottom'>
              <span>{designerList[0] && designerList[0].nickname}</span>
            </div>
            <div className='hover-bottom'>
              <p>{designerList[0] && designerList[0].nickname}</p>
              <p>{designerList[0] && designerList[0].con} 作品 {designerList[0] && designerList[0].works_num}</p>
            </div>
          </div>
        </div>
        <div className='designer2 designer-item mouse'>
          <div className='designer-item-context'>
            <div className='top'>金牌</div>
            <Lnk target='_blank' to={`/designer-details?designer=${designerList[1] && designerList[1].designer_id}`}>
              <img src={designerList[1] && designerList[1].pic} alt="金牌设计师" />
            </Lnk>
            <div className='bottom'>
              <span>{designerList[1] && designerList[1].nickname}</span>
            </div>
            <div className='hover-bottom'>
              <p>{designerList[1] && designerList[1].nickname}</p>
              <p>{designerList[1] && designerList[1].con} 作品 {designerList[1] && designerList[1].works_num}</p>
            </div>
          </div>
        </div>
        <div className='designer3 designer-item'>
          <div className='designer-item-context'>
            <div className='designer3-1 designer3-child mouse'>
              <Lnk target='_blank' to={`/designer-details?designer=${designerList[2] && designerList[2].designer_id}`}>
                <img src={designerList[2] && designerList[2].pic} alt="设计师" />
              </Lnk>
              <div className='bottom'>
                <span>{designerList[2] && designerList[2].nickname}</span>
              </div>
              <div className='hover-bottom'>
                <p>{designerList[2] && designerList[2].nickname}</p>
                <p>{designerList[2] && designerList[2].con} 作品 {designerList[2] && designerList[2].works_num}</p>
              </div>
            </div>
            <div className='space'></div>
            <div className='designer3-2 designer3-child mouse'>
              <Lnk target='_blank' to={`/designer-details?designer=${designerList[3] && designerList[3].designer_id}`}>
                <img src={designerList[3] && designerList[3].pic} alt="设计师" />
              </Lnk>
              <div className='bottom'>
                <span>{designerList[3] && designerList[3].nickname}</span>
              </div>
              <div className='hover-bottom'>
                <p>{designerList[3] && designerList[3].nickname}</p>
                <p>{designerList[3] && designerList[3].con} 作品 {designerList[3] && designerList[3].works_num}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button className='more'>
        <Lnk target='_blank' to='recommend' >
          查看更多
        </Lnk>
      </Button>
    </>
  )
}

export default memo(Designer)