import React, { useState } from 'react'
import { Form, Button, Col, Row, message, Input, Radio } from 'antd';
import axios from '@/api'
import Qs from 'qs'
import Cookie from 'js-cookie'
import './index.scss'

import Lnk from '@/components/lnk'

const Register = (props) => {
  document.title = '注册'

  const [code, setCode] = useState(0)
  const [phone, setPhone] = useState()
  const [phoneStatus, setPhoneStatus] = useState(false)

  // 发送手机验证码
  const $getPhoneCode = () => {
    if (!phoneStatus) {
      message.error('请输入正确的手机号')
      return
    }
    let params = Qs.stringify({
      mobile: phone,
      type: 2,
      channel: 3
    })
    axios.post('User/send_code', params)
      .then(res => {
        $countDown()
      })
      .catch(e => {
        message.error('发送手机验证码失败')
        console.log(e)
      })
  }

  // 倒计时
  const $countDown = () => {
    let count = 60
    let interval = setInterval(() => {
      count = count - 1
      setCode(count)
      if (count === 0) {
        clearInterval(interval)
      }
    }, 1000)
  }

  const $onFinish = (event) => {
    console.log(event)
    let params = Qs.stringify({
      channel: 3,
      mobile: phone,
      type: 0,
      ...event
    })
    axios.post('User/register', params)
     .then((res) => {
        Cookie.set('token', res.token)
        message.success(res.msg)
        props.history.push('../login')
      }).catch((e) => {
        message.error('注册失败')
        console.log(e)})
  }

  return (
    <div className='register'>
      <div className='register-form'>
        <div className='form-context'>
          <div className='form-title'>
            欢迎加入御秀
          </div>
          <div className='form-content'>
            <Form
              initialValues={{
                role: 1,
              }}
              onFinish={$onFinish}
            >
              <Form.Item
                name="username"
                rules={[
                  () => ({
                    validator (rule, value) {
                      if (value && (/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
                        setPhone(value)
                        setPhoneStatus(true)
                        return Promise.resolve()
                      }
                      setPhoneStatus(false)
                      return Promise.reject('请检查手机号是否正确!')
                    }
                  })
                ]}
              >
                <Input placeholder='请输入手机号' />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: '请输入密码',
                  },
                ]}
              >
                <Input type='password' placeholder='请输入密码' />
              </Form.Item>

              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: '请输入验证码',
                  },
                ]}
              >
                <Row>
                  <Col className='form-col' span={12}>
                    <Input placeholder='请输入验证码' />
                  </Col>
                  <Col className='form-col' span={12}>
                    <Button disabled={code ? true : false} onClick={$getPhoneCode} type="primary" className='get-code'>
                      {code ? `请${code}s后重试` : '获取验证码'}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item
                name='role'
                label='请选择身份'
              >
                <Radio.Group>
                  <Radio value={1}>会员</Radio>
                  <Radio value={2}>设计师</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item>
                <Button className='submit' type="primary" htmlType="submit">
                  注册
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className='agreement'>
          注册代表你已同意<Lnk target='_blank' to='/agreement'><span>《御秀用户协议》</span></Lnk>
        </div>
        <div className='switch'>
          已有账号？<Lnk to='/login'>去登陆</Lnk>
        </div>
      </div>
    </div>
  )
}

export default Register