import React, { useState, useEffect } from 'react'
import { Pagination } from 'antd'
import Qs from 'qs'
import axios from '@/api'
import queryString from 'querystring'
import './index.scss'

import Title from '@/components/title'
import Empty from '@/components/empty'
import Type from '@/components/type'
import Commodity from './components/commodity-list'

const CommodityLibrary = (props) => {
  document.title = '商品库'

  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [commodityTypeList, setCommodityTypeList] = useState({})
  const [type, setType] = useState(0)
  const [typeIndex, setTypeIndex] = useState(0)
  const [seriesType, setSeriesType] = useState(0)
  const [styleType, setStyleType] = useState(0)
  const [sortType, setSortType] = useState('comment_count')
  const [salesSort, setSalesSort] = useState() // desc  asc
  const [priceSort, setPriceSort] = useState()
  const [commodityList, setCommodityList] = useState({})
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(1)
  // const [titleList, setTitleList] = useState([]) //标题列表

  const sortList = [
    {
      id: 'comment_count',
      name: '综合'
    },
    {
      id: 'sales_sum',
      name: `销量${salesSort === 'asc' ? '🠕' : '🠗'}`
    },
    {
      id: 'shop_price',
      name: `价格${priceSort === 'asc' ? '🠕' : '🠗'}`
    }
  ]

  useEffect(() => {
    let url = ''
    let data = {}
    if (propsParams.cate_id !== undefined) {
      url = 'Goods/cateSelect'
      data = {
        cate_id: propsParams.cate_id
      }
    } else {
      url = 'Goods/selectCate'
      data = {
        keywords: propsParams.searchValue
      }
    }
    axios.post(url, Qs.stringify({
      channel: 3,
      ...data
    })).then(res => {
      res.result && res.result.styleList && res.result.styleList.forEach(element => {
        element.name = element.style_name
      })
      setCommodityTypeList(res.result)
      // setTitleList(res.result.cateList)
      console.log('//////',res.result.cateList)
      res.result.cateList && setType(propsParams.cate_id || res.result.cateList[0].id)
      res.result && res.result.cateList && res.result.cateList.forEach((element, index) => {
        if (element.id === propsParams.cate_id && element.seriesList && element.seriesList.length > 0) {
          setTypeIndex(index)
        }
        element.seriesList.forEach(e => {
          e.id = e.series_item_id
        })
      })
      res.result && res.result.styleList && setStyleType(res.result.styleList[0].id)
    })
  }, [propsParams])

  useEffect(() => {
    axios.post('Goods/goodsSelect', Qs.stringify({
      channel: 3,
      cate_id: type,
      series_item_id: seriesType,
      style_id: styleType,
      key: sortType,
      sort: priceSort || salesSort || '',
      keywords: propsParams.searchValue || '',
      page: current
    })).then(res => {
      setTotal(res.result.pages)
      setCommodityList((res.result && res.result.goods_list) || [])
    })
  }, [type, seriesType, sortType, styleType, priceSort, salesSort, propsParams, current])

  return (
    <div className='commodity-library'>
      <div className='context commodity-library-context'>
        <Title title='商品库' describe='生活方式由你原创，万般风情，没有压力' />
        {/* <div className="body-box">
          {
            Array.isArray(titleList) && titleList.map((element) => {
              return (
                <div key={element.id}>
                  <div></div>
                </div>
              )
            })
          }
        </div> */}
        <div className='commodity-library-select'>
          {
            commodityTypeList.cateList && (<div className='commodity-library-select-list'>
              <span className='select-label'>分类</span><Type theme style={{ border: 'none', padding: 0 }} list={commodityTypeList.cateList} type={type} onChange={(value, index) => {
                setType(value)
                setTypeIndex(index)
                commodityTypeList.cateList[typeIndex] && commodityTypeList.cateList[typeIndex].seriesList && commodityTypeList.cateList[typeIndex].seriesList[0] && setSeriesType(commodityTypeList.cateList[typeIndex].seriesList[0].series_item_id)
              }} />
            </div>)
          }
          {
            commodityTypeList.styleList && (<div className='commodity-library-select-list'>
              <span className='select-label'>风格</span><Type theme style={{ border: 'none', padding: 0 }} list={commodityTypeList.styleList} type={styleType} onChange={(value) => {
              setStyleType(value)
            }} />
            </div>)
          }
          {
            (commodityTypeList.cateList && commodityTypeList.cateList[typeIndex] && commodityTypeList.cateList[typeIndex].seriesList && commodityTypeList.cateList[typeIndex].seriesList.length && (<div className='commodity-library-select-list'>
              <span className='select-label'>系列</span><Type theme style={{ border: 'none', padding: 0 }} list={commodityTypeList.cateList[typeIndex].seriesList} type={seriesType} onChange={(value) => { setSeriesType(value) }} />
            </div>)) || ''
          }

          <div className='commodity-library-select-list'>
            <span className='select-label'>排序</span><Type theme style={{ border: 'none', padding: 0 }} list={sortList} type={sortType} onChange={(value) => {
              switch (value) {
                case 'sales_sum':
                  setSalesSort(salesSort === 'desc' ? 'asc' : 'desc')
                  setPriceSort()
                  break
                case 'shop_price':
                  setPriceSort(priceSort === 'desc' ? 'asc' : 'desc')
                  setSalesSort()
                  break
                default:
                  setSalesSort()
                  setPriceSort()
              }
              setSortType(value)
            }} />
          </div>
        </div>
        <div className='commodity-library-content'>
          {
            commodityList.length ? <Commodity list={commodityList} /> : <Empty />
          }
        </div>
        <Pagination className='soft-page' showSizeChanger={false} current={current} total={total * 10} onChange={(page) => {
          setCurrent(page)
          window.scrollTo(0, 0)
        }} />
      </div>
    </div>
  )
}

export default CommodityLibrary