import React, { memo, useEffect, useState } from 'react'
import { Button } from 'antd';
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import Lnk from '@/components/lnk'

const Design = () => {
  const [designList, setDesignList] = useState([])

  useEffect(() => {
    axios.post('Article/five_dog', Qs.stringify({
      channel: 3
    })).then(res => {
      setDesignList(res.result)
    })
  }, [])

  return (
    <div className='design'>
      <div className='design-context'>
        <div className='design-left' style={{ backgroundImage: `url(${designList[0] && designList[0].thumb})` }}>
          <div className='design-left-context'>
            <div className='fived'>
              <p>5D</p>
            </div>
            <div className='panorama'>全景样板间</div>
            <Button className='start-btn'>
              <Lnk target='_blank' to='/design'>开始设计</Lnk>
            </Button>
          </div>
        </div>
        <div className='design-right'>
          <p className='english-introduce'>AMERICAN</p>
          <p className='english-introduce'>IMPRESSION</p>
          <p className='introduce'>一 站 式 定 制 你 的 家</p>
          <p className='select'>选择您想要的风格吧</p>
          <div className='select-group'>
            {
              designList && designList.map((element, index) => {
                return (
                  <div className='select-item' key={index} style={{ backgroundImage: `url(${element.thumb})` }}>
                    <div className='select-item-context'>
                      {element.title}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Design)