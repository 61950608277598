import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'

import CommoditySelect from './commodity-select'
import { InputNumber, message} from 'antd'
import Empty from '@/components/empty'

const IntegralDetails = (props) => {
  document.title = '详情页'
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [integralDetails, setIntegralDetails] = useState({});
  const [commodityNumber, setCommodityNumber] = useState(1)

  //  const [commodityDetails, setCommodityDetails] = useState({})
  // const [commodityOptions, setCommodityOptions] = useState([])
  // const [commodityOptionsId, setCommodityOptionsId] = useState([])
  const [activeTab, setActiveTab] = useState(0) // 默认选中得tab
  const [selectColorIndex, setSelectColorIndex] = useState('none') // 颜色标题
  const [selectImageIndex, setSelectImageIndex] = useState(0) // 图片索引（非墙布时）

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...propsParams
    })
    axios.post('Integral/integralDetail', params).then((res) => {
      console.log('.....',res.result)
      setIntegralDetails(res.result)
    })
  }, [propsParams]);
  

  // 切换商品规格
  // const $onChangeCommoditySpecifications = (value, index, tag, element) => {
  //   if (element.parent_name === '颜色') {
  //     let value = element.spec_list[index].item
  //     setSelectColorIndex(value || 'none')
  //   }
  //   commodityOptions[tag] = index
  //   commodityOptionsId[tag] = value
  //   setCommodityOptionsId([...commodityOptionsId])
  //   setCommodityOptions([...commodityOptions])
  // }

  // 切换颜色
  const $onChangeCommodityImage = (event) => {
    let url = ''
    if (event) {
      let temp = []
      temp = event.filter(element => element.color_title === selectColorIndex)
      if (temp.length) {
        url = (temp[0] && temp[0]) || ''
      } else if (event.length > selectImageIndex) {
        url = event[selectImageIndex] || ''
      }
    }
    return url
  }
  // 立即兑换
  const onClickExchange = () => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...propsParams,
      integral_num: commodityNumber
    })
    axios.post('Integral/directBuy',params).then((res) => {
      // message.success(res.msg)
      console.log('............',res)
      if (res.status === 1) {
        props.history.push('/order-integral')
      } else {
        message.info(res.msg)
      }
    })
  }

  return (
    <div className="integral-details">
      <div className="context">
        {/* 详情导航 */}
        <div className="details-head">
          <span className="head-left">首页</span>
          <span className="head-icon">></span>
          <span>{integralDetails.integral_name}</span>
        </div>
        {/* 详情内容 */}
        <div className='commodity-details-context'>
          <div className='cdc-left cdc-children'>
            <div className='show-img'>
              <img src={$onChangeCommodityImage(integralDetails.images)} alt='商品详情' />
            </div>
            <CommoditySelect list={integralDetails.images} onChange={(element, index) => {
              setSelectColorIndex(element || 'none')
              setSelectImageIndex(index)
            }} />
          </div>
          <div className='cdc-right cdc-children'>
            <div className='commodity-title'>
              {integralDetails.integral_name}
            </div>
            <div className='commodity-options-item'>
              <span className='coi-label'>销量：</span>
              <div className='coi-value'>{integralDetails.sales_sum}</div>
            </div>
            <div className='commodity-options-item'>
              <span className='coi-label'>数量：</span>
              <div className='coi-value'>
                <InputNumber min={1} value={commodityNumber} onChange={(event) => setCommodityNumber(event)} />
              </div>
            </div>
            <div className='commodity-options-item'>
              <span className='coi-label'>价格：</span>
              <div className='coi-value'>
                <span className='span'>{integralDetails.exchange_integral}积分</span>
                <span className='span'>+</span>
                <span className='span'>￥{integralDetails.shop_price}</span>
              </div>
            </div>
            <div className='commodity-options-item'>
              <div className='add-car btn coi-children exchange' onClick={onClickExchange}>立即兑换</div>
              
            </div>
            <div className='commodity-options-item'>
              <span className='coi-label'>服务：</span>
              <div className='coi-value'>
                {`付款后7-15天内发货，物流周期7-12天。长途运输+同城配送+步梯 <=3 楼免费搬楼(电梯入户免费搬楼)+安装(拆包/拼渡等)`}
              </div>
            </div>
          </div>
        </div>

        {/* 商品评价 */}
        <div className='tabs'>
          <div className='tabs-title-list'>
            <div className={`tabs-title-item ${activeTab === 0 && 'tabs-active'}`} onClick={() => setActiveTab(0)}>商品详情</div>
            {/* <div className={`tabs-title-item ${activeTab === 1 && 'tabs-active'}`} onClick={() => setActiveTab(1)}>商品评价</div> */}
          </div>
          <div className='tabs-content'>
            {
              activeTab === 0 ? (<div dangerouslySetInnerHTML={{ __html: integralDetails.integral_content }} ></div>) : (
                <div className='commodity-evaluate'>
                  <Empty text='暂无评价' />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntegralDetails