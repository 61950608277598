import React, { useState, useEffect } from 'react'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import banner from '@/assets/images/design-master.jpg'

import Title from '@/components/title'
import Lnk from '@/components/lnk'

const DesignMaster = () => {
  document.title = '设计大咖'
  const [designMasterList, setDesignMasterList] = useState([])

  useEffect(() => {
    axios.post('Designer/levelDesignerList', Qs.stringify({
      channel: 3,
      num: 3,
      level_id: 2
    })).then(res => {
      setDesignMasterList(res.result)
    })
  }, [])

  return (
    <div className='design-master'>
      <img className='banner-img' src={banner} alt='设计大咖' />
      <Title title='金牌设计师' describe='生活方式由你原创，万般风情，没有压力' />
      <div className='design-master-context'>
        {
          designMasterList && designMasterList.map(element => {
            return (
              <div className='design-master-item' key={element.designer_id}>
                <div className='design-master-item-img' style={{backgroundImage: `url(${element.head_pic})`}}>
                  <div className='design-master-round'>
                    {element.level}
                  </div>
                  <div className='design-master-cover'>
                    <div className='design-master-cover-content'>
                      {element.description}
                    </div>
                    <Lnk className='learn-more' target='_blank' to={`/designer-details?designer=${element.designer_id}`}>了解更多</Lnk>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default DesignMaster