import React, { useState, useEffect } from 'react'
import Qs from 'qs'
import axios from '@/api'
import queryString from 'querystring'
import './index.scss'

import Lnk from '@/components/lnk'

const NewsDetails = (props) => {
  document.title = '新闻详情'
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [newsDetails, setNewsDetails] = useState({})

  useEffect(() => {
    axios.post('Article/newsDetail', Qs.stringify({
      channel: 3,
      ...propsParams
    })).then(res => {
      setNewsDetails(res.result)
    })
  }, [propsParams])

  return (
    <div className='news-details'>
      <div className='context'>
        <div className='news-details-left'>
          <div className='ndl-context'>
            <div className='ndl-context-title'>
              {newsDetails.info && newsDetails.info.title}
            </div>
            <div className='ndl-context-info'>
              <span>时间：{newsDetails.info && newsDetails.info.publish_time}</span>
              <span>原创：{newsDetails.info && newsDetails.info.category}</span>
              <span>浏览量：{newsDetails.info && newsDetails.info.read_num}</span>
            </div>
            <div dangerouslySetInnerHTML={{ __html: newsDetails.info && newsDetails.info.content }} ></div>
          </div>
        </div>
        <div className='news-details-right'>
          <div className='ndr-context'>
            <div className='ndr-content'>
              <div className='ndr-content-title'><span>你可能感兴趣的</span></div>
              {
                newsDetails.interest && newsDetails.interest.map((element, index) => {
                  return (
                    <div className='ndr-list' key={index}>
                      <Lnk target='_blank' to={`/news-details?article_id=${element.article_id}`}>
                        <span>{index + 1}</span>
                        <span>{element.title}</span>
                      </Lnk>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsDetails