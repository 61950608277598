import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Lnk from '../../components/lnk'

import { message, Modal, Input } from 'antd'
import deng from '@/assets/images/deng.png'

const Account = () => {
  document.title = '账号安全'

  const [account, setAccount] = useState({})
  const [kjlAccount, setKjlAccount] = useState('') // 酷家乐账号
  const [isBindAccount, setIsBindAccount] = useState(false)

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('User/pcUserInfo', params).then((res) => {
      console.log(res.result);
      setAccount(res.result)
    })
  }, []);

  function out () {
    let keys = document.cookie.match(/[^ =;]+(?==)/g)
    console.log('keys', keys);
    if (keys) {
      for (let i = 0; i <= keys.length; i++) {
        Cookies.remove(keys[i])
      }
    }
    window.location.href = "/login"
    message.success('退出成功')
  }
  // 绑定酷家乐账号
  const $onClickBindAccount = () => {
    if(!kjlAccount) {
      message.info('请输入酷家乐账号')
      return
    }
    let token = Cookies.get('token')
    let params = Qs.stringify({
      token,
      channel: 1,
      email: kjlAccount
    })
    axios.post('Designer/kujiale_change_user', params).then(res => {
      setKjlAccount('')
      message.info(res.msg)
    })
    setIsBindAccount(false)
  }

  return (
    <div className="account">
      {/* 绑定酷家乐账号 */}
      <Modal
        okText="绑定"
        cancelText="取消"
        title="绑定酷家乐账号"
        footer={null}
        maskClosable={false}
        centered={true}
        visible={isBindAccount}
        onCancel={() => setIsBindAccount(false)}
      >
      <div className="band-account-modal">
        <Input placeholder="请输入酷家乐账号" value={kjlAccount} onInput={event => {
          event.stopPropagation()
          setKjlAccount(event.target.value)
        }} />
        <div className="submit-btn" onClick={$onClickBindAccount}>绑定</div>
      </div>
      </Modal>
      <div className="account-content">
        {/* 基础信息 */}
        <div className="account-top">
          <h4 className="top-title">您的基础信息</h4>
          <div className="top-content">
            <p>用户名：{account.username}</p>
            <p>登录邮箱：{account.email}</p>
            <p>绑定手机：{account.mobile}</p>
          </div>
          <div className="account" onClick={() => setIsBindAccount(true)}>绑定酷家乐账号</div>
          <div className="out" onClick={out}>退出账号</div>
        </div>
        {/* 安全服务 */}
        <div className="account-bottom">
          <h4 className="security">您的安全服务</h4>
          <div className="bottom-content">
            <p className="set">
              <img src={deng} alt="" />
              <span>已设置</span>
            </p>
            <p className="psw">登录密码</p>
            <p className="word">安全性高的密码可以使账号更安全,建议您定期 更换密码,切设置一个包含数字 和字母,并长度超过6位以上的密码</p>
            <p className="modify">
              <Lnk target='_blank' to="/manage/change-password" className="change">前往修改</Lnk>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Account