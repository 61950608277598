import React,{ useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'
import { API } from '../../api/config'

import { Form, Rate, Input, Button, Upload, message } from 'antd'

const GoodsEvaluation = (props) =>{
  document.title = '未评价-评价'

  const { TextArea } = Input
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [evaluation, setEvaluation] = useState({});
  const [fileList, setFileList] = useState([]) //上传图片
  const [goodsValue, setGoodsValue] = useState(0); //商品评价
  const [describeValue, setDescribeValue] = useState(0) //描述评价
  const [logisticsValue, setLogisticsValue] = useState(0) //物流评价
  const [serviceValue, setServiceValue] = useState(0) //服务评价
  const [evaluationImg, setEvaluationImg] = useState([]); //评价图片
  
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      order_id: propsParams.order_id
    })
    axios.post('User/commentInfo',params).then((res) => {
      console.log('>>>>>>',res.result.goodsList)
      setEvaluation(res.result.goodsList)
    })
  }, [propsParams]);

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }
  
  const onChanges = ({ fileList: newFileList }) => {
    let image = ''
    fileList.map((element) => {
      if (typeof element.response !== 'undefined') {
        image = element.response.result
          // console.log('index>>>>',image)
          // setEvaluationImg(image)
      }
    })
    if (evaluationImg.indexOf(image) === -1 && image != '') {
      evaluationImg.push(image)
    }
    console.log('evaluationImg>>>>',evaluationImg)
    setFileList(newFileList)
  }
  // 商品评价
  const handleChangeGoods = (value) => {
    setGoodsValue(value)
    console.log('goodsValue',goodsValue)
  }
  // 描述评价
  const handleChangeDescribe = (value) => {
    setDescribeValue(value)
    console.log('describeValue',describeValue)
  }
  // 物流评价
  const handleChangeLogistics = (value) => {
    setLogisticsValue(value)
    console.log('logisticsValue',logisticsValue)
  }
  // 服务评价
  const handleChangeServiceValue = (value) => {
    setServiceValue(value)
    console.log('serviceValue',serviceValue)
  }

  // 提交
  const $onFinish = (e) =>{
    let newImg = evaluationImg.join(',')
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      order_id: propsParams.order_id,
      is_anonymous: 1,
      rank: goodsValue,
      describe_score: describeValue,
      logistics_score: logisticsValue,
      seller_score: serviceValue,
      img: newImg,
      ...e
    })
    
    axios.post('User/addConmment',params).then((res) => {
      message.success(res.msg)
    })
  }
  return (
    <div className="evaluation">
      <div className="goods-evaluation">
        <div className="evaluation-title">商品评价</div>
        <div className="evaluation-content">
          <Form onFinish={$onFinish}>
            <span className="shop-title">商品：</span>
            <Rate className="evaluation-icon" onChange={handleChangeGoods} value={goodsValue} />
            <span className="title-style">描述：</span>
            <Rate className="evaluation-icon" onChange={handleChangeDescribe} value={describeValue} />
            <span className="title-style">物流：</span>
            <Rate className="evaluation-icon" onChange={handleChangeLogistics} value={logisticsValue} />
            <span className="title-style">服务：</span>
            <Rate className="evaluation-icon" onChange={handleChangeServiceValue} value={serviceValue} />
            {/* 评价用户信息 */}
            {
              Array.isArray(evaluation) && evaluation.map((element,index) => {
                return (
                  <div key={index} className="evaluation-info">
                    <div className="info-img">
                      <img src={element.original_img} alt=""/>
                    </div>
                    <div className="info-name">{element.goods_name}</div>
                    <div className="info-price">售价：<span className="info-price-style">{element.really_price}</span></div>
                    <div className="info-num">{element.goods_num}</div>
                  </div>
                )
              })
            }
            <p className="evaluation-information">评价内容：</p>
            <Form.Item name="content">
              <TextArea placeholder="请输入文字描述"></TextArea>
            </Form.Item>
            <Upload
              action={API + 'User/upload'}
              listType="picture-card"
              fileList={fileList}
              onChange={(event) => onChanges(event, 'image')}
              onPreview={onPreview}
            >
              {fileList.length < 5 && '+ Upload'}
            </Upload>
            <Button type="primary" htmlType="submit" className="submit-btn">
              提交
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default GoodsEvaluation