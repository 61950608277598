import React, { useState, useEffect, useCallback } from 'react'
import Qs from 'qs'
import axios from '@/api'
import { Pagination } from 'antd'
import { IMAGE_URL } from '@/api/config'
import './index.scss'

import StoreSearch from '@/components/store-search'

const StoreList = props => {

  const [storeList, setStoreList] = useState([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)

  useEffect(() => {
    let { params } = props.history.location
    $getStoreList(params)
  }, [$getStoreList])

  // 点击地图获取门店
  const $getStoreList = useCallback(
    (event, page) => {
      let params = {
        store_name: '',
        province_id: '',
        city_id: '',
        ...event
      }
      axios.post(`http://api.rs2012.com/index.php/WXAPI/Index/store_list/p/${page || 1}`, Qs.stringify(params)).then(res => {
        setStoreList(res.data || [])
        setTotal(res.page || 0)
      })
    },
    []
  )

  return (
    <div className="store-list">
      <div className="context">
        <StoreSearch onSearch={event => {
          $getStoreList(event)
          setPage(1)
        }} />
        <div className="store-list-group">
          {storeList.map((element) => (
            <div className="store-item" key={element.id}>
              <img className="store-img" src={/^http/.test(element.pic) ? element.pic : IMAGE_URL + element.pic} alt="门店图片" />
              <div className="store-info">
                <div className="store-name store-info-item">
                  {element.store_name}
                </div>
                <div className="store-info-item">
                  地址：{element.address}
                </div>
                <div className="store-info-item">电话：{element.telephone} </div>
              </div>
            </div>
          ))}
        </div>
        {
          total > 10 && (
            <div className="page-context">
              <Pagination current={page} total={total} onChange={event => {
                setPage(event)
                $getStoreList({}, event)
              }} /> <span className="total-page">共{total}页</span>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default StoreList
