import React, { memo, useEffect, useState } from 'react'
import './index.scss'
import propTypes from 'prop-types'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'


import Lnk from '@/components/lnk'
import { message } from 'antd'

const BrandList = ({ list }) => {

  // 收藏
  // const $onClickCollection = (id) => {
  //   // console.log('id', id)
  //   let token = Cookies.get('token')
  //   let params = Qs.stringify({
  //     token,
  //     channel: 1,
  //     goods_id: id
  //   })
  //   axios.post('Goods/collectGoods', params).then(res => {
  //     console.log('res', res.msg)
  //     message.success(res.msg)
  //   })
  // }
  
  return (
    <div className='brand-list'>
      {
        list && list.map((element, index) => {
          return (
            <div className="brand-item" key={index}>
              <Lnk target='_blank' to={`commodity-details?goods_id=${element.goods_id}`} key={index}>
                <div className='brand-img'>
                  <div className='brand-img-context'>
                    <img src={element.pic} alt='全屋套餐' />
                  </div>
                </div>

                <div className='brand-item-content'>
                  <p className='goods-name'>{element.goods_name}</p>
                </div>
                <div className="brand-item-center">
                  <p className="brand-sold">已售<span className='num'>{element.sales_sum}</span>件</p>
                  <p className="brand-price">￥{element.shop_price}</p>
                </div>
              </Lnk>
              {/* <div className="item-bottom-box">
                <div className="add-goods add-collection" onClick={() => {$onClickCollection(element.goods_id)}}>添加收藏</div>
                <div className="add-goods add-shopping-cart" onClick={() => {$AddShoppingCart(element.goods_id)}}>加入购物车</div>
              </div> */}
            </div>
            // <Lnk target='_blank' to={`commodity-details?goods_id=${element.goods_id}`} className='brand-item' key={index}>
              
            //   <div className='brand-img'>
            //     <div className='brand-img-context'>
            //       <img src={element.pic} alt='全屋套餐' />
            //     </div>
            //   </div>
              
            //   <div className='brand-item-content'>
            //     <p className='goods-name'>{element.goods_name}</p>
            //   </div>
            //   <div className="brand-item-center">
            //     <p className="brand-sold">已售<span className='num'>{element.sales_sum}</span>件</p>
            //     <p className="brand-price">￥{element.shop_price}</p>
            //   </div>
            //   <div className="item-bottom-box">
            //     <div className="add-goods add-collection">添加收藏</div>
            //     <div className="add-goods add-shopping-cart">加入购物车</div>
            //   </div>
            //   {/* <div className='brand-price'>￥{element.shop_price}</div>
            //   <div className='brand-sold'>已售<span className='num'>{element.sales_sum}</span>件</div> */}
            // </Lnk>
          )
        })
      }
    </div>
  )
}

BrandList.propTypes = {
  list: propTypes.array
}

BrandList.defaultProps = {
  list: []
}

export default memo(BrandList)