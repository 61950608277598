import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Lnk from '../../components/lnk'
import queryString from 'querystring'

import phone from '@/assets/images/phone_app.png'

const SubmitOrder = (props) => {
  document.title = '提交订单'

  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [orderInfo, setOrderInfo] = useState({})
  const [recommendGoods, setRecommendGoods] = useState({})
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      master_order_sn: propsParams.ordersn
    })
    axios.post('Cart/recommendGoods', params).then((res) =>{
      console.log('res',res.result)
      setRecommendGoods(res.result)
    })
  }, [propsParams])

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...propsParams
    })
    axios.post('User/return_order_info', params).then((res) => {
      setOrderInfo(res.result)
    })
  }, [propsParams]);
  return (
    <div className="submit-order">
      <div className="context">
        {/* 导航 */}
        <div className="avigation">
          <span className="home-page">
            <Lnk target="_blank" to="" className="home-page">首页</Lnk>
          </span>
          <i className="icon">></i>
          <span className="place-order">提交订单</span>
        </div>
        {/* 内容 */}
        <div className="submit-order-content">
          {/* 左侧 */}
          <div className="content-left">
            <div className="pay-success">
              <div className="pay-success-title">支付成功</div>
              <div className="pay-success-price"><span>{orderInfo.pay_price}</span>元</div>
              <div className="pay-success-order">
                <Lnk target="_blank" to={`/manage/back-orders-details?order_id=${orderInfo.order_id}`} className="see-order">查看订单</Lnk>
              </div>
              <div className="pay-success-tips">
                御秀公司不会以任何理由要求您提供银行卡信息或支付额外费用请谨防钓鱼链接或诈骗电话。
                <Lnk target="_blank" to="/about?id=2&parent=0&children=1" className="learn-more">了解详情 ></Lnk>
              </div>
            </div>
          </div>
          {/* 中间 */}
          <div className="content-center">
            <p>商品合计：￥{orderInfo.goods_price}</p>
            <p>运费：￥{orderInfo.shipping_price}</p>
            <p>实付：￥{orderInfo.pay_price}</p>
            <p>下单时间：{orderInfo.add_time}</p>
            <p>收货人：{orderInfo.consignee}</p>
            <p>联系电话：{orderInfo.mobile}</p>
            <p>收货地址：{orderInfo.address}</p>
          </div>
          {/* 右侧 */}
          <div className="content-right">
            <div className="top-phone">
              <img src={phone} alt=""/>
            </div>
            <div className="bottom-phone">
              <p>关注我们首单立减8元</p>
              <p className="order-logistics">
                <Lnk target="_blank" to="/about?id=1&parent=0&children=0" className="learn-order">随时随地了解订单物流>></Lnk>
              </p>
            </div>
          </div>
        </div>

        {/* 为您推荐 */}
        <div className="recommend">
          <div className="recommend-title">为您推荐</div>
          {/* 为您推荐内容 */}
          <div className="recommend-content">
            {
              Array.isArray(recommendGoods) && recommendGoods.map((element) => {
                return (
                  <div className="recommend-item"  key={element.goods_id}>
                    <Lnk target="_blank" to={`/commodity-details?goods_id=${element.goods_id}`}>
                      <div className="item-img">
                        <img src={element.pic} alt=""/>
                      </div>
                      <div className="item-content">
                        <div className="item-goods-name">{element.goods_name}</div>
                        <div className="item-goods-price">￥{element.shop_price}</div>
                        <div className="item-goods-evaluate">{element.comment_num}人好评</div>
                      </div>
                    </Lnk>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubmitOrder