import React, { useState, useEffect, memo } from 'react'
import { Modal, Row, Form, Col, Button, Input, Radio, Select, message } from 'antd';
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import designer3 from '@/assets/images/designer_o3.png'

import Lnk from '@/components/lnk'

const Appointment = () => {
  const VIEWPRONT = document.body.clientHeight
  const video = document.getElementById("videoPlayer")
  const [visible, setVisible] = useState(false)
  const [province, setProvince] = useState([]) // 省
  const [provinceId, setProvinceId] = useState() // 省id
  const [city, setCity] = useState([]) // 市

  const { Option } = Select
  const [form] = Form.useForm()

  useEffect(() => {
    axios.post('Designer/region', Qs.stringify({
      channel: 3,
      id: provinceId
    })).then(res => {
      if (!provinceId) {
        setProvince(res.result)
      } else {
        setCity(res.result)
      }
    })
  }, [provinceId])

  const $onFinish = (event) => {
    axios.post('Designer/meetDesigner', Qs.stringify({
      designer_id: 0,
      channel: 3,
      ...event
    })).then(res => {
      message.info(res.msg)
      form.resetFields()
      setVisible(false)
    })
      .catch(e => {
        message.error('预约失败')
        form.resetFields()
        setVisible(false)
      })
  }

  window.onscroll = function () {
    if(!video) {
      return
    }
    //为了保证兼容性，这里取两个值，哪个有值取哪一个
    //scrollTop就是触发滚轮事件时滚轮的高度
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    if(scrollTop + VIEWPRONT > 1200 && scrollTop < 600) {
      // 播放
      if(video.paused) {
        video.play()
      }
    } else {
      // 暂停
      if(!video.paused) {
        video.pause()
      }
    }
  }

  return (
    <div className='appointment'>
      {/*  */}
      {/* <Modal
        className='appointment-submit'
        visible={visible}
        onCancel={() => {
          form.resetFields()
          setVisible(false)
        }
        }
        footer={null}
        width={400}
      >
        <div style={{ padding: '20px' }}>
          <p style={{ marginBottom: '20px' }}>快速提交需求，定制属于自己的“家”</p>
          <Form
            name="basic"
            form={form}
            initialValues={{ sex: 0 }}
            onFinish={$onFinish}
          >
            <Row>
              <Col span={11}>
                <Form.Item
                  name="contact_name"
                  rules={[{ required: true, message: '请输入姓名' }]}
                >
                  <Input placeholder='请输入姓名' />
                </Form.Item>
              </Col>
              <Col className='col-right' span={11} offset={2}>
                <Form.Item
                  name="sex"
                  rules={[{ required: true, message: '请选择性别' }]}
                >
                  <Radio.Group value={0}>
                    <Radio value={0}>先生</Radio>
                    <Radio value={1}>女士</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="contact_mobile"
              rules={[() => ({
                validator (rule, value) {
                  if (value && (/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
                    return Promise.resolve()
                  }
                  return Promise.reject('请检查手机号是否正确!')
                }
              })]}
            >
              <Input placeholder='请输入手机号' />
            </Form.Item>
            <Row>
              <Col span={11}>
                <Form.Item
                  name="province_id"
                  rules={[{ required: true, message: '请选择地区' }]}
                >
                  <Select onChange={(value) => setProvinceId(value)}>
                    {
                      Array.isArray(province) && province.map(element => {
                        return (
                          <Option value={element.id} key={element.id}>{element.name}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col className='col-right' span={11} offset={2}>
                <Form.Item
                  style={{ width: '100%' }}
                  name="city_id"
                  rules={[{ required: true, message: '请选择地区' }]}
                >
                  <Select>
                    {
                      Array.isArray(city) && city.map(element => {
                        return (
                          <Option value={element.id} key={element.id}>{element.name}</Option>
                        )
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="remarks"
            >
              <Input placeholder='请输入备注信息' />
            </Form.Item>
            <Button className='submit' htmlType="submit">立即申请</Button>
          </Form>
        </div>
      </Modal> */}
      {/*  */}
      <div className='video-content'>
        <video id="videoPlayer" className="video" muted="muted" controls poster="https://wxxcx1.rs2012.com/Public/video/video.jpg">
          <source src="https://wxxcx1.rs2012.com/Public/video/video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className='designer-right'>
        <Lnk className='top designer' target='_blank' to='/store'>
          {/* <span className='title'>预约设计师</span>
          <span className='title-english'>DESIGNER</span>
          <span className='introduce'>求解空间怎么利用？</span>
          <span className='introduce'>色彩怎么搭配？</span>
          <span className='introduce'>对尺寸没把握？</span>
          <span className='introduce'>一键呼叫！</span>
          <span className='introduce'>你身边的设计师吧！</span>
          <div className='appointment-btn' onClick={() => setVisible(true)}>点击预约设计师</div> */}
        </Lnk>
        <Lnk className='bottom cover' target='_blank' to='/soft'>
          <img src={designer3} alt="软装馆" />
        </Lnk>
      </div>
    </div>
  )
}

export default memo(Appointment)