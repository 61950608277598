import React, { memo, useRef } from 'react'
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import './index.scss'

const CommoditySelect = ({list, onChange}) => {
  const ref = useRef(null);

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev();
    }
  };

  const params = {
    slidesPerView: 3,
    spaceBetween:10,
    slidesPerGroup: 1,
    loop: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }
  }

  return (
    <div className='commodity-select'>
      <div className='commodity-select-context'>
        <Swiper {...params} ref={ref}>
          {
            list && list.map((element, index) => {
              return (
                <div className='swiper-item' key={element.img_id}>
                  <div className='swiper-item-context'>
                    <div className='top-white'></div>
                    <img onClick={() => onChange(element.color_title, index)} src={element.image_url} alt="商品图片" />
                  </div>
                </div>
              )
            })
          }
        </Swiper>
        <i className='iconfont icon-left-double' onClick={goPrev}></i>
        <i className='iconfont icon-right-double' onClick={goNext}></i>
      </div>
    </div>
  )
}

export default memo(CommoditySelect)