import React, { useState, useEffect } from 'react'
import { Modal, Row, Form, Col, Button, Input, Radio, Select, message } from 'antd';
import queryString from 'querystring'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import banner from '@/assets/images/designer-details.jpg'

import Title from '@/components/title'
import Works from './components/works'
import Cookies from "js-cookie";

const DesignerDetails = (props) => {
  document.title = '设计师详情'

  const [designerDetails, setDesignerDetails] = useState({})
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [visible, setVisible] = useState(false)
  const [province, setProvince] = useState([]) // 省
  const [provinceId, setProvinceId] = useState() // 省id
  const [city, setCity] = useState([]) // 市

  const { Option } = Select
  const [form] = Form.useForm()

  useEffect(() => {
    axios.post('Designer/region', Qs.stringify({
      channel: 3,
      id: provinceId
    })).then(res => {
      if (!provinceId) {
        setProvince(res.result)
      } else {
        setCity(res.result)
      }
    })
  }, [provinceId])

  useEffect(() => {
    axios.post('Product/designerProductRecommend', Qs.stringify({
      channel: 3,
      num: 5,
      ...propsParams
    })).then(res => {
      setDesignerDetails(res.result)
    })
  }, [propsParams])

  const onClickSetDesigner = (event)=>{
    console.log(event)
    let token = Cookies.get('token')
    axios.post('Designer/collectDesigner', Qs.stringify({
      channel: 1,
      token,
      designer_id: event.designer,
    })).then(res => {
      message.success(res.msg)
    })
  }
  const $onFinish = (event) => {
    axios.post('Designer/meetDesigner', Qs.stringify({
      channel: 3,
      ...propsParams,
      ...event
    })).then(res => {
      message.info(res.msg)
      form.resetFields()
      setVisible(false)
    })
      .catch(e => {
        message.error('预约失败')
        form.resetFields()
        setVisible(false)
      })
  }

  return (
    <div className='designer-details'>
      {/*  */}
      <Modal
        className='appointment-submit'
        visible={visible}
        onCancel={() => {
          form.resetFields()
          setVisible(false)
        }
        }
        footer={null}
        width={400}
      >
        <p style={{ marginBottom: '20px' }}>快速提交需求，定制属于自己的“家”</p>
        <Form
          name="basic"
          form={form}
          initialValues={{ sex: 0 }}
          onFinish={$onFinish}
        >
          <Row>
            <Col span={11}>
              <Form.Item
                name="contact_name"
                rules={[{ required: true, message: '请输入姓名' }]}
              >
                <Input placeholder='请输入姓名' />
              </Form.Item>
            </Col>
            <Col className='col-right' span={11} offset={2}>
              <Form.Item
                name="sex"
                rules={[{ required: true, message: '请选择性别' }]}
              >
                <Radio.Group value={0}>
                  <Radio value={0}>先生</Radio>
                  <Radio value={1}>女士</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="contact_mobile"
            rules={[() => ({
              validator (rule, value) {
                if (value && (/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
                  return Promise.resolve()
                }
                return Promise.reject('请检查手机号是否正确!')
              }
            })]}
          >
            <Input placeholder='请输入手机号' />
          </Form.Item>
          <Row>
            <Col span={11}>
              <Form.Item
                name="province_id"
                rules={[{ required: true, message: '请选择地区' }]}
              >
                <Select onChange={(value) => setProvinceId(value)}>
                  {
                    Array.isArray(province) && province.map(element => {
                      return (
                        <Option value={element.id} key={element.id}>{element.name}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col className='col-right' span={11} offset={2}>
              <Form.Item
                style={{ width: '100%' }}
                name="city_id"
                rules={[{ required: true, message: '请选择地区' }]}
              >
                <Select>
                  {
                    Array.isArray(city) && city.map(element => {
                      return (
                        <Option value={element.id} key={element.id}>{element.name}</Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="remarks"
          >
            <Input placeholder='请输入备注信息' />
          </Form.Item>
          <Button className='submit' htmlType="submit">立即申请</Button>
        </Form>
      </Modal>
      {/*  */}
      <img className='banner-img' src={banner} alt='设计师详情' />
      <div className='context'>
        <Title title='设计师简介' />
        <hr style={{ margin: '20px 0 50px' }} />
        <div className='designer-details-content'>
          <div className='designer-details-content-left'>
            <img src={designerDetails.info && designerDetails.info.head_pic} alt='设计师' />
            <div className='designer-name'>
              <div>
                {
                  designerDetails.info && designerDetails.info.nickname
                }
              </div>
            </div>
          </div>
          <div className='designer-details-content-right'>
            <div className='designer-options'>
              <div className='designer-options-btn' onClick={() => setVisible(true)}>预约设计师</div>
              {designerDetails.info && designerDetails.info.is_collect ?(
                  <div className='designer-options-btn'>已关注</div>
              ):(
                  <div className='designer-options-btn' onClick={() => {onClickSetDesigner(propsParams)}}>关注</div>
              )}

            </div>
            <div className='designer-style'>
              <p>所在单位：{designerDetails.info && designerDetails.info.company_name}</p>
              <p>工作年限：{designerDetails.info && designerDetails.info.job_year}</p>
              <p>擅长风格: {designerDetails.info && designerDetails.info.style}</p>
              <p>{designerDetails.info && designerDetails.info.describe}</p>
              <p>
                <i className='iconfont icon-look'></i>{designerDetails.info && designerDetails.info.view_num}
                <i className='iconfont icon-like1'></i>{designerDetails.info && designerDetails.info.fans}
              </p>
            </div>
          </div>
        </div>
        <Works list={designerDetails.product_list} />
      </div>
    </div>
  )
}

export default DesignerDetails