import React, { useState, useEffect } from 'react'
import { Carousel } from 'antd'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'
import Lnk from '@/components/lnk'
import Appointment from './components/appointment'
import Title from '@/components/title'
import Type from '@/components/type'
import Works from '@/components/works'
import Design from './components/design'
import Designer from './components/designer'
import ServiceBanner from './components/service-banner'
import Recommend from './components/recommend'
import News from './components/news'
import qrCode from '@/assets/images/qr-code.png'
import applets from '@/assets/images/applets.jpg'

const Index = (props) => {
  const [bannerList, setBannerList] = useState([])
  const [worksTypeList, setWorksTypeList] = useState([])
  const [worksList, setWorksList] = useState([])
  const [worksType, setWorksType] = useState('0')
  const [recommendList, setRecommendList] = useState([])

  useEffect(() => {
    axios
      .post(
        'Index/bannerList',
        Qs.stringify({
          pid: 3,
          channel: 3
        })
      )
      .then((res) => {
        setBannerList(res.result)
      })

    axios
      .post(
        'product/getStyle',
        Qs.stringify({
          channel: 3,
          pid: 3
        })
      )
      .then((res) => {
        res.result &&
          res.result.forEach((element) => (element.name = element.style_name))
        res.result.unshift({
          id: '0',
          sort: '0',
          name: '全部'
        })
        res.result.push({
          id: 'more',
          sort: 'more',
          name: '更多',
          path: '/popular-works'
        })
        setWorksTypeList(res.result)
      })

    axios
      .post(
        'Index/homeSpace',
        Qs.stringify({
          channel: 3
        })
      )
      .then((res) => {
        res.result = res.result.filter(
          (element) =>
            Array.isArray(element.goodsList) && element.goodsList.length >= 3
        )
        setRecommendList(res.result)
      })
  }, [])

  useEffect(() => {
    axios
      .post(
        'product/productList',
        Qs.stringify({
          channel: 3,
          style: worksType,
          limit: 6,
          page: 1
        })
      )
      .then((res) => {
        res.result &&
          Array.isArray(res.result.list) &&
          setWorksList(res.result.list)
      })
  }, [worksType])

  const $onClickService = () => {
    window.open('http://wpa.qq.com/msgrd?v=3&uin=2992124446&site=qq&menu=yes');
  }
  const $onClickTop = () => {
    window.scrollTo(0,0)
  }
  return (
    <div className="index">
      <Carousel className="carousel" autoplay dots={false}>
        {bannerList &&
          bannerList.map((element) => {
            return (
              <div className="carousel-item" key={element.ad_id}>
                <img src={element.ad_code} alt="banner" />
              </div>
            )
          })}
      </Carousel>
      {/* <Service /> */}
      <div className="right-fixed">
          <div className="fixed-box">
            <Lnk
              target="_blank"
              to="/manage/my-order?pid=0&cid=1"
              className="fixed-box-content"
            >
              <div className="box-content">
                <i className="iconfont icon-ren box-tu"></i>
                <p className="box-content-word">个人中心</p>
              </div>
            </Lnk>
            <div className="fixed-box-content" onClick={$onClickService}>
              <div className="box-content">
                <i className="iconfont icon-kefu2 box-tu"></i>
                <p className="box-content-word">客服</p>
              </div>
            </div>
            <Lnk target="_blank" to="/shop-car" className="fixed-box-content">
              <div className="box-content">
                <i className="iconfont icon-car box-tu"></i>
                <p className="box-content-word">购物车</p>
              </div>
            </Lnk>
            <div className="fixed-box-content qrCode">
              <div className="box-content">
                <i className="iconfont icon-saoma box-tu"></i>
                <p className="box-content-word">公众号</p>
              </div>
              <img src={qrCode} alt="二维码" className="qrCode-tu" />
            </div>
            <div className="fixed-box-content applets">
              <div className="box-content">
                <i className="iconfont icon-tub- box-tu"></i>
                <p className="box-content-word">小程序</p>
              </div>
              <img src={applets} alt="小程序" className="applets-tu" />
            </div>
            <Lnk className="fixed-box-content" target="_blank" to="/source-download">
              <div className="box-content">
                <i className="iconfont icon-xiazai- box-tu"></i>
                <p className="box-content-word">资料下载</p>
              </div>
            </Lnk>
            <div className="fixed-box-content" onClick={$onClickTop}>
              <div className="box-content last-box-content">
                <i className="iconfont icon-icon box-tu"></i>
                <p className="box-content-word">返回顶部</p>
              </div>
            </div>
          </div>
        </div>
      <div className="context">
        {/* 预约设计师 */}
        <Appointment />
        <Title
          title="优秀作品"
          describe="生活方式由你原创，万般风情，没有压力"
        />
        <Type
          list={worksTypeList}
          type={worksType}
          onChange={(value) => setWorksType(value)}
        />
        <Works list={worksList} />
      </div>
      {/* 5D设计 */}
      <Design />
      <div className="context">
        <div className="designer-title">
          <Title
            title="专业设计师，您的户型全能画师"
            describe="让好的设计师为您的家添加一抹色彩"
          />
        </div>
        <Designer />
      </div>
      <ServiceBanner />
      <div className="context">
        <Recommend list={recommendList} />
      </div>
      {/* 家的学问 */}
      <News />
    </div>
  )
}

export default Index
