import React, { useState, useEffect } from 'react'
import './index.scss'
import 'react-area-linkage/dist/index.css'
import { pcaa } from 'area-data'
import { AreaSelect } from 'react-area-linkage'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import { API } from '../../api/config'
import queryString from 'querystring'

import {
  Tabs,
  Form,
  Input,
  Select,
  Radio,
  Checkbox,
  Button,
  Upload,
  message
} from 'antd'

const PublishingWorks = (props) => {
  document.title = '发布作品'

  const [form] = Form.useForm()
  const { TabPane } = Tabs
  const { Option } = Select
  const [houseType, setHouseType] = useState({})
  const [addressArea, setAddressArea] = useState({})
  const [region, setRegion] = useState({})
  const [cateList, setCateList] = useState({})
  const [goodsList, setGoodsList] = useState({})
  const [selectProductValue, setSelectProductValue] = useState({}) // 选中的产品项
  const [allContent, setAllContent] = useState({}) //input框内容
  const [allSelectCity, setAllSelectCity] = useState({}) //下拉城市列表内容
  const [addChapterList, setAddChapterList] = useState([{}])
  const [productList, setProductList] = useState([]) //添加产品列表
  const [newProductList, setNewProductList] = useState({}) //添加产品列表ID

  const [fileList, setFileList] = useState([]) //头像图片
  const [qrCode, setQrCode] = useState([]) //二维码图片
  const [chapter, setChapter] = useState([]) //章节图片
  const [erCode, setErCode] = useState() //二维码
  const [headImg, setHeadImg] = useState() //头像

  const [mainGraph, setMainGraph] = useState('')
  const [code, setCode] = useState('')
  const [chapterImg, setChapterImg] = useState([])
  const [styleArr, setStyleArr] = useState([]) //风格
  const [designArr, setDesignArr] = useState([]) //设计空间
  const [nextKey, setNextKey] = useState('1')
  const [roomSize, setRoomSize] = useState(0) // 设计面积
  const [room, setRoom] = useState(0) // 户型
  
  // const [newChapter, setNewChapter] = useState({})
  const [title, setTitle] = useState([]) //标题
  const [describe, setDescribe] = useState([]) // 描述

  const [propsParams] = useState(
    queryString.parse(props.location.search.substr(1))
  )
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('Designer/basic_options', params).then((res) => {
      // console.log('.....', res.result)
      setHouseType(res.result)
    })
  }, [])

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('Designer/region', params).then((res) => {
      setRegion(res.result)
    })
  }, [])
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      id: allSelectCity.value
    })
    axios.post('Designer/region', params).then((res) => {
      setRegion(res.result)
    })
  }, [allSelectCity])

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('Designer/get_cateList', params).then((res) => {
      // console.log('产品列表', res.result)
      setCateList(res.result)
    })
  }, [])
  useEffect(() => {
    if (typeof propsParams.product_id) {
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        token,
        type: 3,
        id: propsParams.product_id
      })
      // console.log('propsParams.product_id',propsParams.product_id)
      axios.post('designer/productInfo', params).then((res) => {
        
        let add = new Array()
        add[0] = res.result.province
        add[1] = res.result.city
        setAddressArea(add)
        setStyleArr(res.result.style || [])
        setDesignArr(res.result.product_space || [])
        setErCode(res.result.images_qrcode)
        if (res.result.images_qrcode) {
          setQrCode([
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: res.result.images_qrcode
            }
          ])
          setCode(res.result.images_qrcode)
        }
        setHeadImg(res.result.image)
        if (res.result.image) {
          setFileList([
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: res.result.image
            }
          ])
          setMainGraph(res.result.image)
        }
        setRoomSize(res.result.room_size)
        setRoom(res.result.room)

        // setNewChapter(res.result.chapter)
        // console.log('res.result.chapter',res.result.chapter)

        let temp = []
        let title1 = {}
        let describe1 = {}
        Array.isArray(res.result.chapter) &&
          res.result.chapter.forEach((element,index) => {
            let te = []
            
            element.img.forEach((el, i) => {
              te.push({
                uid: i,
                name: 'image.png',
                status: 'done',
                url: el
              })
            })
            temp.push(te)

            if (element.title !== undefined) {
              title1[`chapter[${index}][title]`] = element.title
            }
            if (element.describe !== undefined) {
              describe1[`chapter[${index}][describe]`] = element.describe
            }
          })

        setChapter(temp)
        setTitle(title1)
        setDescribe(describe1)
        
        // 默认产品图片
        if (res.result.goodsList) {
          setProductList(res.result.goodsList)
          let arrProduct = []
          Array.isArray(res.result.goodsList) && res.result.goodsList.forEach((element) => {
            arrProduct.push(element.goods_id)
          })
          setNewProductList(arrProduct)
        }

        // 新增章节默认显示
        let chapterNum = []
         if (Array.isArray(res.result.chapter) && res.result.chapter.length) {
          res.result.chapter.forEach((element) => {
            chapterNum.push(element)
          })
        } else {
          chapterNum = [{}]
        }

        setAddChapterList(chapterNum)

        form.setFieldsValue({
          ...res.result,
          ...title1,
          ...describe1,
        })
      })
    }
  }, [propsParams, form])

  useEffect(() => {
      
    let token = Cookies.get('token')
    let params = Qs.stringify({
      token,
      channel: 1,
      id: selectProductValue.value
    })
    axios.post('Designer/get_goodsList', params).then((res) => {
      setGoodsList(res.result)
    })
  }, [selectProductValue])

  // 封面图
  const onChanges = ({ fileList: newFileList }) => {
    let image = ''
    fileList.map((element) => {
      image = (element.response && element.response.result) || element.url
      setMainGraph(image)
    })
    setFileList(newFileList)
  }
  // 二维码
  const onChangeCode = ({ fileList: newFileList }) => {
    let erImage = ''
    qrCode.map((element) => {
      erImage = (element.response && element.response.result) || element.url
      setCode(erImage)
    })
    setQrCode(newFileList)
  }
  // 章节空间
  const onChangeChapter = ({ fileList: newFileList }, tag) => {
    newFileList.map((element, index) => {
      let images = ''
      let img = []
      let aa = []
      if (typeof element.response !== 'undefined') {
        images = element.response.result

        //console.log('images', images)
        aa['img'] = images
        img.push(aa)

        setChapterImg([...chapterImg, img])
        // console.log("111:",img)
        // console.log("222:",chapterImg)
      }
    })
    //console.log("123:",tag)
    chapter[tag] = newFileList
    console.log('..',newFileList)
    setChapter({ ...chapter })
    //console.log('chapter', chapter)
  }

  const $onChangeAll = (e, tag) => {
    e.persist()
    let temp = {}
    temp[tag] = e.target.value
    setAllContent({
      ...allContent,
      ...temp
    })
  }
  // 基本选项
  const $onChangeChecked = (e, tag) => {
    if (tag === 'style') {
      let style = styleArr.indexOf(e) > -1 ? true : false
      let newStyle = []
      if (style) {
        newStyle = styleArr.filter((element) => element !== e)
      } else {
        newStyle = [...styleArr, e]
      }
      setStyleArr(newStyle)
    }
    if (tag === 'product_space') {
      let space = designArr.indexOf(e) > -1 ? true : false
      let newSpace = []
      if (space) {
        newSpace = designArr.filter((element) => element !== e)
      } else {
        newSpace = [...designArr, e]
      }
      setDesignArr(newSpace)
    }
    if (tag === 'room') {
      let room = e.target.value
      setRoom(room)
    }
    if (tag === 'room_size') {
      let room_size = e.target.value
      setRoomSize(room_size)
    }
  }

  // 添加产品
  const $handleCity = (value, tag) => {
    if (tag === 'product') {
      setSelectProductValue(value)
    } else {
      let item = goodsList.filter((element) => element.goods_id === value.value)
      setProductList([...productList, ...item])

      let newProduct = {}
      newProduct = [...productList, ...item]
      let arrProduct = []
      Array.isArray(newProduct) && newProduct.forEach((element) => {
        arrProduct.push(element.goods_id)
      })
      setNewProductList(arrProduct)
    }
    
  }
  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }

  // 删除产品图片
  const onClickDelete = (element) => {
    console.log('=======',element.goods_id)
    Array.isArray(productList) && productList.forEach(item => {
      console.log('item',item.goods_id)
      if (item.goods_id === element.goods_id) {
        let arr5 = productList.filter((t) => t.goods_id !== element.goods_id)
        setProductList(arr5)
      }
    })
    let productListID = []
    Array.isArray(productList) && productList.forEach(element => {
      productListID.push(element.goods_id)
    })
    setNewProductList(productListID)
  }
  // 提交
  const $onFinish = (e) => {
    let province
    let json_province = e.event[0]
    for (var key_province in json_province) {
      province = key_province //获取key值
    }
    let city
    let json_city = e.event[1]
    for (var key_city in json_city) {
      city = key_city //获取key值
    }
    let token = Cookies.get('token')
    let arr = []
    for (let i in chapter) {
      arr.push(chapter[i])
    }
    let fd = new FormData()
    let newStyle1 = styleArr.join(',')
    let newSpace1 = designArr.join(',')

    let addImg = {}
    
    let newProductList1 = newProductList.join(',')
    
    for (const key in chapter) {
      let addChapter = []
      if (chapter.hasOwnProperty(key)) {
        const element = chapter[key]
        element.forEach((element) => {
          let url = (element.response && element.response.result) || element.url
          addChapter.push(url)
        })
        addImg[`chapter[${key}][img]`] = addChapter.join(',')
      }
    }

    let params = {
      channel: 1,
      token,
      ...e,
      ...allContent,
      image: mainGraph,
      images_qrcode: code,
      province: province,
      city: city,
      style: newStyle1,
      product_space: newSpace1,
      goods_id: newProductList1,
      room_size: roomSize,
      room: room,
      ...addImg,
    }

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key]
        if (element instanceof Object) {
          let str = []
          fd.append(key, str.join(','))
        } else {
          fd.append(key, element)
        }
      }
    }
    

    if (typeof propsParams.product_id === 'undefined') {
      fd.append('act', 'add')
    } else {
      fd.append('act', 'edit')
      fd.append('id', propsParams.product_id)
    }
    axios.post('designer/addProduct', fd).then((res) => {
      message.success(res.msg)
    })
  }
  return (
    <div className="publishing-works">
      <div className="publishing-works-content">
        <div className="card-container">
          <Form onFinish={$onFinish} form={form}>
            <Tabs
              type="card"
              tabBarGutter="0"
              activeKey={nextKey}
              onChange={(event) => setNextKey(event)}
            >
              <TabPane tab="基本信息" key="1">
                <div className="basic-info">
                  <Form.Item colon={false}>
                    <p className="publishing-title">类型 : 高清图</p>
                  </Form.Item>

                  <p className="publishing-title">封面图</p>
                  <Form.Item name="image">
                    <Upload
                      action={API + 'User/upload'}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={(event) => onChanges(event, 'image')}
                      onPreview={onPreview}
                    >
                      {fileList.length < 1 && '+ Upload'}
                    </Upload>
                    <p>
                      仅支持JPG、GIF、PNG、JPEG、BMP格式,文件小于1M,图片宽高比4:3
                    </p>
                  </Form.Item>

                  <p className="publishing-title">二维码</p>
                  <Form.Item name="images_qrcode">
                    <Upload
                      action={API + 'User/upload'}
                      listType="picture-card"
                      fileList={qrCode}
                      onChange={(event) => onChangeCode(event, 'images_qrcode')}
                      onPreview={onPreview}
                    >
                      {qrCode.length < 1 && '+ Upload'}
                    </Upload>
                    <p>
                      仅支持JPG、GIF、PNG、JPEG、BMP格式,文件小于500k,380*380px
                    </p>
                  </Form.Item>

                  <p className="publishing-title">作品名称</p>
                  <Form.Item name="title">
                    <Input onChange={(event) => $onChangeAll(event, 'title')} />
                  </Form.Item>

                  <p className="publishing-title">软装价格</p>
                  <Form.Item name="soft_price">
                    <Input
                      onChange={(event) => $onChangeAll(event, 'soft_price')}
                    />
                  </Form.Item>

                  <p className="publishing-title">硬装价格</p>
                  <Form.Item name="stuff_price">
                    <Input
                      onChange={(event) => $onChangeAll(event, 'stuff_price')}
                    />
                  </Form.Item>

                  <p className="publishing-title">省/市</p>
                  <Form.Item
                    label="所在地区"
                    className="consignee"
                    name="event"
                    rules={[
                      {
                        required: true,
                        message: '所在地区不能为空'
                      }
                    ]}
                  >
                    <AreaSelect
                      type="all"
                      level={1}
                      data={pcaa}
                      defaultArea={addressArea}
                    />
                  </Form.Item>
                  {/*<Form.Item>*/}
                  {/*  <Select*/}
                  {/*    style={{ width: 150 }}*/}
                  {/*    labelInValue*/}
                  {/*    onChange={(event) => $handleGoods(event, 'province')}*/}
                  {/*  >*/}
                  {/*    {Array.isArray(region) &&*/}
                  {/*      region.map((element, id) => {*/}
                  {/*        return (*/}
                  {/*          <Option key={id} value={element.id}>*/}
                  {/*            {element.name}*/}
                  {/*          </Option>*/}
                  {/*        )*/}
                  {/*      })}*/}
                  {/*  </Select>*/}
                  {/*  <Select*/}
                  {/*    style={{ width: 150 }}*/}
                  {/*    labelInValue*/}
                  {/*    onChange={(event) => $handleGoods(event, 'city')}*/}
                  {/*  >*/}
                  {/*    {Array.isArray(region) &&*/}
                  {/*      region.map((element, id) => {*/}
                  {/*        return (*/}
                  {/*          <Option key={id} value={element.id}>*/}
                  {/*            {element.name}*/}
                  {/*          </Option>*/}
                  {/*        )*/}
                  {/*      })}*/}
                  {/*  </Select>*/}
                  {/*</Form.Item>*/}

                  <p className="publishing-title">社区小区</p>
                  <Form.Item name="address">
                    <Input
                      onChange={(event) => $onChangeAll(event, 'address')}
                    />
                  </Form.Item>

                  <p className="publishing-title">作品介绍（建议100-200字）</p>
                  <Form.Item name="describe">
                    <Input.TextArea
                      onChange={(event) => $onChangeAll(event, 'describe')}
                    />
                  </Form.Item>
                  <Form.Item label="展示" name="status">
                    <Radio.Group
                      onChange={(event) => $onChangeChecked(event, 'status')}
                    >
                      <Radio value="1">上架</Radio>
                      <Radio value="2">下架</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <p className="publishing-title">酷家乐地址</p>
                  <Form.Item name="url">
                    <Input onChange={(event) => $onChangeAll(event, 'link')} />
                  </Form.Item>

                  <p className="publishing-title">高清全景地址</p>
                  <Form.Item name="urls">
                    <Input
                      onChange={(event) => $onChangeAll(event, 'gqlink')}
                    />
                  </Form.Item>
                  <div
                    className="setup"
                    onClick={() => {
                      setNextKey('2')
                    }}
                  >
                    下一步
                  </div>
                </div>
              </TabPane>

              <TabPane tab="基本选项" key="2" className>
                <div className="basic-option">
                  {/* 户型 */}
                  <p className="publishing-title">户型(单选)</p>
                  <Form.Item name="room">
                    <Radio.Group>
                      {Array.isArray(houseType.apartment) &&
                        houseType.apartment.map((element, id) => {
                          return (
                            <Radio
                              value={element.id}
                              key={id}
                              style={{ width: '13%' }}
                              onChange={(event) =>
                                $onChangeChecked(event, 'room')
                              }
                            >
                              {element.name}
                            </Radio>
                          )
                        })}
                    </Radio.Group>
                  </Form.Item>
                  {/* 风格 */}
                  <p className="publishing-title">风格(多选)</p>
                  <Form.Item name="style">
                    <Radio.Group value={1}>
                      {Array.isArray(houseType.style) &&
                        houseType.style.map((element, id) => {
                          return (
                            <Checkbox.Group
                              value={styleArr}
                              style={{ width: '17.5%' }}
                              key={element.id}
                            >
                              <Checkbox
                                value={element.id}
                                onChange={() =>
                                  $onChangeChecked(element.id, 'style')
                                }
                              >
                                {/* {console.log(styleTab.indexOf(element))} */}
                                {/* {styleArr} */}
                                {element.name}
                              </Checkbox>
                            </Checkbox.Group>
                          )
                        })}
                    </Radio.Group>
                  </Form.Item>
                  {/* 设计空间 */}
                  <p className="publishing-title">设计空间(多选)</p>
                  <Form.Item name="product_space">
                    <Radio.Group value={1}>
                      {Array.isArray(houseType.space) &&
                        houseType.space.map((element, id) => {
                          return (
                            <Checkbox.Group
                              value={designArr}
                              style={{ width: '20%' }}
                              key={element.id}
                            >
                              <Checkbox
                                value={element.id}
                                onChange={() =>
                                  $onChangeChecked(element.id, 'product_space')
                                }
                              >
                                {element.name}
                              </Checkbox>
                            </Checkbox.Group>
                          )
                        })}
                    </Radio.Group>
                  </Form.Item>
                  {/* 设计面积 */}
                  <p className="publishing-title">设计面积(单选)</p>
                  <Form.Item name="room_size">
                    <Radio.Group value={'2'}>
                      {Array.isArray(houseType.size) &&
                        houseType.size.map((element, id) => {
                          return (
                            <Radio
                              value={element.id}
                              key={id}
                              // checked={roomSize === element.id}
                              onChange={(event) =>
                                $onChangeChecked(event, 'room_size')
                              }
                            >
                              {/* {console.log('...',roomSize, element.id,roomSize === element.id)} */}
                              {element.name}
                            </Radio>
                          )
                        })}
                    </Radio.Group>
                  </Form.Item>
                  <div
                    className="setup"
                    onClick={() => {
                      setNextKey('1')
                    }}
                  >
                    上一步
                  </div>
                  <div
                    className="setup"
                    onClick={() => {
                      setNextKey('3')
                    }}
                  >
                    下一步
                  </div>
                </div>
              </TabPane>

              <TabPane tab="产品" key="3">
                <div className="product">
                  <div className="product-img">
                    {productList &&
                      productList.map((element) => {
                        return (
                          <img
                            src={element.original_img}
                            alt="商品"
                            key={element.goods_id}
                            onClick={() => onClickDelete(element)}
                          />
                        ) 
                      })}
                  </div>
                  <p className="publishing-title">添加产品（可多次添加）</p>

                  <Form.Item>
                    <Select
                      style={{ width: 150 }}
                      labelInValue
                      onChange={(event) => $handleCity(event, 'product')}
                    >
                      {Array.isArray(cateList) &&
                        cateList.map((element, id) => {
                          return (
                            <Option key={id} value={element.id}>
                              {element.name}
                            </Option>
                          )
                        })}
                    </Select>
                    <Select
                      style={{ width: 500 }}
                      labelInValue
                      onChange={(event) => $handleCity(event, 'goods')}
                    >
                      {Array.isArray(goodsList) &&
                        goodsList.map((element, id) => {
                          return (
                            <Option key={id} value={element.goods_id}>
                              {element.goods_name}
                            </Option>
                          )
                        })}
                    </Select>
                  </Form.Item>
                  <div
                    className="setup"
                    onClick={() => {
                      setNextKey('2')
                    }}
                  >
                    上一步
                  </div>
                  <div
                    className="setup"
                    onClick={() => {
                      setNextKey('4')
                    }}
                  >
                    下一步
                  </div>
                </div>
              </TabPane>

              <TabPane tab="章节(空间)" key="4">
                <div className="chapter">
                  {addChapterList.map((element, index) => {
                    return (
                      <div key={index}>
                        <p className="publishing-title">章节（空间）图集</p>
                        <Form.Item name={`chapter[${index}][img]`}>
                          <Upload
                            action={API + 'User/upload'}
                            listType="picture-card"
                            fileList={chapter[index]}
                            onChange={(event) => onChangeChapter(event, index)}
                          >
                            {fileList.length < 5 && '+ Upload'}
                          </Upload>
                        </Form.Item>

                        <p className="publishing-title">章节标题（空间名称）</p>
                        <Form.Item name={`chapter[${index}][title]`}>
                          <Input
                            onChange={(event) => $onChangeAll(event, index)}
                          />
                        </Form.Item>

                        <p className="publishing-title">章节（空间）描述</p>
                        <Form.Item name={`chapter[${index}][describe]`}>
                          <Input
                            onChange={(event) => $onChangeAll(event, index)}
                          />
                        </Form.Item>
                      </div>
                    )
                  })}
                  <span
                    className="top-step"
                    onClick={() => {
                      setNextKey('3')
                    }}
                  >
                    上一步
                  </span>
                  <span
                    className="addChapter"
                    onClick={() => {setAddChapterList([...addChapterList, {}])
                      // console.log('///.....',addChapterList.length)
                    }}
                  >
                    增加章节(空间)
                  </span>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                  >
                    提交
                  </Button>
                </div>
              </TabPane>
            </Tabs>
          </Form>
        </div>
      </div>
    </div>
  )
}
export default PublishingWorks
