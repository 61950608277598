import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'
import { API } from '../../api/config'

import Lnk from '../../components/lnk'
import { Form, Select, Input, Upload, Button, message} from 'antd'

const RefundInfo = (props) => {
  document.title = '我的订单-退款详情'

  const { TextArea } = Input
  const [refundInfo, setRefundInfo] =useState({})
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))

  const [fileList, setFileList] = useState([]) //上传图片
  const [evaluationImg, setEvaluationImg] = useState([]); //评价图片
  const [orderId, setOrderId] = useState()
  const [refundMoney, setRefundMoney] = useState()
  const [orderSn, setOrderSn] = useState()
  const [storeId, setStoreId] = useState()
  const [goodsId, setGoodsId] = useState()

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...propsParams,
    })
    axios.post('User/order_goods_detail', params).then((res) => {
      console.log('...',res.result.goods)
      let {order_id, refund_money, store_id, goods_id, order_sn} = res.result.goods
      setOrderId(order_id)
      setRefundMoney(refund_money)
      setStoreId(store_id)
      setGoodsId(goods_id)
      setOrderSn(order_sn)
      setRefundInfo(res.result.goods)
    })
  }, [propsParams]);

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }
  const onChanges = ({ fileList: newFileList }) => {
    let image = ''
    fileList.map((element) => {
      if (typeof element.response !== 'undefined') {
        image = element.response.result
          // console.log('index>>>>',image)
          // setEvaluationImg(image)
      }
    })
    if (evaluationImg.indexOf(image) === -1 && image != '') {
      evaluationImg.push(image)
    }
    console.log('evaluationImg>>>>',evaluationImg)
    setFileList(newFileList)
  }
  // 提交
  const onFinish = (e) => {
    let newImg = evaluationImg.join(',')
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      order_id: propsParams.order_id,
      rec_id: propsParams.rec_id,
      type: 1,
      is_delivery: 0,
      img: newImg,
      order_id: orderId,
      return_money: refundMoney,
      order_sn: orderSn,
      store_id: storeId,
      goods_id: goodsId,
      ...e
    })
    axios.post('User/user_order_services1', params).then((res) => {
      message.success(res.msg)
      console.log('w',e)
    })
  }
  return (
    <div className="refund">
      {/* 标题 */}
      <div className="reason">
        <span className="reason-style">退款原因填写</span>
      </div>
      {/* 内容 */}
      <div className="refund-info">
        {/* 左侧 */}
        <div className="refund-info-left">
          <div className="refund-info-img">
            <img src={refundInfo.original_img} alt=""/>
          </div>
          <div className="refund-info-name">{refundInfo.goods_name}</div>
          <div className="refund-info-num">数量{refundInfo.goods_num}</div>
        </div>
        {/* 右侧 */}
        <div className="refund-info-right">
          <Form onFinish={onFinish}>
            <Form.Item label="*退款原因" name="reason">
              <Select name="reason" style={{ width: 240 }}>
                <Select.Option value="尺寸拍错/不喜欢/效果不好">尺寸拍错/不喜欢/效果不好</Select.Option>
                <Select.Option value="商品成分描述不符">商品成分描述不符</Select.Option>
                <Select.Option value="质量问题3">质量问题</Select.Option>
                <Select.Option value="材料问题">材料问题</Select.Option>
                <Select.Option value="尺寸问题">尺寸问题</Select.Option>
                <Select.Option value="生产日期/保质期描述不符">生产日期/保质期描述不符</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="*原因描述" name="content">
              <TextArea placeholder="请输入文字描述"></TextArea>
            </Form.Item>
            <Form.Item label="*上传图片">
              <Upload
                action={API + 'User/upload'}
                listType="picture-card"
                fileList={fileList}
                onChange={(event) => onChanges(event, 'image')}
                onPreview={onPreview}
              >
                {fileList.length < 8 && '+ Upload'}
              </Upload>
              <p>上传图片可以让我们更好的为您处理更换退货(最多八张)</p>
            </Form.Item>
            <button className="return-btn">
              <Lnk target="_blank" to="/manage/finish-evaluation" className="see-details">返回</Lnk>
            </button>
            <Button type="primary" htmlType="submit" className="return-btn">提交</Button>
          </Form>
        </div>
      </div>
      
    </div>
  )
}

export default RefundInfo