import React, { useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import { Form, Input, Button, message } from 'antd'

const Change = () => {
  document.title = '修改密码'

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      old_password: '',
      password: ''
    })
  }, [])

  const onFinish = (e) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...e
    })
    axios.post('User/editPwd', params).then((res) => {
      message.success(res.msg)
    })
  }
  return (
    <div className="change">
      <div className="change-password">
        <Form
          onFinish={onFinish}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          form={form}
        >
          <Form.Item
            label="旧密码"
            name="old_passwords"
            rules={[
              {
                required: true,
                message: '旧密码不能为空'
              }
            ]}
          >
            <Input.Password placeholder="请输入旧密码" />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="password"
            rules={[
              {
                required: true,
                message: '新密码不能为空'
              }
            ]}
          >
            <Input.Password placeholder="请输入新密码" />
          </Form.Item>
          <Form.Item
            label="确认密码"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: '新密码与旧密码不符'
              }
            ]}
          >
            <Input.Password placeholder="请确认密码" />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="password-btn">
            保存
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Change
