import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import { message } from 'antd'

const Invite = () => {
  document.title = '邀请好友'
  const [infoDetails, setInfoDetails] = useState('')


  const copy = () => {
    var inputValue = document.getElementById('copy')
    inputValue.select() // 选择对象
    document.execCommand('Copy')
    message.success('复制成功')
  }

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('User/invite', params).then((res) => {
      console.log(res.result)
      setInfoDetails(res.result.invite_url)
    })
  }, [])

  return (
    <div className="invite">
      <div className="invite-content">
        <div className="content-top">邀请好友</div>
        <div className="content-bottom">
          <p>
            邀请好友注册得积分：您的好友用手机号主动注册成为御秀会员，
            您即可获得150积分
          </p>
          <p>方法一：发送链接邀请好友</p>
          <p>您的邀请码:</p>
          <div className="code">
            <input
              type="text"
              value={infoDetails}
              className="code-left"
              id='copy'
              readOnly
            />
            <input
              type="button"
              value="复制链接"
              className="code-link"
              onClick={copy}
            />
          </div>
          <p>您可以点击复制链接，然后发送到QQ、微信、微博、MSN等，分享给好友</p>
        </div>
      </div>
    </div>
  )
}

export default Invite
