import React, { memo } from 'react'
import './index.scss'

import Lnk from '@/components/lnk'

const Works = ({ list }) => {
  return (
    <div className='dd-works'>
      {
        list && list.map((element, index) => {
          return (
            <div className='dd-works-item' key={index}>
              <div className='dd-works-item-top'>
                <Lnk className='works-item-img' target='_blank' to={`/home-design-details?id=${element.product_id}`}>
                  <img src={element.image} alt='作品' />
                </Lnk>
              </div>
              <div className='dd-works-item-bottom'>
                <span>{element.product_style} {element.title}</span>
                <span>{element.view}人浏览</span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default memo(Works)