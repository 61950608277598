import React, { memo } from 'react'
import './index.scss'

import Lnk from '@/components/lnk'

const CommodityList = ({ list }) => {
  return (
    <div className='commodity-list'>
      {
        list && list.map((element, index) => {
          return (
            <div className='commodity-item' key={index}>
              <Lnk target='_blank' to={`commodity-details?goods_id=${element.goods_id}`} >
                <div className='commodity-img'>
                  <div className='commodity-img-context'>
                    <img src={element.pic} alt='商品库' />
                  </div>
                </div>
                <div className='commodity-item-content'>
                  <p className='goods-name'>{element.goods_name}</p>
                  <p className='goods-price'>￥{element.shop_price}</p>
                  <p className='goods-sales'>已售<span> {element.sales_sum} </span>件</p>
                </div>
              </Lnk>
            </div>
          )
        })
      }
    </div>
  )
}

export default memo(CommodityList)