import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import { Tabs, message} from 'antd';
import Lnk from '../../components/lnk'
import Empty from '@/components/empty'

const ExchangeRecords = () => {
  document.title = '积分兑换记录'

  const { TabPane } = Tabs;
  const [orderRecordsList, setOrderRecordsList] = useState([]) //全部订单记录
  const [waitOrderList, setWaitOrderList] = useState([])       //待发货订单记录
  const [waitReceiveList, setWaitReceiveList] = useState([])   //待收货订单记录

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      page: 1,
      type: 'all'
    })
    axios.post('Integral/integralOrderList', params).then((res) => {
      // console.log('全部订单', res.result.order_list)
      setOrderRecordsList(res.result.order_list)
    })
  }, []);
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      page: 1,
      type: 'waitsend'
    })
    axios.post('Integral/integralOrderList', params).then((res) => {
      // console.log('待发货', res.result.order_list)
      setWaitOrderList(res.result.order_list)
    })
  }, []);
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      page: 1,
      type: 'waitreceive'
    })
    axios.post('Integral/integralOrderList', params).then((res) => {
      // console.log('待收货', res.result.order_list)
      setWaitReceiveList(res.result.order_list)
    })
  }, [])

  // 取消订单
  const onClickCancel = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      order_id: id
    })
    axios.post('Integral/cancelOrder', params).then((res) => {
      message.success(res.msg)
      let params = Qs.stringify({
        channel: 1,
        token,
        type: 'all'
      })
      axios.post('Integral/integralOrderList', params)
    })
  }

  // 确认收货
  const onClickConfirm = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      order_id: id
    })
    axios.post('Integral/orderConfirm', params).then((res) => {
      message.success(res.msg)
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        token,
        page: 1,
        type: 'waitreceive'
      })
      axios.post('Integral/integralOrderList', params).then((res) => {
        window.location.reload();
      })
    })
  }

  return (
    <div className="exchange-records">
      <div className="records-content">
        <Tabs defaultActiveKey="1" type="card" tabBarGutter="0">
          <TabPane tab='全部订单' key="1" className="title">
            {/* 订单内容 */}
            <div className="all-order-records">
              {
                Array.isArray(orderRecordsList) && orderRecordsList.map((element) => {
                  return (
                    <div key={element.order_id}>
                      {/* 订单详情 */}
                      <div className="order-number">
                        <span className="number-label">下单时间: {element.add_time}</span>
                        <span className="number-label">订单号: {element.order_sn}</span>
                      </div>
                      {/* 商品名称 */}
                      <div className="order-content">
                        <span className="goods-name">商品名称</span>
                        <span className="goods-other">单价</span>
                        <span className="goods-other">数量</span>
                        <span className="goods-other">实付</span>
                        <span className="goods-other">收货人</span>
                        <span className="goods-other">状态</span>
                        <span className="goods-other">操作</span>
                      </div>
                      {/* 商品内容 */}
                      {
                        Array.isArray(element.integral_list) && element.integral_list.map((el) => {
                          return (
                            <div className="specific-content" key={el.rec_id}>
                              <div className="content-pic">
                                <img src={el.original_img} alt=""/>
                                <span className="pic-name">{el.integral_name}</span>
                              </div>
                              {/* 单价 */}
                              <div className="other-content">
                                <span className="content-price">￥{el.integral_price}</span>
                                <span className="content-integral">{el.member_integral_price}积分</span>
                              </div>
                              {/* 数量 */}
                              <div className="other-content">{el.integral_num}</div>
                              {/* 实付 */}
                              <div className="other-content">￥{el.integral_price}</div>
                              {/* 收货人 */}
                              <div className="other-content">{element.consignee}</div>
                              {/* 状态 */}
                              <div className="other-content">{element.order_status_desc}</div>
                              {/* 操作 */}
                              <div className="operation">
                                {
                                  element.order_status_desc === '已取消'
                                  ?
                                  (
                                    <span className="cancelled">订单已取消</span>
                                  )
                                  :
                                  (
                                    <>
                                    </>
                                  )
                                }

                                {
                                  element.pay_btn === 1
                                  ?
                                  (
                                    <>
                                      {/* <span className="remind">
                                        <Lnk target="_blank" to="" className="works-edit">
                                          支付
                                        </Lnk>
                                      </span> */}
                                      {
                                        element.cancel_btn === 1
                                        ?
                                        (<span className="cancel" onClick={() => onClickCancel(element.order_id)}>取消订单</span>)
                                        :
                                        (<span></span>)
                                      }
                                      <span className="see">
                                        <Lnk target="_blank" to={`/manage/exchangeRecords-details?order_id=${element.order_id}`} className="see-word">查看详情</Lnk>
                                      </span>
                                    </>
                                  )
                                  :
                                  (<span className="see"><Lnk target="_blank" to={`/manage/exchangeRecords-details?order_id=${element.order_id}`} className={element.order_status_desc === '已取消' ? 'see-word' : 'see-word-style'}>查看详情</Lnk></span>)
                                }
                              </div>

                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
              {(!Array.isArray(orderRecordsList) || !orderRecordsList.length) && (
                <div>
                  <Empty text="暂无订单" className="status" />
                </div>
              )}
            </div>
          </TabPane>

          <TabPane tab='待发货' key="2" className="title">
            <div className="all-order-records">
              {
                Array.isArray(waitOrderList) && waitOrderList.map((element) => {
                  return (
                    <div key={element.order_id}>
                      {/* 订单详情 */}
                      <div className="order-number">
                        <span className="number-label">下单时间: {element.add_time}</span>
                        <span className="number-label">订单号: {element.order_sn}</span>
                      </div>
                      {/* 商品名称 */}
                      <div className="order-content">
                        <span className="goods-name">商品名称</span>
                        <span className="goods-other">单价</span>
                        <span className="goods-other">数量</span>
                        <span className="goods-other">实付</span>
                        <span className="goods-other">收货人</span>
                        <span className="goods-other">状态</span>
                        <span className="goods-other">操作</span>
                      </div>
                      {/* 商品内容 */}
                      {Array.isArray(element.integral_list) && element.integral_list.map((el) => {
                        return (
                          <div className="specific-content" key={el.rec_id}>
                            <div className="content-pic">
                              <img src={el.original_img} alt=""/>
                              <span className="pic-name">{el.integral_name}</span>
                            </div>
                            {/* 单价 */}
                            <div className="other-content">
                              <span className="content-price">￥{el.integral_price}</span>
                              <span className="content-integral">{el.member_integral_price}积分</span>
                            </div>
                            {/* 数量 */}
                            <div className="other-content">{el.integral_num}</div>
                            {/* 实付 */}
                            <div className="other-content">￥{el.integral_price}</div>
                            {/* 收货人 */}
                            <div className="other-content">{element.consignee}</div>
                            {/* 状态 */}
                            <div className="other-content">{element.order_status_desc}</div>
                            {/* 操作 */}
                            <div className="operation">
                              <span className="see-details">
                                <Lnk target="_blank" to={`/manage/exchangeRecords-details?order_id=${element.order_id}`} className="see-word">查看详情</Lnk>
                              </span>
                            </div>
                          </div>
                        )
                      })
                      }
                    </div>
                  )
                })
              }
              {(!Array.isArray(waitOrderList) || !waitOrderList.length) && (
                <div>
                  <Empty text="暂无订单" className="status" />
                </div>
              )}
            </div>
          </TabPane>

          <TabPane tab='待收货' key="3" className="title">
            <div className="all-order-records">
              {
                Array.isArray(waitReceiveList) && waitReceiveList.map((element) => {
                  return (
                    <div key={element.order_id}>
                      {/* 订单详情 */}
                      <div className="order-number">
                        <span className="number-label">下单时间: {element.add_time}</span>
                        <span className="number-label">订单号: {element.order_sn}</span>
                      </div>
                      {/* 商品名称 */}
                      <div className="order-content">
                        <span className="goods-name">商品名称</span>
                        <span className="goods-other">单价</span>
                        <span className="goods-other">数量</span>
                        <span className="goods-other">实付</span>
                        <span className="goods-other">收货人</span>
                        <span className="goods-other">状态</span>
                        <span className="goods-other">操作</span>
                      </div>
                      {/* 商品内容 */}
                      {Array.isArray(element.integral_list) && element.integral_list.map((el) => {
                        return (
                          <div className="specific-content" key={el.rec_id}>
                            <div className="content-pic">
                              <img src={el.original_img} alt=""/>
                              <span className="pic-name">{el.integral_name}</span>
                            </div>
                            {/* 单价 */}
                            <div className="other-content">
                              <span className="content-price">￥{el.integral_price}</span>
                              <span className="content-integral">{el.member_integral_price}积分</span>
                            </div>
                            {/* 数量 */}
                            <div className="other-content">{el.integral_num}</div>
                            {/* 实付 */}
                            <div className="other-content">￥{el.integral_price}</div>
                            {/* 收货人 */}
                            <div className="other-content">{element.consignee}</div>
                            {/* 状态 */}
                            <div className="other-content">{element.order_status_desc}</div>
                            {/* 操作 */}
                            <div className="operation">
                              <span className="remind" onClick={() => onClickConfirm(element.order_id)}>确认收货</span>
                              <span className="see">
                                <Lnk target="_blank" to={`/manage/exchangeRecords-details?order_id=${element.order_id}`} className="see-word">查看详情</Lnk>
                              </span>
                            </div>
                          </div>
                        )
                      })
                      }
                    </div>
                  )
                })
              }
              {(!Array.isArray(waitReceiveList) || !waitReceiveList.length) && (
                <div>
                  <Empty text="暂无订单" className="status" />
                </div>
              )}
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default ExchangeRecords