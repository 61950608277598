import React, { useState, useEffect } from 'react'
import { Pagination } from 'antd'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import Title from '@/components/title'
import Type from '@/components/type'
import Works from '@/components/works'

const PopularWorks = () => {
  document.title = '人气作品'

  const [worksTypeList, setWorksTypeList] = useState([])
  const [worksList, setWorksList] = useState([])
  const [worksType, setWorksType] = useState('0')
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(1)

  useEffect(() => {
    axios.post('product/getStyle', Qs.stringify({
      channel: 3,
      pid: 3
    })).then(res => {
      res.result.unshift({
        id: '0',
        sort: '0',
        style_name: '全部'
      })
      setWorksTypeList(res.result)
    })
  }, [])

  useEffect(() => {
    axios.post('product/productList', Qs.stringify({
      channel: 3,
      style: worksType,
      limit: 6,
      page: current
    })).then(res => {
      res.result && Array.isArray(res.result.list) && setWorksList(res.result.list)
      res.result && setTotal(res.result.pages)
    })
  }, [worksType, current])

  return (
    <div className='popular-works'>
      <div className='context'>
        <Title title='人气作品' describe='生活方式由你原创，万般风情，没有压力' />
        <Type list={worksTypeList} type={worksType} onChange={(value) => setWorksType(value)} />
        <Works list={worksList} />
        <Pagination className='soft-page' showSizeChanger={false} current={current} total={total * 10} onChange={(page) => {
          setCurrent(page)
          window.scrollTo(0, 0)
        }} />
      </div>
    </div>
  )
}

export default PopularWorks