import React, { useState, useEffect } from 'react'
import './index.scss'
import queryString from 'querystring' /* 截取必须要用到的组件 */
import Qs from 'qs'
import axios from '@/api'
// import { Breadcrumb } from 'antd'

const About = (props) => {

  const [propsParams] = useState(
    queryString.parse(props.location.search.substr(1))
  ) //截取href后面的ID根据绑定事件自动更换下面div的属性
  const [content, setContent] = useState({})
  useEffect(() => {
    axios
      .post(
        'Article/articleDetail',
        Qs.stringify({
          channel: 3,
          article_id: propsParams.id
        })
      )
      .then((res) => {
        console.log(res)
        setContent(res.result)
      })
  }, [propsParams])
  const leftList = [
    {
      id: 0,
      name: '购物中心',
      path: 'none',
      children: [
        {
          id: 1,
          name: '购物流程'
        },
        {
          id: 2,
          name: '支付方式'
        },
        {
          id: 3,
          name: '发票制度'
        }
      ]
    },
    {
      id: 1,
      name: '服务保障',
      path: 'none',
      children: [
        {
          id: 4,
          name: '免费送货'
        },
        {
          id: 5,
          name: '七天退换'
        },
        {
          id: 6,
          name: '一年质保'
        }
      ]
    },
    {
      id: 2,
      name: '配送方式',
      path: 'none',
      children: [
        {
          id: 7,
          name: '物流配送'
        },
        {
          id: 8,
          name: '安装说明'
        },
        {
          id: 9,
          name: '收货指南'
        }
      ]
    },
    {
      id: 3,
      name: '关于我们',
      path: 'none',
      children: [
        {
          id: 10,
          name: '公司简介'
        },
        {
          id: 11,
          name: '加入我们'
        },
        {
          id: 12,
          name: '招商合作'
        },
        {
          id: 56,
          name: '客服中心'
        },
        {
          id: 14,
          name: '联系我们'
        }
      ]
    }
  ]

  let { parent, children } = propsParams

  const handleChange = (newValue, parent, children) => {
    window.location.href = `/about?id=${newValue}&parent=${parent}&children=${children}` /* 在绑定事件中获取传来的newValue */
  }

  return (
    <div className="about_help">
      <div className="about-center">
        <div className="about-left">
          <div className="about-text">
            {leftList &&
              leftList.map((element, index) => {
                return (
                  <ul className="big-list" key={element.id}>
                    <h4>{element.name}</h4>
                    {element.children.map((el, i) => {
                      return (
                        <li
                          className={`child-li ${
                            propsParams.id === String(el.id)
                              ? 'active-items'
                              : ''
                          }`}
                          key={el.id}
                          onClick={() => handleChange(el.id, index, i)}
                        >
                          {el.name}
                        </li>
                      )
                    })}
                  </ul>
                )
              })}
          </div>
        </div>
        <div className="about-right">
          <div className="bcr">
            <span className="bcr-fu">{leftList[Number(parent)] && leftList[Number(parent)].name}</span>
            <i className="iconfont icon-right"></i>
            <span className="bcr-zi">{leftList[Number(parent)] && leftList[Number(parent)].children && leftList[Number(parent)].children[children] && leftList[Number(parent)].children[children].name}</span>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: content.Detail && content.Detail.content
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default About
