import React, { memo } from 'react'
import { Button } from 'antd'
import './index.scss'

import Lnk from '@/components/lnk'

const Designer = ({ list }) => {
  return (
    <div className="re-designer-box">
      <div className='re-designer'>
        {
          list && list.map((element, index) => {
            return (
              <div className='re-designer-item' key={index}>
                <div className='re-designer-item-top'>
                  <Lnk className='re-designer-item-img' target='_blank' to={`/designer-details?designer=${element.designer_id}`}>
                    <img src={element.head_pic} alt='设计师' />
                  </Lnk>
                </div>
                <div className='re-designer-item-bottom'>
                  <div className='commodity-option'>
                    <span className='name'>{element.nickname}</span>
                    <div className='options'>
                      <span className='level' style={{ padding: '2px 10px' }}>{element.level}</span>
                      <span className='options-star'><i className='iconfont icon-like1 star'></i>{element.collect_num}</span>
                      <span className='options-like'><i className='iconfont icon-like star'></i>{element.love_num}</span>
                    </div>
                  </div>
                  <div className='skill-info'>
                    <span className='main-skill'>{element.main_skill || '其他风格'}</span>
                    <div className='look-num'>
                      <span className='skill-info-works'>作品({element.works_num})</span>
                      <span className='skill-info-look'>{element.view_num}人浏览</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        <Button className='more'>查看更多</Button>
      </div>
    </div>
  )
}

export default memo(Designer)