import React, { useEffect, useState } from 'react'
import queryString from 'querystring'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import Empty from '@/components/empty'
import Lnk from '@/components/lnk'

const HomeDesignDetails = (props) => {
  document.title = '御秀-家的设计-详情'
  const [propsParams, setPropsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [designDetails, setDesignDetails] = useState({})
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeImageIndex, setActiveImageIndex] = useState({})

  useEffect(() => {
    axios.post('Product/getProductGoodsInfo', Qs.stringify({
      channel: 3,
      ...propsParams
    })).then(res => {
      let temp = []
      Array(res.result.chapter) && res.result.chapter.forEach(element => {
        temp.push(0)
      })
      setActiveImageIndex(temp)
      setDesignDetails(res.result)
    })
  }, [propsParams])

  return (
    <div className='home-design-details'>
      {/* banner图 */}
      <div className="home-design-banner"></div>
      {/* 设计师信息 */}
      <div className="designer-info1">
        <div className="designer-info-top">
          <div className="designer-info-img">
            <img src={designDetails.head_pic} alt="头像"/>
          </div>
          <div className="designer-info-center">
            <p className="design-style-level">{designDetails.level}</p>
            <p className='design-name'>{designDetails.nickname}</p>
            <p className="design-style">{designDetails.product_style}</p>
          </div>
          <div className="designer-info-name">{designDetails.title}</div>
        </div>
        {/* <div className="info-introduce">{designDetails.describe}</div> */}
        <div className="info-introduce">
          <div className="info-introduce-top">{designDetails.describe}</div>
          <div className="info-introduce-bottom">{designDetails.works_num}个作品>></div>
        </div>
      </div>

      <div className='context'>
        {/* 设计师信息 */}
        {/* <div className='designer-info'>
          <div className='designer-info-left'>
            <img src={designDetails.head_pic} alt='头像' />
            <p className='design-name'>{designDetails.nickname}</p>
          </div>
          <div className='designer-info-right'>
            <div className='design-style'>
              <div className='design-style-level'>
                {designDetails.level}
              </div>
              <span className='hdd-title'>
                {designDetails.title}
              </span>
              <span className='design-style-product-style'>{designDetails.product_style}</span>
              <span className='design-style-works-num'>{designDetails.works_num}个作品</span>
            </div>
            <p>{designDetails.describe}</p>
          </div>
        </div> */}

        <div className='hdd-context'>
          <div className='hdd-context-left'>
            <div className='hdd-context-navigation'>
              <div onClick={() => setActiveIndex(0)} className={activeIndex || 'hdd-active'}>设计作品</div>
              <div className={`relevant ${activeIndex && 'hdd-active'}`} onClick={() => setActiveIndex(1)}>相关产品（{designDetails.goods && designDetails.goods.length}）</div>
            </div>
            {
              !activeIndex ? (
                <div className='hdd-context-1'>
                  {
                    designDetails.chapter && designDetails.chapter.map((element, index) => {
                      return (
                        <div className='hdd-context-1-content' key={index}>
                          <div className='hdd1-title'>
                            {element.title}
                          </div>
                          <div className='hdd1-img-list'>
                            <div className='hdd1-img-list-img'>
                              <img src={element.img && element.img[activeImageIndex[index]]} alt={element.title} />
                              {
                                !index && <a className='look-panorama' target='_blank' rel="noopener noreferrer" href={designDetails.urls}>查看360°高清全景 ➤</a>
                              }
                            </div>
                            <div className='hdd1-select-list'>
                              {
                                element.img && element.img.map((el, i) => {
                                  return (
                                    <img className={activeImageIndex[index] === i ? 'hdd1-select-list-active' : ''} onClick={() => {
                                      activeImageIndex[index] = i
                                      setActiveImageIndex([...activeImageIndex])
                                    }} src={el} alt={element.title} key={i} />
                                  )
                                })
                              }
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              ) : (
                  <div className='hdd-context-2'>
                    {designDetails.goods && designDetails.goods.length ? (
                      designDetails.goods.map((element, index) => {
                        return (
                          <Lnk className='about-commodity' key={index} target='_blank' to={`/commodity-details?goods_id=${element.goods_id}`}>
                            {/* <div className='about-commodity-img'>
                              <img src={element.original_img} alt='产品' />
                            </div>
                            <div className='about-commodity-title'>
                              {element.goods_name}
                            </div> */}
                            <div className="brand-item-img">
                              <img src={element.original_img} alt=""/>
                            </div>
                            <div className="brand-item-title">{element.goods_name}</div>
                          </Lnk>
                        )
                      }
                      )) : <Empty />}
                  </div>
                )
            }

          </div>
          <div className='hdd-context-space'></div>
          <div className='hdd-context-right'>
            <p className="recommended-works-title">作品推荐</p>
            <div className="recommended-works-bottom">
              {
                designDetails.product_list && designDetails.product_list.map((element, index) => {
                  return (
                    <div className='recommended-works' key={index}>
                      <img src={element.image} alt='推荐作品' onClick={() => {
                        window.scrollTo(0, 0)
                        setPropsParams({ id: element.product_id })
                      }} />
                      {/* <div className='recommended-works-info'>
                        <span>{element.title}</span>
                        <span>浏览：{element.view}</span>
                      </div> */}
                    </div>
                  )
                })
              }
            </div>
            {/* {
              designDetails.product_list && designDetails.product_list.map((element, index) => {
                return (
                  <div className='recommended-works' key={index}>
                    <img src={element.image} alt='推荐作品' onClick={() => {
                      window.scrollTo(0, 0)
                      setPropsParams({ id: element.product_id })
                    }} />
                    <div className='recommended-works-info'>
                      <span>{element.title}</span>
                      <span>浏览：{element.view}</span>
                    </div>
                  </div>
                )
              })
            } */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeDesignDetails