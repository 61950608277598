import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

let location = history.location.pathname

const Lnk = ({ to, target, children, className, style }) => {
  return (
    <Link className={className} style={style} target={(location === to) || (to === 'none') ? '' : target} to={to === 'none' ? location : to}>
      {children}
    </Link>
  )
}

export default memo(Lnk)