import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Lnk from '../../components/lnk'

import { Rate, message } from 'antd'
const Finish = () => {
  document.title = '已评价'

  const [finishDetails, setFinishDetails] = useState()
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 'all'
    })
    axios.post('User/already_order', params).then((res) => {
      console.log('List>>>', res.result)
      setFinishDetails([res.result])
    })
  }, [])
  // 删除
  const onClickDelete = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      order_id: id
    })
    axios.post('User/delComment', params).then((res) => {
      message.success(res.msg)
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        token,
        type: 'all'
      })
      axios.post('User/already_order', params).then((res) => {
        setFinishDetails([res.result])
      })
    })
  }
  return (
    <div className="finish">
      <div className="finish-content-info">
        {Array.isArray(finishDetails) &&
          finishDetails.map((element, index) => {
            return(
            Object.values(element).map((ele, index) => {
              return (
                <div key={index}>
                  {/* 订单详情 */}
                  <div className="finish-number">
                    <span className="finish-time">
                      下单时间: {ele.add_time}
                    </span>
                    <span className="finish-time">
                      订单号: {ele.order_sn}
                    </span>
                  </div>
                  {/* 商品名称 */}
                  <div className="finish-content">
                    <span>商品名称</span>
                    <span className="finish-title">实付</span>
                    <span className="finish-title">评价</span>
                    <span className="finish-title">评价内容</span>
                    <span className="operation-title">操作</span>
                  </div>
                  {/* 商品内容 */}
                  <div className="specific-content">
                    <div className="content-pic">
                      <div className="shop-pic">
                        <img src={ele.goods.pic} alt="" />
                      </div>
                      <div className="shop-title">
                        <p>{ele.goods.goods_name}</p>
                        <p>
                          <span>单价：{ele.goods.goods_price}</span>
                          <span className="shop-num">
                            数量：{ele.goods.goods_num}
                          </span>
                        </p>
                      </div>
                    </div>
                    <p className="specific-price">￥{ele.pay_price}</p>
                    <div className=" specific-price evaluate">
                      <span className="evaluate-content">
                        <i className="word">描述:</i>
                        <Rate
                          className="evaluation-icon"
                          disabled
                          value={ele.comment.describe_score}
                        />
                      </span>
                      <span className="evaluate-content">
                        <i className="word">商品:</i>
                        <Rate
                          className="evaluation-icon"
                          disabled
                          value={ele.comment.goods_rank}
                        />
                      </span>
                      <span className="evaluate-content">
                        <i className="word">物流:</i>
                        <Rate
                          className="evaluation-icon"
                          disabled
                          value={ele.comment.logistics_score}
                        />
                      </span>
                      <span className="evaluate-content">
                        <i className="word">服务:</i>
                        <Rate
                          className="evaluation-icon"
                          disabled
                          value={ele.comment.seller_score}
                        />
                      </span>
                    </div>
                    <p className="specific-price pin-lun-content">
                        {ele.comment.content}
                    </p>
                    <div className="operation-content">
                      <h4 className="operation-details">
                        <Lnk
                          target="_blank"
                          to={`/manage/finish-details?order_id=${ele.order_id}`}
                          className="see-details"
                        >
                          详情
                        </Lnk>
                      </h4>
                      <h4
                        className="operation-delete"
                        onClick={() => onClickDelete(ele.order_id)}
                      >
                        删除
                      </h4>
                    </div>
                  </div>
                </div>
              )
          })
          )}
          )}
      </div>
    </div>
  )
}
export default Finish
