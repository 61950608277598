import React, { useState, useEffect, useCallback } from 'react'
import China from "@/utils/map"
import { SVGMap } from "react-svg-map"
import "react-svg-map/lib/index.css"
import { getLocationName } from '@/utils/map-tools'
import Qs from 'qs'
import axios from '@/api'
import { useHistory } from 'react-router-dom'
import './index.scss'

import StoreSearch from '@/components/store-search'
import Empty from '@/components/empty'

const Store = () => {
  const history = useHistory()
  const [pointedLocation, setPointedLocation] = useState(null)
  const [tooltipStyle, setTooltipStyle] = useState({
    display: 'none'
  })
  const [storeList, setStoreList] = useState([]) // 门店列表

  useEffect(() => {
    getStoreList()
  }, [getStoreList])

  const handleLocationMouseOver = event => {
    const pl = getLocationName(event)
    setPointedLocation(pl)
  }

  const handleLocationMouseOut = () => {
    setPointedLocation(null)
    setTooltipStyle({ display: 'none' })
  }

  const handleLocationMouseMove = event => {
    event.persist()
    const ts = {
      display: 'block',
      top: event.clientY + 10,
      left: event.clientX
    }
    setTooltipStyle(ts)
  }

  const onClickLocation = event => {
    let params = event.target.getAttribute('name') || ''
    getStoreList(params)
  }

  // 点击地图获取门店
  const getStoreList = useCallback(
    (params) => {
      axios.post('http://api.rs2012.com/index.php/WXAPI/Index/store_list', Qs.stringify({
        province_name: params
      })).then(res => {
        setStoreList(res.data || [])
      })
    },
    []
  )

  // 搜索门店
  const $onClickSearchStore = params => {
    history.push({
      pathname: '/store-list',
      params
    })
  }

  return (
    <div className="store context">
      <StoreSearch onSearch={$onClickSearchStore} />
      <div className="store-context">
        <div className="map">
          <SVGMap map={China}
            onLocationClick={onClickLocation}
            onLocationMouseOver={handleLocationMouseOver}
            onLocationMouseOut={handleLocationMouseOut}
            onLocationMouseMove={handleLocationMouseMove} />
          <div className="tooltip" style={tooltipStyle}>
            <div className="round">
              {pointedLocation}
            </div>
          </div>
        </div>
        <div className="store-address-list">
          <div className="store-title">
            全国体验店
        </div>
          <div className="store-list">
            {
              storeList.map(element => {
                return (
                  <div className="store-item" key={element.id}>
                    <div className="store-item-name">{ element.store_name }</div>
                    <div className="store-item-details">地址：{ element.address }</div>
                  </div>
                )
              })
            }
            {
              !storeList.length && (
                <div style={{ marginTop: 20 }}>
                  <Empty text="暂无数据" icon="none" />
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Store