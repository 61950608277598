import React, { useEffect, useState } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import Lnk from '../../components/lnk'
import Empty from '@/components/empty'
const Wait = () => {
  document.title = '待评价'

  const [waitEvaluate, setWaitEvaluate] = useState({})
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 'waitcomment'
    })
    axios.post('User/order_list', params).then((res) => {
      console.log('List>>>', res.result.order_list)
      setWaitEvaluate(res.result.order_list)
    })
  }, [])
  return (
    <div className="wait">
      {/* <Empty text="暂无评价" /> */}
      <div className="wait-content">
        {
          Array.isArray(waitEvaluate) && waitEvaluate.map((element, index) => {
            return (
              <div key={index} className="wait-detail">
                {/* 订单详情 */}
                <div className="finish-number">
                  <span className="finish-time">下单时间: {element.add_time}</span>
                  <span className="finish-time">订单号: {element.order_sn}</span>
                </div>
                {/* {console.log('........',element.goods_list)} */}
                {/* 商品名称 */}
                <div className="finish-content">
                  <span>商品名称</span>
                  <span className="finish-title">实付</span>
                  <span className="operation-title">操作</span>
                </div>
                {/* 商品内容 */}
                {
                  Array.isArray(element.goods_list) && element.goods_list.map((el,index) => {
                    return (
                      <div className="specific-content" key={index}>
                        {/* 商品名称内容 */}
                        <div className="content-pic">
                          <div className="shop-pic">
                            <img src={el.pic} alt=""/>
                          </div>
                          <div className="shop-title">
                            <p className="shop-title-test">{el.goods_sn}</p>
                            <p className="shop-title-price">
                              <span className="single-price">单价：￥{el.goods_price}</span>
                              <span>数量：{el.goods_num}</span>
                            </p>
                          </div>
                        </div>
                        {/* 实付内容 */}
                        <div className="specific-price">
                          <p className="price-title">￥{el.really_price}</p>
                        </div>
                        {/* 操作内容 */}
                        <div className="operation-content">
                          <p className="operation-title">
                            <Lnk target="_blank" to={`/manage/goods-evaluation?order_id=${el.order_id}`} className="operation-title-color">
                              评价
                            </Lnk>
                          </p>
                        </div>
                      </div>
                    )
                  })
                }
                
              </div>
            )
          })
        }
        {(!Array.isArray(waitEvaluate) || !waitEvaluate.length) && (
              <div>
                <Empty text="暂无评价" className="status" />
              </div>
            )}
      </div>
      
    </div>
  )
}
export default Wait
