import React, { useState, useEffect } from 'react'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'
import { API } from '../../api/config'
import './index.scss'

import aliPay from '@/assets/images/alipay.jpg'

import Lnk from '@/components/lnk'

const Payment = (props) => {
  document.title = '付款'
  let token = Cookies.get('token')
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [orderInfo, setOrderInfo] = useState({})
  const [time, setTime] = useState(0)
  const [timeStr, setTimeStr] = useState('')

  useEffect(() => {
    let params = Qs.stringify({
      token,
      channel: 1,
      ...propsParams
    })
    axios.post('Cart/payTime', params).then(res => {
      console.log('res', res)
      setTime(res.result.time_str || 0)
      setOrderInfo(res.result.order)
    })
  }, [token, propsParams])

  useEffect(() => {
    let t = setInterval(() => {
      if (time > 0) {
        setTime(time - 1)
        secondToDate()
      } else {
        clearInterval(t)
      }
    }, 1000)
    return () => {
      clearInterval(t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  //秒转化成 时分秒
  const secondToDate = () => {
    let h = Math.floor(time / 3600)
    let m = Math.floor((time / 60 % 60))
    let s = Math.floor((time % 60))
    let ts = h + "小时" + m + "分钟" + s + "秒"
    setTimeStr(ts)
  }

  // 支付
  const $onClickPay = () => {
    console.log(propsParams)
    if(propsParams.order_sn && propsParams.order_sn!=='undefined'){
      window.location.href = API + 'Cart/payOrder?master_order_sn=' + propsParams.order_sn + '&channel=1&pay_type=alipayPc&token=' + token
    }
    if(propsParams.order_id && propsParams.order_id!=='undefined'){
      window.location.href = API + 'Cart/payOrder?channel=1&pay_type=alipayPc&token=' + token + '&order_id=' + propsParams.order_id
    }
  }

  return (
    <div className='payment'>
      <div className='context'>
        <div className='order-info'>
          <i className='iconfont icon-right1 right-icon'></i>
          <div className='order-info-content'>
            <div className='order-item'>
              <span className='order-status'>订单提交成功！去付款咯～</span>
              <div className='order-money'>
                应付总额：<span> {orderInfo.order_amount} <span style={{ fontSize: '14px' }}>元</span> </span>
              </div>
            </div>
            <div className='order-item'>
              成功付款后，{orderInfo.success_day}天发货
            </div>
            <div className='order-item'>
              <p>请在 <span className='order-theme-color'>{timeStr}</span> 内完成支付, 超时后将取消订单</p>
            </div>
            <div className='order-item'>
              收货信息：{orderInfo.consignee} {orderInfo.mobile} {orderInfo.province} {orderInfo.city} {orderInfo.district} {orderInfo.address}
            </div>
            <div className='order-item'>
              <Lnk target='_blank' to='/'>
                <span className='order-theme-color'>返回首页</span>
              </Lnk>
            </div>
          </div>
        </div>
        <div className='pay-type'>
          <div className='pay-type-title'>
            支付方式
          </div>
          <div className='pay-type-list'>
            <div className='pay-type-item'>
              <img src={aliPay} alt="支付宝" />
            </div>
          </div>
          <div className='pay-title'>支付：</div>
          <div className='pay-money'>
            ￥{orderInfo.order_amount}
          </div>
          <div className='pay-submit'>
            <button className='pay-submit-btn' onClick={$onClickPay}>确认支付</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment