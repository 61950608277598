import axios from 'axios'
import { API } from './config'
import Cookies from 'js-cookie'
import { message } from 'antd'

axios.defaults.baseURL = API
// axios.defaults.withCredentials= true
// 这里取决于登录的时候将 token 存储在哪里
// const token = localStorage.getItem('token')

const instance = axios.create({
  timeout: 5000
})

// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// 添加请求拦截器
instance.interceptors.request.use(
  config => {
    // 将 token 添加到请求头
    // token && (config.headers.Authorization = token)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      if (response.data.status === -1) {
        let keys = document.cookie.match(/[^ =;]+(?==)/g)
        if (keys) {
          for (let i = 0; i <= keys.length; i++) {
            Cookies.remove(keys[i])
          }
        }
        message.info('登录过期，正在退出~~~')
        setTimeout(() => {
          window.location.href = '/login'
        }, 1500)
      }
      return Promise.resolve(response.data)
    } else {
      message.info(response.data.msg || '请求失败')
    }
  },
  error => {
    // 相应错误处理
    // 比如： token 过期， 无权限访问， 路径不存在， 服务器问题等
    if (error && error.response) {
      switch (error.response.status) {
        case 401:
          break
        case 403:
          break
        case 404:
          break
        case 500:
          break
        default:
          console.log('其他错误信息')
      }
    } else {
      console.log('error>>>', error)
    }
    return Promise.reject(error)
  }
)

export default instance
