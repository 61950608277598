

import React from 'react'
import { BackTop, Modal } from 'antd'
import { Route, Switch } from 'react-router-dom'
import { useMappedState, useDispatch } from 'redux-react-hook'
import './index.scss'

import Lnk from '@/components/lnk'
import Navigation from '@/components/navigation'
import Footer from '@/components/footer'
import Index from '@/pages/index'
import Login from '@/pages/login'
import Register from '@/pages/register'
import Recommend from '@/pages/recommended'
import SetMeal from '@/pages/set-meal'
import Soft from '@/pages/soft'
import HomeDesignDetails from '@/pages/home-design-details'
import DesignMaster from '@/pages/design-master'
import DesignerDetails from '@/pages/designer-details'
import PopularWorks from '@/pages/popular-works'
import CommodityLibrary from '@/pages/commodity-library'
import NewsDetails from '@/pages/news-details'
import CommodityDetails from '@/pages/commodity-details'
import About from '@/pages/about'
import Design from '@/pages/5d'
import SetMealDetails from '@/pages/set-meal-details'
import ShopCar from '@/pages/shop-car'
import Manage from '@/pages/manage'
import Order from '@/pages/order'
import Payment from '@/pages/payment'
import Agreement from '@/pages/agreement'
import SubmitOrder from '@/pages/submit-order'
import Brand from '@/pages/brand'
import BrandDetails from '@/pages/brand-details'
import IntegralMall from '@/pages/integral-mall'
import IntegralDetails from '@/pages/integral-details'
import OrderIntegral from '@/pages/order-integral'
import SourceDownload from '@/pages/source-download'
import Store from '@/pages/store'
import StoreList from '@/pages/store-list'


const Layout = () => {
  const isLoginModal = useMappedState(state => state.login.isLoginModal)
  const dispatch = useDispatch()

  return (
    <div className='layout'>
      <Modal
        centered
        visible={isLoginModal}
        closable={false}
        footer={null}
        width={270}
        getContainer={false}
      >
        <div className='modal-login'>
          <div className='modal-login-content'>登陆御秀，体验更多功能</div>
          <div className='modal-btn-list'>
            <Lnk target='_blank' to='/login'>
              <div className='modal-btn-item modal-btn-1' onClick={() => dispatch({ type: "HIDDEN" })}>
                去登录
              </div>
            </Lnk>
            <div className='modal-btn-item modal-btn-2' onClick={() => dispatch({ type: "HIDDEN" })}>
              取消
            </div>
          </div>
        </div>
      </Modal>
      <Navigation />
      <div className='layout-context'>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <Route path="/recommend" exact component={Recommend} />
          <Route path="/set-meal" exact component={SetMeal} />
          <Route path="/soft" exact component={Soft} />
          <Route path="/about" exact component={About} />
          <Route path="/home-design-details" exact component={HomeDesignDetails} />
          <Route path="/design-master" exact component={DesignMaster} />
          <Route path="/designer-details" exact component={DesignerDetails} />
          <Route path="/popular-works" exact component={PopularWorks} />
          <Route path="/commodity-library" exact component={CommodityLibrary} />
          <Route path="/news-details" exact component={NewsDetails} />
          <Route path="/commodity-details" exact component={CommodityDetails} />
          <Route path="/set-meal-details" exact component={SetMealDetails} />
          <Route path="/design" exact component={Design} />
          <Route path="/shop-car" exact component={ShopCar} />
          <Route path="/manage" component={Manage} />
          <Route path="/order" component={Order} />
          <Route path="/payment" component={Payment} />
          <Route path="/agreement" component={Agreement} />
          <Route path="/submit-order" exact component={SubmitOrder} />
          <Route path="/brand" exact component={Brand} />
          <Route path="/brand-details" exact component={BrandDetails} />
          <Route path="/integral-mall" exact component={IntegralMall} />
          <Route path="/integral-details" exact component={IntegralDetails} />
          <Route path="/order-integral" exact component={OrderIntegral} />
          <Route path="/source-download" exact component={SourceDownload} />
          <Route path="/store" exact component={Store} />
          <Route path="/store-list" exact component={StoreList} />
        </Switch>
      </div>
      <BackTop />
      <Footer />
    </div >
  )
}

export default Layout