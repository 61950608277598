import React, { useState } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import {
  Form,
  Input,
  Button,
  Select,
  message,
} from 'antd';


const InvoiceInformation = (props) => {
  document.title = '我的增值税发票'
  const { Option } = Select
  const [invoiceType, setInvoiceType] = useState()

  const onChangeInvoiceType = (value) => {
    setInvoiceType(value)
  }

  const onFinish = (e) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 'all',
      ...e
    })
    axios.post('User/addInvoice', params).then((res) => {
      message.success(res.msg)
      setInvoiceType(res.result)
      props.history.push('/manage/vat-invoice?pid=4&cid=15')
    })
  }
  return (
    <div className="invoiceInformation">
      <div className="invoiceInformation-content">
        <div className="invoice-title">填写发票信息</div>
        <div className="invoice-content">
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 7 }}
            onFinish={onFinish}
            className="invoice-content-lists"
          >
            <Form.Item label="发票类型" className="invoice-label" name="type" rules={[
              {
                required: true,
                message: '请选择发票类型',
              },
            ]}>
              <Select onChange={onChangeInvoiceType}>
                <Option value="0">个人</Option>
                <Option value="1">公司</Option>
                <Option value="2">专用发票</Option>
              </Select>
            </Form.Item>
            <Form.Item label="抬头" className="invoice-label" name="company"
                       rules={[
                         {
                           required: true,
                           message: '发票抬头不能为空',
                         },
                       ]}
            >
                <Input placeholder="请输入发票抬头" />
            </Form.Item>
            {
              invoiceType !== '0'&&(
                <Form.Item label="税号" className="invoice-label" name="tax_nums"
                           rules={[
                             {
                               required: true,
                               message: '税号不能为空',
                             },
                           ]}
                >
                  <Input placeholder="请输入税号"/>
                </Form.Item>
              )
            }
            {
              invoiceType !== '0' && invoiceType !== '1' && (
                <>
                  <Form.Item label="注册地址" className="invoice-label" name="address"
                             rules={[
                               {
                                 required: true,
                                 message: '注册地址不能为空',
                               },
                             ]}
                  >
                    <Input placeholder="请输入注册地址" />
                  </Form.Item>
                  <Form.Item label="注册电话" className="invoice-label" name="mobile"
                             rules={[
                               {
                                 required: true,
                                 message: '注册电话不能为空',
                               },
                             ]}
                  >
                    <Input placeholder="请输入注册电话" />
                  </Form.Item>
                  <Form.Item label="开户银行" className="invoice-label" name="bank_name"
                             rules={[
                               {
                                 required: true,
                                 message: '开户银行不能为空',
                               },
                             ]}
                  >
                    <Input placeholder="请输入开户银行" />
                  </Form.Item>
                  <Form.Item label="银行账户" className="invoice-label" name="bank_cardid"
                             rules={[
                               {
                                 required: true,
                                 message: '银行账户不能为空',
                               },
                             ]}
                  >
                    <Input placeholder="请输入银行账户" />
                  </Form.Item>
                </>
              )
            }
            <Button
              className="preservation-btn" 
              type="primary"
              htmlType="submit"
            >保存</Button>
          </Form>
        </div>
      </div>
    </div>
  )
}
export default InvoiceInformation