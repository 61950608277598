import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import axios from '@/api'
import Cookies from 'js-cookie'
import Qs from 'qs'
import './index.scss'

const KuJiaLe = (props) => {
  document.title = '开始设计'
  const [url, setUrl] = useState(`https://www.kujiale.com/v/auth?accesstoken=&dest=`)

  let { dest, planId } = props.match.params

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      token,
      channel: 1
    })
    axios.post('Designer/kujiale_token', params)
      .then(res => {
        if (res.status === 1) {
          if (dest === 2 && planId) {
            setUrl(`https://www.kujiale.com/v/auth?accesstoken=${res.result}&dest=${dest}&planid=${planId}`)
          } else {
            setUrl(`https://www.kujiale.com/v/auth?accesstoken=${res.result}&dest=${dest}`)
          }
        } else {
          message.warning(res.msg)
        }
      })
  }, [dest, planId])

  return (
    <div className='kujiale'>
      <iframe
        src={url}
        title='开始设计'
        className="myiframe"
        scrolling="no"
        frameBorder="0"
      ></iframe>
    </div >
  )
}

export default KuJiaLe