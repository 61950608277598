import React, { useState, useEffect } from 'react'
import axios from '@/api'
import Qs from 'qs'
import Cookies from 'js-cookie'
import { message } from 'antd'
import queryString from 'querystring'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Select } from 'antd'
import 'swiper/swiper.scss'
import './index.scss'

import SetMealBanner from './components/set-meal-banner'

const MealDetails = (props) => {
  document.title = '全屋套餐-详情'

  const { Option } = Select
  let token = Cookies.get('token')
  const [setMealDetails, setSetMealDetails] = useState({})
  const [goodsMoreList, setGoodsMoreList] = useState([]) //丰富区
  const [planDetails, setPlanDetails] = useState([])
  const [imgNum, setImgNum] = useState(0) //图片切换
  const [propsParams] = useState(
    queryString.parse(props.location.search.substr(1))
  )
  const [setMealPrice, setSetMealPrice] = useState({
    price: 0,
    select: 0
  }) // 套餐价格
  const [totalPrice, setTotalPrice] = useState({
    price: 0,
    select: 0
  })

  useEffect(() => {
    let params = Qs.stringify({
      channel: 3,
      ...propsParams
    })
    axios.post('Index/packageInfo', params).then((res) => {
      setSetMealDetails(res.result)
      res.result.cateList.forEach((element, index) => {
        element.select = ''
        element.more = index === 0 ? true : false
        if (element.planList.length > 0) {
          element.select = element.planList[0].plan_id
        }
        element.planList.forEach((el) => {
          el.checked = false
          el.goodsList.forEach((e) => {
            e.checked = false
            e.num = 1
          })
        })
      })
      res.result.goodsMore.forEach((element) => {
        element.checked = false
        element.num = 1
      })
      setPlanDetails(res.result.cateList)
      setGoodsMoreList(res.result.goodsMore)
    })
  }, [propsParams])

  // 计算套餐价格
  useEffect(() => {
    let countPrice = 0
    let countNumber = 0
    planDetails.forEach((element) => {
      element.planList.forEach((el) => {
        el.goodsList.forEach((e) => {
          if (e.checked) {
            countPrice += Number(e.package_price * e.num) || 0
            countNumber++
          }
        })
      })
    })
    setSetMealPrice({
      price: countPrice.toFixed(2),
      select: countNumber
    })
  }, [planDetails])

  const $selectGoods = (element) => {
    element.checked = !element.checked
    let countPrice = 0
    let countNumber = 0
    goodsMoreList.forEach((element) => {
      if (element.checked) {
        countPrice += Number(element.package_price * element.goods_num) || 0
        countNumber++
      }
    })
    setTotalPrice({
      price: countPrice.toFixed(2),
      select: countNumber
    })
  }
  useEffect(() => {
    let countPrice = 0
    let countNumber = 0
    goodsMoreList.forEach((element) => {
      if (element.checked) {
        countPrice += Number(element.package_price * element.goods_num) || 0
        countNumber++
      }
    })
    setTotalPrice({
      price: countPrice.toFixed(2),
      select: countNumber
    })
  }, [goodsMoreList])

  // 套餐全选
  const $selectSetMealAll = event => {
    event.checked = !event.checked
    event.goodsList.forEach((el) => {
      el.checked = event.checked
    })
    setPlanDetails([...planDetails])
  }

  // 套餐选择单选
  const $selectSetMeal = (event, el, element) => {
    event.stopPropagation()
    el.checked = !el.checked
    let selectLength = element.goodsList.filter(e => e.checked)
    let selectAll = selectLength.length === element.goodsList.length ? true : false
    element.checked = selectAll
    setPlanDetails([...planDetails])
  }

  const $onChangeGoodsNumber = (element, event) => {
    event.persist()
    element.num = event.target.value > 1 ? event.target.value : 1
    setGoodsMoreList([...goodsMoreList])
  }

  // 改变套餐商品数量加减
  const $onClickChangeSetMealNumber = (event, element) => {
    event.persist()
    let num = event.target.value
    element.num = num > 1 ? num : 1
    setPlanDetails([...planDetails])
  }

  // 改变丰富区数量加减
  const $onClickSelectSetMealNumber = (element, tag) => {
    let num = element.goods_num
    if (tag === 'add') {
      num++
    } else {
      num--
    }
    element.goods_num = num > 1 ? num : 1
    setGoodsMoreList([...goodsMoreList])
  }

  // 立即购买
  const $onClickBuy = () => {
    let temp = []
    planDetails.forEach((element) => {
      element.planList.forEach((el) => {
        el.goodsList.forEach((e) => {
          if (e.checked) {
            temp.push(`${e.more_goods_id}-${e.num}`)
          }
        })
      })
    })

    let temp1 = []
    goodsMoreList.forEach((element) => {
      if (element.checked) {
        temp1.push(`${element.more_goods_id}-${element.goods_num}`)
      }
    })

    if (temp.length === 0 && temp1.length === 0) {
      message.info('请先选择购买的商品')
      return
    }

    let temp2 = temp.concat(temp1)
    let params = Qs.stringify({
      token,
      channel: 1,
      type: 1,
      ...propsParams,
      more_goods_ids: temp2.join(','),
    })
    axios.post('/Cart/buy_cart', params).then((res) => {
      props.history.push('/order')
    })
  }

  // *********************
  // 选择切换套餐
  const onChangeSetMeal = (event) => {
    let selectItem = event.planList.filter(element => element.plan_id === event.select)
    return selectItem[0] || []
  }
  // 切换套餐商品
  const onChangeSetMealCommodity = (event, element) => {
    let selectItem = element.planList.filter(el => el.plan_id === element.select)
    element.activeCommodity = selectItem[0] && selectItem[0].goodsList && selectItem[0].goodsList[event.realIndex]
    setPlanDetails([...planDetails])
  }
  // 切换套餐类型
  const onChangeSetMealType = (el, element) => {
    element.select = el.plan_id
    let selectItem = element.planList.filter(el => el.plan_id === element.select)
    element.activeCommodity = selectItem[0] && selectItem[0].goodsList && selectItem[0].goodsList[0]
    setPlanDetails([...planDetails])
  }
  // *********************

  return (
    <div className="set-meal-details">
      <div className="context">
        <div className="set-meal-details-context">
          <div className="smd-left">
            <div className="left-img">
              <img
                src={setMealDetails.images && setMealDetails.images[imgNum]}
                alt=""
              />
            </div>
          </div>
          <div className="wire"></div>
          <div className="smd-right">
            {setMealDetails.images && setMealDetails.images.length > 0 && (
              <SetMealBanner list={setMealDetails.images} activeIndex={imgNum} onChange={(imgNum) => { setImgNum(imgNum) }} />
            )}
          </div>
        </div>

        {/* 头像区域 */}
        <div className="banner">
          <div className="banner-left">
            <img
              src={setMealDetails.info && setMealDetails.info.head_pic}
              alt="头像"
            />
            <p>{setMealDetails.info && setMealDetails.info.user_name}</p>
          </div>
          <div className="banner-right">
            <div className="br-title">
              {setMealDetails.info && setMealDetails.info.package_name}
            </div>
            <div className="br-content">
              {setMealDetails.info && setMealDetails.info.describe}
            </div>
          </div>
        </div>
      </div>

      {/* 家具区 */}
      <div className="furniture">
        <div className="context">
          <div className="furniture-title">
            {Array.isArray(planDetails) &&
              planDetails.map((element, index) => {
                return (
                  <div key={index}>
                    {/* 标题 */}
                    <div className="furniture-nei">
                      <div>
                        <span className="title">{element.cate_name}</span>
                        <span className="english-title">LivingRoom</span>
                      </div>
                      <div className="show-more" onClick={() => {
                        element.more = !element.more
                        setPlanDetails([...planDetails])
                      }}>
                        {element.more ? '收起页面' : '展开全部'}
                        <i className={`iconfont ${element.more ? 'icon-right-double' : 'icon-bottom-double'}`}></i>
                      </div>
                    </div>
                    {
                      element.more && (
                        <div>
                          <div className="set-meal-type">
                            <div className="room-type">
                             {/*  <Select defaultValue={element.select} style={{ width: 120 }} onChange={(event) => onChangeSetMealType(event, element)}>
                                {
                                  Array.isArray(element.planList) &&
                                  element.planList.map(el => {
                                    return <Option key={el.plan_id} value={el.plan_id}>{el.plan_name}</Option>
                                  })
                                }
                              </Select> */}
                              {
                                Array.isArray(element.planList) &&
                                element.planList.map(el => {
                                  return (
                                    <div className={`${element.select === el.plan_id ? 'room-type-item-active' : ''} room-type-item`} key={el.plan_id} onClick={() => onChangeSetMealType(el, element)}>
                                      {el.plan_name}
                                    </div>
                                  )
                                })
                              }
                            </div>

                            <div className="box-select-style">
                              <div className={`${onChangeSetMeal(element).checked ? 'select-all-active' : ''} select-all`} onClick={() => $selectSetMealAll(onChangeSetMeal(element))}></div>全选
                              <div className="checked-style"></div>已选购
                              <div className="unchecked-style"></div>未选购
                            </div>
                          </div>
                          {/* 内容 */}
                          {/* ********* */}
                          <Swiper
                            spaceBetween={10}
                            slidesPerView={4}
                            loop={false}
                            centeredSlides={true}
                            onSwiper={event => onChangeSetMealCommodity(event, element)}
                            onSlideChange={event => onChangeSetMealCommodity(event, element)}
                          >
                            {
                              onChangeSetMeal(element).goodsList.map(el => {
                                return (
                                  <SwiperSlide key={el.more_goods_id}>
                                    <div className="swiper-commodity-item">
                                      <img src={el.pic} alt="商品" />
                                      <div className={`${el.checked ? 'select-box-active' : ''} select-box`} onClick={(event) => $selectSetMeal(event, el, onChangeSetMeal(element))}></div>
                                    </div>
                                  </SwiperSlide>
                                )
                              })
                            }
                          </Swiper>
                          {/* 指针 */}
                          <div className="pointer">
                            <div className="triangle"></div>
                          </div>
                          {/* 商品详情 */}
                          <div className="set-meal-commodity-details">
                            <div className="sm-commodity-top">
                              <div className="sm-commodity-title">
                                {element.activeCommodity && element.activeCommodity.goods_name}
                              </div>
                              <div className="sm-commodity-options">
                                <div className="sm-commodity-number">
                                  选购数量
                          <input type="number" min={1} value={(element.activeCommodity && element.activeCommodity.num) || 1} onChange={(event) => $onClickChangeSetMealNumber(event, element.activeCommodity)} />
                                </div>
                                <div className={(element.activeCommodity && element.activeCommodity.checked) ? 'checked-style' : 'unchecked-style'} style={{ marginLeft: 0 }}></div>{(element.activeCommodity && element.activeCommodity.checked) ? '已选购' : '未选购'}
                              </div>
                            </div>
                            {element.activeCommodity && Array.isArray(element.activeCommodity.key_names) && element.activeCommodity.key_names.map(el => {
                              return (
                                <div key={el.key_name}>
                                  <div className="sm-commodity-middle">{el.key_name}</div>
                                </div>
                              )
                            })}
                            <div className="sm-commodity-bottom">
                              <span>￥{element.activeCommodity && element.activeCommodity.package_price}</span>/{element.activeCommodity && element.activeCommodity.unit}
                            </div>
                          </div>
                          {/* ********* */}
                        </div>
                      )
                    }
                  </div>
                )
              })}
          </div>
          {/* 丰富区 */}
          <div className="rich-area">
            {goodsMoreList.length !== 0
              ?
              (
                <div className="furniture-nei">
                  <p className="title">丰富区</p>
                </div>
              )
              :
              (<></>)
            }

            <div className="rich-area-content">
              {Array.isArray(goodsMoreList) &&
                goodsMoreList.map((element) => {
                  return (
                    <div key={element.goods_id} className="rich-area-item">
                      <div className="rich-area-img">
                        <img src={element.pic} alt="" />
                      </div>
                      <div className="rich-area-center">
                        <div className="center-title">{element.goods_name}</div>
                        <div className="center-bottom">{element.key_name}</div>
                        <div className="commodity-specifications">
                          <p className="price">
                            ￥{element.package_price}/{element.unit}
                          </p>
                          <div className="goods-number-context">
                            <div
                              className="options-btn"
                              onClick={() =>
                                $onClickSelectSetMealNumber(element, 'reduce')
                              }
                            >
                              -
                            </div>
                            <input
                              className="goods-number"
                              type="number"
                              value={element.goods_num}
                              onChange={(event) => {
                                $onChangeGoodsNumber(element, event)
                              }}
                            />
                            <div
                              className="options-btn"
                              onClick={() =>
                                $onClickSelectSetMealNumber(element, 'add')
                              }
                            >
                              +
                            </div>
                          </div>
                          <input
                            type="checkbox"
                            checked={element.checked}
                            className="check"
                            onChange={() => $selectGoods(element)}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      {/* 底部付款 */}
      <div className="foot-pay">
        <div className="context">
          <ul className="zhi-fu">
            <li>
              已选<span>{setMealPrice.select + totalPrice.select}</span>件
            </li>
            <li className="discounts">
              实际支付：￥
              <span>
                {Number(setMealPrice.price) + Number(totalPrice.price)}
              </span>
            </li>
            <li className="buy" onClick={$onClickBuy}>
              立即购买
            </li>{' '}
            {/* Cart/buy_cart */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MealDetails
