import React, { useState, useEffect } from 'react'
import './index.scss'
import queryString from 'querystring'
import { Route, Switch } from 'react-router-dom'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Lnk from '../../components/lnk'

import tu from '@/assets/images/menu-5.png'

import myOrder from '../my-order'
import refund from '../refund'
import Details from '../details'
import WaitEvaluation from '../wait-evaluation'
import FinishEvaluation from '../finish-evaluation'
import Coupon from '../my-coupon'
import Invite from '../invite'
import myShare from '../my-share'
import Transaction from '../transaction'
import cashOut from '../cash-out'
import Information from '../my-information'
import Collection from '../my-collection'
import AccountNumber from '../account-number'
import ChangePassword from '../change-password'
import Invoice from '../vat-invoice'
import bindCard from '../bind-card'
import Withdrawal from '../withdrawal'
import invoiceInformation from '../invoice-information'
import ShippingAddress from '../shipping-address'
import EditInvoice from '../edit-invoice'
import WorkOrder from '../work-order'
import MyWorks from '../my-works'
import MyPlan from '../my-plan'
import PublishingWorks from '../publishing-works'
import DesignerInformation from '../designer-information'
import GoodsEvaluation from '../goods-evaluation'
import FinishDetail from '../finish-details'
import BackOrdersDetails from '../back-orders-details'
import RefundInfo from '../refund-info'
import ReturnRefund from '../return-refund'
import MyIntegral from '../my-integral'
import ExchangeRecords from '../exchange-records'
import ExchangeRecordsDetails from '../exchangeRecords-details'


const Manage = (props) => {
  const [propsParams] = useState(
    queryString.parse(props.location.search.substr(1))
  )
  const [manageDetails, setManageDetails] = useState({})

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('Goods/newGoodsCateList', params).then((res) => {
      console.log(res)
    })
  }, [])
  useEffect(() => {
    let token = Cookies.get('token') 
    let params =  Qs.stringify({
      channel: 1,
      token
    })
    axios.post('User/pcUserInfo', params).then((res) => {
      // console.log(res);
      setManageDetails(res.result)
    })
  }, [])
  let role = Cookies.get('role')
  const manageList = [
    {
      id: 0,
      name: '交易管理',
      path: 'none',
      children: [
        {
          id: 1,
          name: '我的订单',
          path: '/manage/my-order?pid=0&cid=1'
        },
        {
          id: 2,
          name: '退款、退货',
          path: '/manage/refund?pid=0&cid=2'
        }
      ]
    },
    {
      id: 1,
      name: '评价管理',
      path: 'none',
      children: [
        {
          id: 3,
          name: '待评价',
          path: '/manage/wait-evaluation?pid=1&cid=3'
        },
        {
          id: 4,
          name: '已评价',
          path: '/manage/finish-evaluation?pid=1&cid=4'
        }
      ]
    },
    {
      id: 2,
      name: '账户管理',
      path: 'none',
      children: [
        {
          id: 5,
          name: '我的优惠券',
          path: '/manage/my-coupon?pid=2&cid=5'
        },
        {
          id: 6,
          name: '邀请好友',
          path: '/manage/invite?pid=2&cid=6'
        }
      ]
    },
    {
      id: 3,
      name: '我的分销',
      path: 'none',
      children: [
        {
          id: 7,
          name: '我的分享',
          path: '/manage/my-share?pid=3&cid=7'
        },
        // {
        //   id: 8,
        //   name: '交易记录',
        //   path: '/manage/transaction?pid=3&cid=8'
        // },
        {
          id: 9,
          name: '我的提现',
          path: '/manage/cash-out?pid=3&cid=9'
        }
      ]
    },
    {
      id: 4,
      name: '个人信息',
      path: 'none',
      children: [
        {
          id: 10,
          role: 2,
          name: '我的资料',
          path: '/manage/my-information?pid=4&cid=10'
        },
        {
          id: 111,
          role: 3,
          name: '设计师资料',
          path: '/manage/designer-information?pid=4&cid=111'
        },
        {
          id: 11,
          role: 3,
          name: '我的方案',
          path: '/manage/my-plan?pid=4&cid=11'
        },
        {
          id: 12,
          name: '我的收藏',
          path: '/manage/my-collection?pid=4&cid=12'
        },
        {
          id: 13,
          name: '账号安全',
          path: '/manage/account-number?pid=4&cid=13'
        },
        {
          id: 14,
          name: '绑定银行卡',
          path: '/manage/bind-card?pid=4&cid=14'
        },
        {
          id: 15,
          name: '我的增值税发票',
          path: '/manage/vat-invoice?pid=4&cid=15'
        },
        {
          id: 16,
          name: '收货地址',
          path: '/manage/shipping-address?pid=4&cid=16'
        },
        // {
        //   id: 17,
        //   name: '修改密码',
        //   path: '/manage/change-password?pid=4&cid=17'
        // },
        {
          id: 18,
          name: '我的积分',
          path: '/manage/my-integral?pid=4&cid=18'
        }
      ]
    },
    {
      id: 5,
      name: '作品管理',
      path: 'none',
      role: 3,
      children: [
        {
          id: 18,
          name: '我的作品',
          path: '/manage/my-works?pid=5&cid=18'
        },
        // {
        //   id: 19,
        //   name: '作品订单',
        //   path: '/manage/work-order?pid=&cid=19'
        // }
      ]
    }
  ]

  const handleChange = (event) => {
    // props.history.push(event.path)
    window.location.href = (event.path)
  }
  return (
    <div className="manage">
      <div className="context">
        <div className="manage-center">
          {/* 左侧 */}
          <div className="manage-left">
            <>
            {Number(role) === 0 && (
                <div className="head-portrait">
                  <div className="head-img">
                    <img src={manageDetails.head_pic} alt="" />
                  </div>
                  <p className="nickname">{manageDetails.username}</p>
                  <span className="switch">
                    <Lnk target="_blank" to="../login" className="switch-change">
                      切换账号
                    </Lnk>
                  </span>
                </div>
              )}
            </>

            <div className="manage-text">
              {manageList &&
                manageList.map((element) => {
                  return (
                    <div key={element.id}>
                      {(Number(role) === 3 || element.role !== 3) && (
                        <ul>
                          <h4>{element.name}</h4>
                          {element.children.map((el) => {
                            return (
                              <div key={el.id}>
                              {(el.role === undefined || el.role === Number(role)) && (
                                <li
                                  className={`${
                                    String(element.id) === propsParams.pid &&
                                    String(el.id) === propsParams.cid
                                      ? 'text-child'
                                      : ''
                                  }`}
                                  onClick={() => handleChange(el)}
                                >
                                  {el.name}
                                </li>
                              )}
                              </div>
                            )
                          })}
                        </ul>
                      )}
                    </div>
                  )
                })}
            </div>
          </div>
          {/* 右侧 */}
          <div className="manage-right">
            {/* 头部 */}
            {Number(role) === 3 && (
              <div className="r-header">
                <div className="header-content">
                  <div className="header-left">
                    <div className="header-img">
                      <img src={manageDetails.head_pic} alt="" />
                    </div>
                    <div className="header-word">
                      <p className="head-name">{manageDetails.username}</p>
                      <p className="head-finish">已认证</p>
                    </div>
                  </div>
                  <div className="header-right">
                    <div className="right-img">
                      <img src={tu} alt="" />
                    </div>
                    <div className="header-word">{manageDetails.pay_points}</div>
                  </div>
                </div>
              </div>
            )}

            {/* 右侧底部 --- 小彭直呼内行 */}
            <div className="foot-content">
              <Switch>
                <Route path="/manage/my-order" exact component={myOrder} />
                <Route path="/manage/refund" exact component={refund} />
                <Route path="/manage/details" exact component={Details} />
                <Route
                  path="/manage/wait-evaluation"
                  exact
                  component={WaitEvaluation}
                />
                <Route
                  path="/manage/finish-evaluation"
                  exact
                  component={FinishEvaluation}
                />
                <Route path="/manage/my-coupon" exact component={Coupon} />
                <Route path="/manage/invite" exact component={Invite} />
                <Route path="/manage/my-share" exact component={myShare} />
                <Route
                  path="/manage/transaction"
                  exact
                  component={Transaction}
                />
                <Route path="/manage/cash-out" exact component={cashOut} />
                <Route
                  path="/manage/my-information"
                  exact
                  component={Information}
                />
                <Route
                  path="/manage/my-collection"
                  exact
                  component={Collection}
                />
                <Route
                  path="/manage/account-number"
                  exact
                  component={AccountNumber}
                />
                <Route
                  path="/manage/change-password"
                  exact
                  component={ChangePassword}
                />
                <Route
                  path="/manage/vat-invoice"
                  exact
                  component={Invoice}
                />
                <Route
                  path="/manage/bind-card"
                  exact
                  component={bindCard}
                />
                <Route
                  path="/manage/withdrawal"
                  exact
                  component={Withdrawal}
                />
                <Route
                  path="/manage/invoice-information"
                  exact
                  component={invoiceInformation}
                />
                <Route
                  path="/manage/shipping-address"
                  exact
                  component={ShippingAddress}
                />
                <Route
                  path="/manage/edit-invoice"
                  exact
                  component={EditInvoice}
                />
                <Route
                  path="/manage/work-order"
                  exact
                  component={WorkOrder}
                />
                <Route
                  path="/manage/my-works"
                  exact
                  component={MyWorks}
                />
                <Route
                  path="/manage/my-plan"
                  exact
                  component={MyPlan}
                />
                <Route
                  path="/manage/publishing-works"
                  exact
                  component={PublishingWorks}
                />
                <Route
                  path="/manage/designer-information"
                  exact
                  component={DesignerInformation}
                />
                <Route
                  path="/manage/goods-evaluation"
                  exact
                  component={GoodsEvaluation}
                />
                <Route
                  path="/manage/finish-details"
                  exact
                  component={FinishDetail}
                />
                <Route
                  path="/manage/back-orders-details"
                  exact
                  component={BackOrdersDetails}
                />
                <Route
                  path="/manage/refund-info"
                  exact
                  component={RefundInfo}
                />
                <Route
                  path="/manage/return-refund"
                  exact
                  component={ReturnRefund}
                />
                <Route
                  path="/manage/my-integral"
                  exact
                  component={MyIntegral}
                />
                <Route
                  path="/manage/exchange-records"
                  exact
                  component={ExchangeRecords}
                />
                <Route
                  path="/manage/exchangeRecords-details"
                  exact
                  component={ExchangeRecordsDetails}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Manage
