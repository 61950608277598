import React, { useState, useEffect } from 'react'
import { InputNumber, message, Modal, Rate } from 'antd'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'
import { useDispatch } from 'redux-react-hook'
import './index.scss'

import Radio from '@/components/radio'
import CommoditySelect from './components/commodity-select'
import RoomList from '@/components/room-list'

const CommodityDetails = (props) => {
  document.title = '商品详情'
  const dispatch = useDispatch()
  let token = Cookies.get('token')
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [commodityDetails, setCommodityDetails] = useState({})
  const [commodityOptions, setCommodityOptions] = useState([])
  const [commodityOptionsId, setCommodityOptionsId] = useState([])
  const [activeTab, setActiveTab] = useState(0) // 默认选中得tab
  const [commodityPrice, setCommodityPrice] = useState(0) // 产品价格
  const [commodityNumber, setCommodityNumber] = useState(1)
  const [addCartModal, setAddCartModal] = useState(false) // 添加到购物车弹框
  const [addCartModalText, setAddCartModalText] = useState('') // 添加到购物车提示
  const [selectColorIndex, setSelectColorIndex] = useState('none') // 颜色标题
  const [selectImageIndex, setSelectImageIndex] = useState(0) // 图片索引（非墙布时）
  const [evaluateDetails, setEvaluateDetails] = useState({}) //商品评价内容

  useEffect(() => {
    $getCommodityInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsParams])

  useEffect(() => {
    axios.post('Goods/specPrice', Qs.stringify({
      channel: 3,
      ...propsParams,
      spec_item: commodityOptionsId.join('_'),
      activity_type: 0,
    })).then(res => {
      setCommodityPrice(res.result)
    })
  }, [propsParams, commodityOptionsId])

  let { goods = {} } = commodityDetails

  // 获取商品信息
  const $getCommodityInfo = () => {
    axios.post('Goods/goodsInfo', Qs.stringify({
      token,
      channel: 1,
      ...propsParams
    })).then(res => {
      let temp1 = []
      let temp2 = []
      res.result && res.result.filter_spec && res.result.filter_spec.forEach((element) => {
        temp1.push(0)
        if (element.spec_list && element.spec_list.length) {
          temp2.push(element.spec_list[0].item_id)
        } else {
          temp2.push('')
        }
        if (res.result.goods.cat_id3 === '29') {
          // 颜色选择
          if (element.parent_name === '颜色') {
            if (element.spec_list && element.spec_list.length) {
              setSelectColorIndex(element.spec_list[0].item || 'none')
            }
          }
        } else {
          setSelectImageIndex(0)
        }
      })
      setCommodityOptions(temp1)
      setCommodityOptionsId(temp2)
      setCommodityDetails(res.result)
    })
  }
  useEffect(() => {
    axios.post('Goods/getGoodsComment', Qs.stringify({
      channel: 3,
      ...propsParams,
      // spec_item: commodityOptionsId.join('_'),
      // activity_type: 0,
    })).then(res => {
      setEvaluateDetails(res.result.list)
    })
  }, [propsParams,])

  // 切换商品规格
  const $onChangeCommoditySpecifications = (value, index, tag, element) => {
    if (element.parent_name === '颜色') {
      let value = element.spec_list[index].item
      setSelectColorIndex(value || 'none')
    }
    commodityOptions[tag] = index
    commodityOptionsId[tag] = value
    setCommodityOptionsId([...commodityOptionsId])
    setCommodityOptions([...commodityOptions])
  }

  // 购买
  const $onClickBuy = () => {
    if (!token) {
      dispatch({ type: 'SHOW' })
      return
    }
    let params = Qs.stringify({
      token,
      type: 0,
      channel: 1,
      package_id: 0,
      ...propsParams,
      goods_num: commodityNumber,
      goods_spec: commodityOptionsId.join('_')
    })
    axios.post('Cart/buy_cart', params).then(res => {
      console.log(res)
      if (res.status === 1) {
        props.history.push('/order')
      } else {
        message.info(res.msg)
      }
    })
  }

  // 添加到购物车
  const $onClickAddCart = () => {
    if (!token) {
      dispatch({ type: 'SHOW' })
      return
    }
    let params = {
      token,
      channel: 1,
      package_id: 0,
      ...propsParams,
      goods_num: commodityNumber,
      goods_spec: commodityOptionsId.join('_')
    }
    axios.post('Cart/addCart', Qs.stringify(params)).then(res => {
      setAddCartModalText(res.msg)
      setAddCartModal(true)
    }).catch(e => {
      message.error('操作失败')
    })
  }

  // 收藏
  const $onClickCollection = () => {
    if (!token) {
      dispatch({ type: 'SHOW' })
      return
    }
    let params = {
      token,
      channel: 1,
      goods_id: goods.goods_id
    }
    axios.post('Goods/collectGoods', Qs.stringify(params)).then(res => {
      message.info(res.msg)
      $getCommodityInfo()
    }).catch(e => {
      message.error('操作失败')
    })
  }

  // 切换颜色
  const $onChangeCommodityImage = (event) => {
    let url = ''
    if (event) {
      let temp = []
      temp = event.filter(element => element.color_title === selectColorIndex)
      if (temp.length) {
        url = (temp[0] && temp[0].image_url) || ''
      } else if (event.length > selectImageIndex) {
        url = event[selectImageIndex].image_url || ''
      }
    }
    return url
  }

  return (
    <div className='commodity-details'>
      <div className='context'>
        <div className='commodity-details-context'>
          <div className='cdc-left cdc-children'>
            <div className='show-img'>
              <img src={$onChangeCommodityImage(commodityDetails.goods_images_list)} alt='商品详情' />
            </div>
            <CommoditySelect list={commodityDetails.goods_images_list} onChange={(element, index) => {
              setSelectColorIndex(element || 'none')
              setSelectImageIndex(index)
            }} />
          </div>
          <div className='cdc-right cdc-children'>
            <div className='commodity-title'>
              {goods.goods_name}
            </div>
            <div className='commodity-options-item'>
              <span className='coi-label'>销量：</span>
              <div className='coi-value'>{goods.sales_sum}</div>
            </div>
            {
              commodityDetails.filter_spec && commodityDetails.filter_spec.map((element, index) => {
                return (
                  <div className='commodity-options-item' key={element.parent_id}>
                    <span className='coi-label'>{element.parent_name}：</span>
                    <div className='coi-value'>
                      <Radio list={element.spec_list} value={commodityOptions[index]} tag={index} onChange={(value, index, tag) => $onChangeCommoditySpecifications(value, index, tag, element)} />
                    </div>
                  </div>
                )
              })
            }
            <div className='commodity-options-item'>
              <span className='coi-label'>数量：</span>
              <div className='coi-value'>
                <InputNumber min={1} value={commodityNumber} onChange={(event) => setCommodityNumber(event)} />
              </div>
            </div>
            <div className='commodity-options-item'>
              <span className='coi-label'>价格：</span>
              <div className='coi-value'>
                ￥<span className='span'>{commodityPrice || goods.shop_price}</span>/{goods.unit || ''}
              </div>
            </div>
            <div className='commodity-options-item'>
              <div className='buy btn coi-children' onClick={$onClickBuy}>立即购买</div>
              <div className='add-car btn coi-children' onClick={$onClickAddCart}>加入购物车</div>
              <div className='star coi-children' onClick={$onClickCollection}> <i className={`iconfont icon-arrow ${commodityDetails.goods_collect === '0' ? '' : 'collection'}`}></i>{commodityDetails.goods_collect === '0' ? '收藏' : '已收藏'}</div>
            </div>
            <div className='commodity-options-item'>
              <span className='coi-label'>服务：</span>
              <div className='coi-value'>
                {`付款后7-15天内发货，物流周期7-12天。长途运输+同城配送+步梯 <=3 楼免费搬楼(电梯入户免费搬楼)+安装(拆包/拼渡等)`}
              </div>
            </div>
          </div>
        </div>
        <div className='commodity-title'>同类推荐</div>
        <RoomList list={commodityDetails.likeGoods} />
        <div className='tabs'>
          <div className='tabs-title-list'>
            <div className={`tabs-title-item ${activeTab === 0 && 'tabs-active'}`} onClick={() => setActiveTab(0)}>商品详情</div>
            <div className={`tabs-title-item ${activeTab === 1 && 'tabs-active'}`} onClick={() => setActiveTab(1)}>商品评价</div>
          </div>
          <div className='tabs-content'>
            {
              activeTab === 0 ? (<div dangerouslySetInnerHTML={{ __html: goods.goods_content }} ></div>) : (
                <div className='commodity-evaluate'>
                  {/* <Empty text='暂无评价' /> */}
                  <div>
                    {
                      Array.isArray(evaluateDetails) && evaluateDetails.map((element) => {
                        return (
                          <div key={element.comment_id} className="evaluate-box">
                            <div className="box-left">
                              <div className="left-img">
                                <img src={element.head_pic} alt=""/>
                              </div>
                              <p className="left-name">{element.user_name}</p>
                            </div>
                            <div className="box-right">
                            <div className="right-title">{element.content}</div>
                              <div className="right-other">
                                <span>
                                  <i className="word">评价：</i>
                                  <Rate className="evaluation-icon" disabled value={element.goods_rank} />
                                </span>
                                <span className="right-time">{element.add_time}</span>
                                <span className="right-num">数量：{element.goods_num}</span>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
      {/* 加入购物车 */}
      <Modal
        centered
        visible={addCartModal}
        closable={false}
        footer={null}
        width={270}
        getContainer={false}
      >
        <div className='add-cart-modal'>
          <div className='title'>提示</div>
          <div className='content'>{addCartModalText}</div>
          <div className='add-cart-submit' onClick={() => setAddCartModal(false)}>确定</div>
        </div>
      </Modal>
      {/* --- end --- */}
    </div>
  )
}

export default CommodityDetails