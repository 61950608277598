import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'
import Lnk from '../../components/lnk'

const BackOrdersDetails = (props) => {
  document.title = '查看详情'

  const [contentDetails, setContentDetails] = useState([])
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...propsParams,
    })
    axios.post('User/order_details', params).then((res) => {
      console.log('[][][]', res.result.order_info)
      setContentDetails(res.result.order_info)
    })
  }, [propsParams]);
  
  return (
    <div className="details">
      <div className="details-information">
        {/* 订单信息 */}
        <div className="details-number">
          <span>下单时间: {contentDetails.add_time}</span>
          <span>订单号: {contentDetails.order_sn}</span>
        </div>
        {/* 收货信息 */}
        <div className="goods-information">
          <div className="goods-left">
            <p>收货人 :  {contentDetails.consignee}</p>
            <p>联系方式 :  {contentDetails.mobile}</p>
            <p>收货地址 :  {contentDetails.province}{contentDetails.city}{contentDetails.district}{contentDetails.address}</p>
            <p>配送方式 :  普通配送，快递包邮</p>
          </div>
          <div className="goods-right">
            <p>商品合计 :  ￥{contentDetails.goods_price}</p>
            <p>运费 :  ￥{contentDetails.integral_money}</p>
            <p>支付方式 :  {contentDetails.pay_name}</p>
            <p>状态 :  {contentDetails.order_status_desc}</p>
          </div>
        </div>
        <div className="shop-name">
          <div className="shop-content">
            <span>商品名称</span>
            <span>单价</span>
            <span>数量</span>
            <span>实付</span>
            <span>收货人</span>
            <span>状态</span>
          </div>
          {Array.isArray(contentDetails.goods_list) && contentDetails.goods_list.map((el,index) => {
            return (
              <div className="specific-shop" key={index}>
                <div className="shop-pic">
                  <img src={el.pic} alt=""/>
                  {/* <span>{el.goods_name}</span> */}
                  <div className="shop-size">
                    <h4>{el.goods_name}</h4>
                    <h4>{el.spec_key_name}</h4>
                  </div>
                </div>
                <p>￥{el.goods_price}</p>
                <p>{el.goods_num}</p>
                <p>￥{el.really_price}</p>
                <p>{contentDetails.consignee}</p>
                {contentDetails.return_btn === 1 && el.return_id === 0
                  ?
                  (
                    <>
                    {el.return_id === 0?(
                    <p>
                      <span>
                        <Lnk target="_blank" to={`/manage/refund-info?rec_id=${el.rec_id}`} className="refund">退款</Lnk>
                      </span>
                          <span className="line">/</span>
                          <span>
                        <Lnk target="_blank" to={`/manage/return-refund?rec_id=${el.rec_id}`} className="return-refund">退货退款</Lnk>
                      </span>
                        </p>
                    ):(
                      <p>已申请退款</p>
                    )
                    }
                    </>
                  )
                  :
                  (<p>{contentDetails.order_status_desc}</p>)
                }
              </div>
            )
          })}
          {/* 留言 */}
          <div className="message">
            <div className="message-title">卖家留言</div>
            <div className="message-context">无</div>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default BackOrdersDetails