import React from 'react'
import './index.scss'

const Empty = ({icon, text}) => {
  return (
    <div className='empty'>
      <i className={`iconfont ${icon || 'icon-empty'}`} />
      <p>{text || '暂无商品'}</p>
    </div>
  )
}

export default Empty