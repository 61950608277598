import React from 'react'
import './index.scss'

const Agreement = () => {
  document.title = '用户协议'

  return (
    <div className='agreement'>
      <div className='context'>
        <div className='agreement-title'>御秀全屋用户注册协议</div>
        <div className='agreement-content'>
<div className='agreement-level'>一、御秀全屋软装服务条款的确认与接纳</div>
{`御秀全屋软装商城网站（以下简称“本站”）上的各项电子服务的所有权和运作权归本站所有。本站提供的服务将完全按照其发布的服务条款和操作规则严格执行。用户必须完全同意所有服务条款并完成注册程序，才能成为本站的正式用户。用户确认：本协议条款是处理双方权利义务的当然约定依据。除非违反国家强制性法律，否则始终有效。在下订单的同时，您也同时承认了您拥有购买这些商品的权利能力和行为能力，并且将对您在订单中提供的所有信息的真实性负责。
协议细则`}
<div className='agreement-level'>1、本网站服务条款的确认和接纳</div>
{`本网站各项服务的所有权和运作权归本网站拥有。`}
<div className='agreement-level'>2、用户必须：</div>
{`(1)自行配备上网的所需设备， 包括个人电脑、调制解调器或其他必备上网装置。
(2)自行负担个人上网所支付的与此服务有关的电话费用、 网络费用。`}
<div className='agreement-level'>3、用户在本网站交易平台上不得发布下列违法信息：</div>
{`(1)反对宪法所确定的基本原则的；
(2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
(3)损害国家荣誉和利益的；
(4)煽动民族仇恨、民族歧视，破坏民族团结的；
(5)破坏国家宗教政策，宣扬邪教和封建迷信的；
(6)散布谣言，扰乱社会秩序，破坏社会稳定的；
(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
(8)侮辱或者诽谤他人，侵害他人合法权益的；
(9)含有法律、行政法规禁止的其他内容的。`}
<div className='agreement-level'>4、有关个人资料</div>
{`用户同意：
(1)  提供及时、详尽及准确的个人资料。
(2)  同意接收来自本网站的信息。
(3) 不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。`}
<div className='agreement-level'>5、电子邮件</div>
{`用户在注册时应当选择稳定性及安全性相对较好的电子邮箱，并且同意接受并阅读本网站发往用户的各类电子邮件。如用户未及时从自己的电子邮箱接受电子邮件或因用户电子邮箱或用户电子邮件接收及阅读程序本身的问题使电子邮件无法正常接收或阅读的，只要本网站成功发送了电子邮件，应当视为用户已经接收到相关的电子邮件。电子邮件在发信服务器上所记录的发出时间视为送达时间。`}
<div className='agreement-level'>6、服务条款的修改</div>
{`本网站有权在必要时修改服务条款，本网站服务条款一旦发生变动，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的本网站信息服务。如果用户继续享用本网站信息服务，则视为接受服务条款的变动。本网站保留随时修改或中断服务而不需通知用户的权利。本网站行使修改或中断服务的权利，不需对用户或第三方负责。`}
<div className='agreement-level'>7、用户的帐号、密码和安全性</div>
{`你一旦注册成功成为用户，你将得到一个密码和帐号。如果你不保管好自己的帐号和密码安全，将负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。你可随时根据指示改变你的密码，也可以结束旧的帐户重开一个新帐户。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，请立即通知本网站。`}
<div className='agreement-level'>8、拒绝提供担保</div>
{`用户明确同意信息服务的使用由用户个人承担风险。本网站不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保，但会在能力范围内，避免出错。`}
<div className='agreement-level'>9、有限责任</div>
{`本网站对任何直接、间接、偶然、特殊的损害不负责任，这些损害来自：不正当使用本网站服务，或用户传送的信息不符合规定等。这些行为都有可能导致本网站形象受损，所以本网站事先提出这种损害的可能性，同时会尽量避免这种损害的发生。`}
<div className='agreement-level'>10、信息的储存及限制</div>
{`本网站有判定用户的行为是否符合本网站服务条款的要求和精神的权利，如果用户违背本网站服务条款的规定，本网站有权中断其服务的帐号。`}
<div className='agreement-level'>11、用户管理</div>
{`用户必须遵循：
(1) 使用信息服务不作非法用途。
(2) 不干扰或混乱网络服务。
(3) 遵守所有使用服务的网络协议、规定、程序和惯例。用户的行为准则是以因特网法规，政策、程序和惯例为根据的。`}
<div className='agreement-level'>12、价格和数量</div>
{`本站有权根据市场形势变动情况调整此类信息并不作任何通知。如发生了价格变化情况，在确认了您的订单未发货（橱柜、衣柜、家具等商品为下单）的情况下，您有权申请退款，但希望您能及时通过电子邮件或电话通知本站客户服务。`}
<div className='agreement-level'>13、送货</div>
{`本站将会把商品送到您所指定的送货地址。所有在本站上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。
请清楚准确地填写您的真实姓名、送货地址及联系方式。因如下情况造成订单延迟或无法配送等，本站将不承担迟延配送的责任：
A、客户提供错误信息和不详细的地址
B、货物送达无人签收，由此造成的重复配送所产生的费用及相关增加的费用后果
C、不可抗力，例如：自然灾害、交通戒严、突发战争等`}
<div className='agreement-level'>14、拒绝提供担保</div>
{`用户个人对网络服务的使用承担风险。本站对此不作任何类型的担保。不论是明确的或隐含的。但是不对商业性的隐含担保、特定目的和不违反规定的适当担保作限制。本站不担保服务一定能满足用户的要求，也不担保服务不会受中断，对服务的及时性，安全性，出错发生都不作担保。本站对在本站上得到的任何商品购物服务或交易进程不作担保。`}
<div className='agreement-level'>15、保障</div>
{`用户同意保障和维护本网站全体成员的利益，负责支付由用户使用超出服务范围引起的律师费用，违反服务条款的损害补偿费用，其它人使用用户的电脑、帐号和其它知识产权的追索费。`}
<div className='agreement-level'>16、结束服务</div>
{`用户或本网站可随时根据实际情况中断一项或多项服务。本网站不需对任何个人或第三方负责而随时中断服务。用户若反对任何服务条款的建议或对后来的条款修改有异议，或对本网站服务不满，用户可以行使如下权利：
(1) 不再使用本网站信息服务。
(2) 通知本网站停止对该用户的服务。
结束用户服务后，用户使用本网站服务的权利马上中止。从那时起，用户没有权利，本网站也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。
（3）用户有下列情形的，网站可单方终止本协议，停止用户在网站的使用权利：
（一）违反本协议约定，网站依据违约条款终止本协议的；
（二）盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为的；
（三）在网站有欺诈、发布或销售假冒伪劣/侵权商品、侵犯他人合法权益或其他严重违法违约行为的；
（四）提供虚假身份信息的；
（五）其它应当终止服务的情况。`}
<div className='agreement-level'>17、通告</div>
{`所有发给用户的通告都可通过重要页面的公告或电子邮件或常规的信件传送。服务条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。`}
<div className='agreement-level'>18、信息内容的所有权</div>
{`本网站定义的信息内容包括：文字、软件、声音、相片、录象、图表；在广告中全部内容；本网站为用户提供的其它信息。所有这些内容受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在本网站和广告商授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。`}
<div className='agreement-level'>19、法律</div>
{`本网站信息服务条款要与中华人民共和国的法律解释一致。用户和本网站一致同意服从嘉兴法院管辖。`}
        </div>
      </div>
    </div>
  )
}

export default Agreement