import React from 'react'
import { Button } from 'antd'
import './index.scss'

import introduce1 from '@/assets/images/5d-1.jpg'
import introduce2 from '@/assets/images/5d-2.jpg'
import introduce3 from '@/assets/images/5d-3.jpg'
import introduce4 from '@/assets/images/5d-4.jpg'
import introduce5 from '@/assets/images/5d-5.jpg'
import panorama from '@/assets/images/panorama.jpg'
import video from '@/assets/images/video.png'
import house from '@/assets/images/house.jpg'
import menu1 from '@/assets/images/menu-1.png'
import menu2 from '@/assets/images/menu-2.png'
import menu3 from '@/assets/images/menu-3.png'

import Title from '@/components/title'
import Intelligence from './components/intelligence'
import Lnk from '@/components/lnk'

const Design5d = () => {
  document.title = '5D设计'

  const introduceList = [
    {
      id: 0,
      text1: '在线直接设计',
      text2: '一秒学会',
      icon: introduce1
    },
    {
      id: 1,
      text1: '3000+模型家居',
      text2: '所见所得',
      icon: introduce2
    },
    {
      id: 2,
      text1: '海量样板套餐',
      text2: '一键匹配',
      icon: introduce3
    },
    {
      id: 3,
      text1: '全国楼盘户型图',
      text2: '一键搜索',
      icon: introduce4
    },
    {
      id: 4,
      text1: '商品清单报价',
      text2: '一键导出',
      icon: introduce5
    }
  ]

  return (
    <div className='design5d'>
      <div className='design5d-banner'>
        <div className='design5d-banner-title'>
          <p className='design5d-title'>设计创造生活</p>
          <p className='design5d-title'>御秀为您量身打造</p>
          <p className='design5d-title-english'>Customization of Your Home</p>
          <Button className='start-design'>
            <Lnk target='_blank' to='/kujiale/0'>
              开始设计
            </Lnk>
          </Button>
        </div>
      </div>
      <div className='design5d-context'>
        <div className='context'>
          <div className='design5d-introduce'>
            {
              introduceList && introduceList.map(element => {
                return (
                  <div className='design5d-introduce-item' key={element.id}>
                    <img src={element.icon} alt='5D设计' />
                    <p>{element.text1}</p>
                    <p>{element.text2}</p>
                  </div>
                )
              })
            }
          </div>
          <Title title='人工智能时代' describe='Foresee the future' />
          <Intelligence />
          <Title title='软件优势' describe='Software Advantages' />
          <div className='advantages-introduction'>
            简易的操作，完整的素材模式。就算你不懂设计，都可以马上动手操作。结合软件
            优势功能，真正能给予您提升高效率，高体验，实现共赢转化。
          </div>
        </div>
        <div className='advantages'>
          <img src={panorama} alt='软件优势' />
          <div className='look-advantages'>
            <img src={video} alt='全景样板间' />
            <p>点击查看5D全景样板间</p>
          </div>
        </div>
        <div className='context'>
          <Title title='遇见您未来的家' describe='Foresee the future' />
          <div className='advantages-introduction'>
            三步操作，即可完成
          </div>
        </div>
        <div className='house'>
          <img src={house} alt='软件优势' />
          <div className='house-context'>
            <div className='context'>
              <div className='house-item'>
                <img src={menu1} alt='未来的家' />
                <p>第一步</p>
                <p>搜户型/画户型</p>
                <p>可搜索户型，自定义户型，一比一还原客户真实场景</p>
              </div>
              <div className='house-item'>
                <img src={menu2} alt='未来的家' />
                <p>第二步</p>
                <p>去搭配/去匹配</p>
                <p>可一键匹配样板模板，可自主搭配，多种风格随心搭配</p>
              </div>
              <div className='house-item'>
                <img src={menu3} alt='未来的家' />
                <p>第三步</p>
                <p>全景VR/效果图</p>
                <p>免费渲染，付费场景，选择角度切换，提前预见未来的家</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Design5d