import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Empty from '@/components/empty'
import {
  Form,
  Input,
  Button,
  Select,
  message,
} from 'antd';

const Withdrawal = () => {
  document.title = '我的提现'

  const [withdrawal, setWithdrawal] = useState({})
  const [banklist, setbanklist] = useState({})

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
    })
    axios.post('User/mybank', params).then((res) => {
      setbanklist(res.result)
      console.log(res.result)
    })
  }, [])
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      page: 1
    })
    axios.post('User/my_withdrawals', params).then((res) => {
      console.log(res)
      setWithdrawal(res.result)
    })
  }, [])

  const validateMessages = {
    required: `提现金额 不能为空`,
  };

  const onFinish = (e) => {
    let token =Cookies.get('token')
    let params= Qs.stringify({
      channel: 1,
      way: 3,
      token,
      ...e
    })
    console.log(e);
    axios.post('User/withdrawals', params).then((res) => {
      message.success(res.msg)
    })
  }
  return (
    <div className="withdrawal">
      <div className="cash">
        <div className="my-cash">我的钱包</div>
        <div className="cash-content">
          <div className="cash-status">
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              validateMessages={validateMessages}
              onFinish={onFinish}
            >
              <Form.Item label="余额">
                  <p className="balance">￥{withdrawal.user_money}</p>
              </Form.Item>
              
              <Form.Item label="提现金额" rules={[{ required: true }]} name="money">
                <Input style={{width: '40%'}}/>
              </Form.Item>
              <Form.Item label="提现至：" name="bankcard_id">
                <Select style={{width: '40%'}}>
                  <Select.Option value="demo">中国农业银行</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button className="submit" type="primary" htmlType="submit">
                  提现
                </Button>
              </Form.Item>
            </Form>
          </div>
          
        </div>
        <div className="record">
          <div className="withdrawal-record">我的提现记录</div>
          <ul className="withdrawal-money">
            <li className="money-number">提现金额</li>
            <li className="money-number">提现银行</li>
            <li className="money-status">时间</li>
            <li className="money-status">状态</li>
          </ul>
        </div>
        <div>
          <Empty text='暂无订单'/>
        </div>
      </div>
    </div>
  )
}
export default Withdrawal