import React, { memo } from 'react'
import './index.scss'

const Radio = (props) => {
  let { list = [], value = 0, onChange, tag } = props
  return (
    <div className='radio'>
      {
        list.map((element, index) => {
          return (
            <div className={`radio-item ${value === index && 'radio-item-active'}`} key={element.item_id} onClick={() => onChange(element.item_id, index, tag)}>
              {element.item}
            </div>
          )
        })
      }
    </div>
  )
}

export default memo(Radio)