import React, {useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookie from 'js-cookie'
import Empty from '@/components/empty'

const Share = () => {
  document.title = '我的分享'
  const [shareDetails, setShareDetails] = useState({})
  useEffect(() => {
    let token = Cookie.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('User/shareList', params).then((res) => {
      console.log(res.result);
      setShareDetails(res.result)
    })
  }, []);

  return (
    <div className="share">
      <div className="share-content">
        {/* 头部 */}
        <div className="share-top">
          <span className="share-number">分享人数 : {shareDetails.total_count} 人</span>
          <span className="total-money">总佣金 : ￥{shareDetails.total_money}</span>
        </div>
        {/* 标题部分 */}
        <div className="share-title">
          <ul className="title">
            <li>推荐客户</li>
            <li>电话</li>
            <li>成单数量</li>
            <li>注册时间</li>
            <li>所得佣金</li>
            <li>操作</li>
          </ul>
        </div>
        {/* 分享内容 */}
        <div className="share-thing">
          <Empty text='暂无分享'/>
        </div>
      </div>
    </div>
  )
}

export default Share