import React, { useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

const WorkOrder = () => {
  document.title = '作品订单'
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      page: '',
      type: 'all',
      product_id: ''
    })
    axios.post('Designer/pcProductOrder', params).then((res) => {
      console.log(res.result)
    })
  }, [])
  return (
    <div className='workOrder'>
      <div className="work-order">
        <ul className="work-order-title">
          <li>作品名称</li>
          <li>购买者</li>
          <li>支付价格</li>
          <li>购买时间</li>
          <li>订单状态</li>
          <li>管理</li>
        </ul>
      </div>
    </div>
  )
}
export default WorkOrder





