import React, { memo } from 'react'
import propTypes from 'prop-types'
import './index.scss'

import Lnk from '@/components/lnk'

const RoomList = ({ list }) => {
  return (
    <div className='room-list'>
      {
        list && list.map((element, index) => {
          return (
            <Lnk target='_blank' to={`commodity-details?goods_id=${element.goods_id}`} className='room-item' key={index}>
              <div className='room-img'>
                <div className='room-img-context'>
                  <img src={element.pic} alt='全屋套餐' />
                </div>
              </div>
              <div className='room-item-content'>
                <p className='goods-name'>{element.goods_name}</p>
                <p children='goods-style'>{element.style_name || '其他风格'}</p>
              </div>
            </Lnk>
          )
        })
      }
    </div>
  )
}

RoomList.propTypes = {
  list: propTypes.array
}

RoomList.defaultProps = {
  list: []
}

export default memo(RoomList)