import React, { memo } from 'react'
import './index.scss'

import Lnk from '../lnk'

const Type = ({ list, onChange, type, button, style, theme }) => {

  return (
    <div className={`type ${button ? 'type-button' : ''} ${theme ? 'theme-color' : ''}`}>
      {
        list && list.map((element, index) => {
          return (
            <div key={index}>
              {
                element.path ? (<Lnk className='type-item' style={style} target='_blank' to={element.path}>{element.style_name || element.name}</Lnk>) : (
                  <div style={style} onClick={() => onChange(element.id, index)} className={`type-item ${element.id ===  type ? 'active-item' : ''}`}>
                    {element.name}
                  </div>
                )
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default memo(Type)