import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Lnk from '../../components/lnk'
import banner from '@/assets/images/brand_banner.jpg'

// import Empty from '@/components/empty'

const Brand = () => {
  document.title = '品牌汇'

  const [storeStyleList, setStoreStyleList] = useState([])
  const [brandList, setBrandList] = useState({})
  const [brandStyleTab, setBrandStyleTab] = useState(0) //风格切换
  useEffect(() => {
    let params = Qs.stringify({
      channel: 3
    })
    axios.post('Index/storeStyle', params).then((res) => {
      setStoreStyleList(res.result)
    })
  }, [])

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 3,
      page: 1,
      style_id: brandStyleTab
    })
    axios.post('Index/brandList', params).then((res) => {
      setBrandList(res.result.brandList)
    })
  }, [brandStyleTab])
  return (
    <div className="brand">
      <img className="banner-img" src={banner} />
      <div className="context">
        {/* 标题 */}
        <div className="brand-title">
          <p className="brand-collection">品牌汇</p>
          <p className="brand-collection-title">
            生活方式由你原创,万般风情，没有压力
          </p>
        </div>
        {/* 品牌风格 */}
        {/*<div className="brand-style">*/}
        {/*    {*/}
        {/*    Array.isArray(storeStyleList) && storeStyleList.map((element) => {*/}
        {/*        return (*/}
        {/*          <ul key={element.style_id} className={`brand-style-title ${element.style_id === brandStyleTab ? 'active' : ''}`} onClick={() => setBrandStyleTab(element.style_id)}>*/}
        {/*            <li>{element.style_name}</li>*/}
        {/*          </ul>*/}
        {/*        )*/}
        {/*      })*/}
        {/*    }*/}
        {/*</div>*/}
        {/* 品牌内容 */}
        <div className="brand-content">
          {Array.isArray(brandList) &&
            brandList.map((element) => {
              return (
                <div className="brand-item-box" key={element.store_id}>
                  <Lnk
                    target="_blank"
                    to={`/brand-details?store_id=${element.store_id}`}
                  >
                    <div className="brand-item">
                      {/* <Lnk
                      target="_blank"
                      to={`/brand-details?store_id=${element.store_id}`}
                    > */}
                      <div className="brand-item-img">
                        <img src={element.store_avatar} alt="" />
                      </div>
                      {/* <div className="brand-item-title">
                        {element.store_name}
                      </div> */}
                      {/* <div className="small-img">
                      <img src={element.store_logo} alt="" />
                    </div>
                    <p className="brand-item-word">
                      <span>{element.store_name}</span>
                      <span>{element.style_name}</span>
                    </p> */}
                      {/* </Lnk> */}
                    </div>
                    <div className="a1">
                      <div className="brand-item-title">
                        {element.store_name}
                      </div>
                    </div>
                    <div className="brand-item-title1">
                      {element.store_name}
                    </div>
                  </Lnk>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default Brand
