import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import { API } from '../../api/config'

import { Tabs, Form, Input, Select, message, Upload, Button, Tag } from 'antd'
import head from '@/assets/images/big.png'

const DesignerInformation = () => {
  document.title = '设计师资料'
  const { TabPane } = Tabs
  const { TextArea } = Input
  const { Option } = Select
  const { CheckableTag } = Tag
  const [form] = Form.useForm()

  const [designerInfo, setDesignerInfo] = useState({}) //设计师信息
  const [nationality, setNationality] = useState({}) //国籍
  const [worksStyleTab, setStyleTab] = useState([]) //风格切换
  const [serviceTypeTab, setServiceTypeTab] = useState([]) //服务类型切换
  // #########################
  const [personalRegionPicker, setPersonalRegionPicker] = useState({})
  // #########################
  const [workArea, setSelectWorkArea] = useState({}) //选中的工作所属地区
  const [serviceArea, setServiceArea] = useState({}) //选中的服务地区
  const [province, setProvince] = useState('') //个人 省
  const [personCity, setPersonCity] = useState('') //个人 市
  const [area, setArea] = useState('') //个人 区

  const [jobProvince, setJobProvince] = useState('') //公司 省
  const [jobCity, setJobCity] = useState('') //公司 市
  const [jobArea, setJobArea] = useState('') //公司 区

  const [serviceProvince, setServiceProvince] = useState('') //服务地区 省
  const [serviceCity, setServiceCity] = useState('') //服务地区 市
  const [defaultServiceArea, setDefaultServiceArea] = useState('') //服务地区

  const [imageUrl, setImageUrl] = useState('') //头像
  const [smallUrl, setSmallUrl] = useState('') //小封面图
  const [bigUrl, setBigUrl] = useState('') //大封面图
  const [nextKey, setNextKey] = useState('1')

  // const [fileList, setFileList] = useState([])
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('Designer/designerInfo', params).then((res) => {
      console.log('设计师信息', res.result)
      setSmallUrl(res.result.fm_img)
      setBigUrl(res.result.fm_img2)

      let {
        company_address,
        company_name,
        company_mobile,
        company_province,
        company_city,
        company_area
      } = res.result.company
      let { home_province, home_city, home_area } = res.result.home
      let { service_province, service_city, service_area } = res.result.service
      // let address = company_province_name + company_city_name + company_area_name + company_address + company_name
      // let personalArea = company_province_name + company_city_name + company_area_name
      let { id } = res.result.user_id
      let address = company_name
      let companyAddress = company_address
      let companyMobile = company_mobile
      let country_id = id

      // 个人
      setProvince(home_province)
      setPersonCity(home_city)
      setArea(home_area)
      // 公司
      setJobProvince(company_province)
      setJobCity(company_city)
      setJobArea(company_area)
      // 服务
      setServiceProvince(service_province)
      setServiceCity(service_city)
      setDefaultServiceArea(service_area)

      // console.log('province', province)
      setDesignerInfo(res.result)
      console.log('风格', res.result.style)
      let { style } = res.result
      let temp = []
      style.forEach((element) => {
        if (element.is_have) {
          temp.push(element)
        }
      })
      setStyleTab(temp)
      let firstId = ''
      if (res.result.style.length) {
        firstId = res.result.style[0].id
      }
      let { type } = res.result
      let temp1 = []
      type.forEach((element) => {
        if (element.is_have) {
          temp1.push(element)
        }
      })
      setServiceTypeTab(temp1)

      form.setFieldsValue({
        ...res.result,
        company_name: address,
        company_address: companyAddress,
        company_mobile: companyMobile,
        ...res.result.home,
        ...res.result.country,
        ...res.result.style,
        ...res.result.company,
        ...res.result.service,
        province,
        personCity,
        area,
        jobProvince,
        jobCity,
        jobArea,
        serviceProvince,
        serviceCity,
        defaultServiceArea,
        country_id
      })
    })
  }, [form])

  // 个人
  useEffect(() => {
    if (!province) {
      return
    }
    $handleProvince(province, 'city')
  }, [province])
  useEffect(() => {
    if (!province) {
      return
    }
    $handleProvince(personCity, 'area')
  }, [personCity])
  // 公司
  useEffect(() => {
    if (!province) {
      return
    }
    $handleWork(jobProvince, 'city')
  }, [jobProvince])
  useEffect(() => {
    if (!province) {
      return
    }
    $handleWork(jobCity, 'area')
  }, [jobCity])
  // 服务区
  useEffect(() => {
    if (!province) {
      return
    }
    $handleServiceArea(serviceProvince, 'city')
  }, [serviceProvince])
  useEffect(() => {
    if (!province) {
      return
    }
    $handleServiceArea(serviceCity, 'area')
  }, [serviceCity])

  // 服务地区
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('Designer/region', params).then((res) => {
      setPersonalRegionPicker({
        province: res.result
      })

      setSelectWorkArea({
        province: res.result
      })
      setServiceArea({
        province: res.result
      })
    })
  }, [])
  // 国籍
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('Index/country', params).then((res) => {
      setNationality(res.result)
      // console.log('国籍',res.result)
    })
  }, [])
  // 个人所属地区
  const $handleProvince = (value, tag) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      id: value
    })
    axios.post('Designer/region', params).then((res) => {
      setPersonalRegionPicker({
        ...personalRegionPicker,
        [tag]: res.result
      })
      // console.log(res.result)
    })
  }
  // 工作地区
  const $handleWork = (value, tag) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      id: value
    })
    axios.post('Designer/region', params).then((res) => {
      setSelectWorkArea({
        ...workArea,
        [tag]: res.result
      })
    })
  }
  // 服务地区
  const $handleServiceArea = (value, tag) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      id: value
    })
    axios.post('Designer/region', params).then((res) => {
      setServiceArea({
        ...serviceArea,
        [tag]: res.result
      })
    })
  }

  // 上传头像
  
  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('只能上传图片只支持 JPG/PNG !')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('图片必须小于2MB!')
    }
    return isJpgOrPng && isLt2M
  }
  const handleChange = (info, tag) => {
    console.log('info', info)
    if (info.file.status === 'done') {
      if (tag === 'head') {
        let url = info.file.response.result || ''
        setImageUrl(url)
      }
      if (tag === 'small') {
        let url = info.file.response.result || ''
        setSmallUrl(url)
      }
      if (tag === 'big') {
        let url = info.file.response.result || ''
        setBigUrl(url)
        console.log('url', url)
      }
    }
  }
  // 擅长风格
  const handleChangeStyle = (element, checked) => {
    console.log('element', element)
    const nextWorksStyleTab = checked
      ? [...worksStyleTab, element]
      : worksStyleTab.filter((t) => t !== element)
    designerInfo.style.forEach((el) => {
      if (element.id === el.id) {
        el.is_have = checked ? 1 : 0
      }
    })
    setStyleTab(nextWorksStyleTab)
  }
  // 服务类型
  const handleChangeType = (element, checked) => {
    console.log('///', checked)
    const nextWorksTypeTab = checked
      ? [...serviceTypeTab, element]
      : serviceTypeTab.filter((t) => t !== element)
      designerInfo.type.forEach((el) => {
        if (element.id === el.id) {
          el.is_have = checked ? 1 : 0
        }
      })
    setServiceTypeTab(nextWorksTypeTab)
    // console.log('???',serviceTypeTab)
  }
  // 提交
  const onFinish = (event) => {
    let token = Cookies.get('token')
    let arr = []
    serviceTypeTab &&
      serviceTypeTab.forEach((element) => {
        arr.push(element.id)
      })
    let type = [...arr]
    let newType = type.join(',')

    let newStyle = []
    worksStyleTab.forEach(element => {
      newStyle.push(element.id)
    })
    let style1 = [...newStyle]
    let styleType = style1.join(',')

    let smallImg = ''
    if (typeof event.fm_img.file !== 'undefined') {
      smallImg = event.fm_img.file.response.result
    } else {
      smallImg = event.fm_img
    }
    let bigImg = ''
    if (typeof event.fm_img2.file !== 'undefined') {
      bigImg = event.fm_img2.file.response.result
    } else {
      bigImg = event.fm_img2
    }
    let head_pic = ''
    if (typeof event.head_pic.file !== 'undefined') {
      head_pic = event.head_pic.file.response.result
    } else {
      head_pic = event.head_pic
    }
    delete event.fm_img
    delete event.fm_img2
    delete event.head_pic
    // console.log('event', event)

    let params = Qs.stringify({
      channel: 1,
      token,
      ...event,
      home_province: province,
      home_city: personCity,
      home_area: area,
      company_province: jobProvince,
      company_city: jobCity,
      company_area: jobArea,
      service_province: serviceProvince,
      service_city: serviceCity,
      service_area: defaultServiceArea,
      type: newType,
      style: styleType,
      fm_img: smallImg,
      fm_img2: bigImg,
      head_pic: head_pic
    })
    axios.post('Designer/edit_designerInfo', params).then((res) => {
      message.success(res.msg)
    })
  }

  return (
    <div className="designerInformation">
      <div className="designer-information">
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 15 }}
          onFinish={onFinish}
        >
          <Tabs activeKey={nextKey} onChange={(event) => setNextKey(event)}>
            <TabPane tab="个人中心" key="1">
              <div className="personal-center">
                {/* 上传头像 */}
                <p className="personal-title">*上传头像</p>
                <Form.Item name="head_pic">
                  <Upload
                    showUploadList={false}
                    action={API + 'User/upload'}
                    beforeUpload={beforeUpload}
                    onChange={(event) => handleChange(event, 'head')}
                  >
                    {imageUrl ? (
                      <div className="head-portrait">
                        <img
                          src={imageUrl}
                          style={{ width: '100%' }}
                          alt="头像"
                        />
                      </div>
                    ) : (
                      <div className="head-portrait">
                        <img src={designerInfo.head_pic} alt="头像" />
                      </div>
                    )}
                  </Upload>
                </Form.Item>
                {/* 真实姓名*/}
                <p className="personal-title">*真实姓名（填写后不可修改）</p>
                <Form.Item name="real_name">
                  <Input disabled={designerInfo.real_name ? true : false} />
                </Form.Item>
                {/* 昵称*/}
                <p className="personal-title">*昵称</p>
                <Form.Item name="nick_name">
                  <Input />
                </Form.Item>
                {/* 个人所属地区 */}
                <p className="personal-title">*个人所属地区</p>
                <div>
                  {/* 省 */}
                  <Select
                    style={{ width: 150 }}
                    value={province}
                    onChange={(event) => {
                      setProvince(event)
                      $handleProvince(event, 'city')
                    }}
                  >
                    {Array.isArray(personalRegionPicker.province) &&
                      personalRegionPicker.province.map((element, id) => {
                        return (
                          <Option key={id} value={element.id}>
                            {element.name}
                          </Option>
                        )
                      })}
                  </Select>
                  {/* 市 */}
                  <Select
                    style={{ width: 150 }}
                    value={personCity}
                    onChange={(event) => {
                      setPersonCity(event)
                      $handleProvince(event, 'area')
                    }}
                  >
                    {Array.isArray(personalRegionPicker.city) &&
                      personalRegionPicker.city.map((element, id) => {
                        return (
                          <Option key={id} value={element.id}>
                            {element.name}
                          </Option>
                        )
                      })}
                  </Select>
                  {/* 区 */}
                  <Select
                    style={{ width: 150 }}
                    value={area}
                    onChange={(event) => {
                      setArea(event)
                    }}
                  >
                    {Array.isArray(personalRegionPicker.area) &&
                      personalRegionPicker.area.map((element, id) => {
                        return (
                          <Option key={id} value={element.id}>
                            {element.name}
                          </Option>
                        )
                      })}
                  </Select>
                </div>
                {/* 个人联系电话 */}
                <p className="personal-title">*个人联系电话</p>
                <Form.Item name="home_mobile">
                  <Input />
                </Form.Item>
                {/* 工作单位地区 */}
                <p className="personal-title">*工作单位地区</p>
                <Form.Item name="">
                  {/* 省 */}
                  <Select
                    style={{ width: 150 }}
                    value={jobProvince}
                    onChange={(event) => {
                      setJobProvince(event)
                      $handleWork(event, 'city')
                    }}
                  >
                    {Array.isArray(workArea.province) &&
                      workArea.province.map((element, id) => {
                        return (
                          <Option key={id} value={element.id}>
                            {element.name}
                          </Option>
                        )
                      })}
                  </Select>
                  {/* 市 */}
                  <Select
                    style={{ width: 150 }}
                    value={jobCity}
                    onChange={(event) => {
                      setJobCity(event)
                      $handleWork(event, 'area')
                    }}
                  >
                    {Array.isArray(workArea.city) &&
                      workArea.city.map((element, id) => {
                        return (
                          <Option key={id} value={element.id}>
                            {element.name}
                          </Option>
                        )
                      })}
                  </Select>
                  {/* 区 */}
                  <Select
                    style={{ width: 150 }}
                    value={jobArea}
                    onChange={(event) => {
                      setJobArea(event)
                    }}
                  >
                    {Array.isArray(workArea.area) &&
                      workArea.area.map((element, id) => {
                        return (
                          <Option key={id} value={element.id}>
                            {element.name}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
                {/* 工作单位详细地址 */}
                <p className="personal-title">*工作单位详细地址</p>
                {/* {console.log('公司地址', company_address)} */}
                <Form.Item name="company_name">
                  <Input />
                </Form.Item>
                {/* 工作单位名称 */}
                <p className="personal-title">*工作单位名称</p>
                <Form.Item name="company_address">
                  <Input />
                </Form.Item>
                {/* 工作单位电话 */}
                <p className="personal-title">*工作单位电话</p>
                <Form.Item name="company_mobile">
                  <Input />
                </Form.Item>
                {/* 服务地区 */}
                <p className="personal-title">*服务地区</p>
                <Form.Item>
                  {/* 省 */}
                  <Select
                    style={{ width: 150 }}
                    value={serviceProvince}
                    onChange={(event) => {
                      setServiceProvince(event)
                      $handleServiceArea(event, 'city')
                    }}
                  >
                    {Array.isArray(serviceArea.province) &&
                      serviceArea.province.map((element, id) => {
                        return (
                          <Option key={id} value={element.id}>
                            {element.name}
                          </Option>
                        )
                      })}
                  </Select>
                  {/* 市 */}
                  <Select
                    style={{ width: 150 }}
                    value={serviceCity}
                    onChange={(event) => {
                      setServiceCity(event)
                      $handleServiceArea(event, 'area')
                    }}
                  >
                    {Array.isArray(serviceArea.city) &&
                      serviceArea.city.map((element, id) => {
                        return (
                          <Option key={id} value={element.id}>
                            {element.name}
                          </Option>
                        )
                      })}
                  </Select>
                  {/* 区 */}
                  <Select
                    style={{ width: 150 }}
                    value={defaultServiceArea}
                    onChange={(event) => {
                      setDefaultServiceArea(event)
                    }}
                  >
                    {Array.isArray(serviceArea.area) &&
                      serviceArea.area.map((element, id) => {
                        return (
                          <Option key={id} value={element.id}>
                            {element.name}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
                {/* 国籍 */}
                {/*<p className="personal-title">*国籍</p>*/}
                {/*<Form.Item name="country_id">*/}
                {/*  <Select style={{ width: 150 }}>*/}
                {/*    {Array.isArray(nationality.list) &&*/}
                {/*    nationality.list.map((element) => {*/}
                {/*      return <div key={element.id}>{element.show_cn}</div>*/}
                {/*    })}*/}
                {/*  </Select>*/}
                {/*</Form.Item>*/}
                {/* 工作经验 */}
                <p className="personal-title">*工作经验</p>
                <Form.Item name="job_year" span={5}>
                  {/* <Col span={5}> */}
                  <Input />
                  {/* </Col> */}
                </Form.Item>
                {/* 擅长风格 */}
                <p className="personal-title">*擅长风格</p>
                <Form.Item name="">
                  {Array.isArray(designerInfo.style) &&
                    designerInfo.style.map((element, index) => {
                      return (
                        <CheckableTag
                          key={index}
                          checked={element.is_have === 1}
                          onChange={(checked) =>
                            handleChangeStyle(element, checked)
                          }
                          value={element.id}
                          className="style-good"
                        >
                          {element.style_name}
                        </CheckableTag>
                      )
                    })}
                </Form.Item>
                {/* 服务类型 */}
                <p className="personal-title">*服务类型</p>
                <Form.Item name="">
                  {Array.isArray(designerInfo.type) &&
                    designerInfo.type.map((element, index) => {
                      return (
                        <CheckableTag
                          key={index}
                          checked={element.is_have === 1}
                          onChange={(checked) =>
                            handleChangeType(element, checked)
                          }
                          value={element.id}
                          className="style-good"
                        >
                          {element.name}
                        </CheckableTag>
                      )
                    })}
                </Form.Item>
                {/* *个人简介 */}
                <p className="personal-title">*个人简介</p>
                <Form.Item name="con">
                  <TextArea
                    placeholder="请填写个人简介(如毕业院校，工作经验，获奖情况等)"
                    allowClear
                  />
                </Form.Item>
                <div
                  className="next-step"
                  onClick={() => {
                    setNextKey('2')
                    console.log('key', nextKey)
                  }}
                >
                  下一步
                </div>
              </div>
            </TabPane>
            <TabPane tab="个性封面" key="2">
              <div className="personalized-cover">
                {/* 封面上部 */}
                <div className="cover-top">
                  <Form.Item name="fm_img">
                    <Upload
                      showUploadList={false}
                      action={API + 'User/upload'}
                      beforeUpload={beforeUpload}
                      onChange={(event) => handleChange(event, 'small')}
                    >
                      {smallUrl ? (
                        <div className="top-img">
                          <img
                            src={smallUrl}
                            style={{ width: '100%' }}
                            alt="个性图"
                          />
                        </div>
                      ) : (
                        <div className="top-img">
                          <img src={head} />
                        </div>
                      )}
                      <Button className="top-img-btn">上传新封面</Button>
                    </Upload>
                  </Form.Item>
                  <div className="explain">
                    <p>封面图说明</p>
                    <p>1、封面图将在个人主页显示</p>
                    <p>2、最佳尺寸为394*315px</p>
                    <p>3、上传图片规格化仅支持jpg/png</p>
                    <p>4、上传图片大小不超过2M</p>
                  </div>
                </div>
                {/* 封面下部 */}
                <div className="cover-bottom">
                  <Form.Item name="fm_img2">
                    <Upload
                      showUploadList={false}
                      action={API + 'User/upload'}
                      beforeUpload={beforeUpload}
                      onChange={(event) => handleChange(event, 'big')}
                    >
                      {bigUrl ? (
                        <div className="bottom-img">
                          <img
                            src={bigUrl}
                            style={{ width: '100%', height: '100%' }}
                            alt="个性图"
                          />
                        </div>
                      ) : (
                        <div className="bottom-img">
                          <img src={head} />
                        </div>
                      )}
                      <Button className="bottom-img-btn">上传新封面</Button>
                    </Upload>
                  </Form.Item>
                  <div className="explain">
                    <p>封面图说明</p>
                    <p>1、封面图将在个人主页显示</p>
                    <p>2、最佳尺寸为280*625px</p>
                    <p>3、上传图片规格化仅支持jpg/png</p>
                    <p>4、上传图片大小不超过2M</p>
                  </div>
                </div>
                <div className="bottom-submit">
                  <span className="step-btn" onClick={() => setNextKey('1')}>
                    上一步
                  </span>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                  >
                    提交
                  </Button>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </Form>
      </div>
    </div>
  )
}

export default DesignerInformation
