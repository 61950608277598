import React, { useState, useEffect } from 'react'
import { Checkbox, Radio, Input, message, Modal, Button, Form } from 'antd'
import { pcaa } from 'area-data'
import { AreaSelect } from 'react-area-linkage'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'
import Lnk from '../../components/lnk'
import './index.scss'
import addressSelect from '@/assets/images/address.png'
import address from '@/assets/images/address1.png'
import shop from '@/assets/images/shop.png'

const Order = (props) => {
  document.title = '提交订单'
  const { TextArea } = Input
  let role = Cookies.get('role')
  let token = Cookies.get('token') || ''
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [addressList, setAddressList] = useState([])
  const [addressChecked, setAddressChecked] = useState(0) // 选择的地址索引
  const [orderInfo, setOrderInfo] = useState({})
  const [isInvoice, setIsInvoice] = useState(false) // 是否需要发票
  const [isCoupon, setIsCoupon] = useState(true) // 是否不使用优惠券
  const [invoiceType, setInvoiceType] = useState(0) // 发票类型
  const [invoiceTitle, setInvoiceTitle] = useState(0) // 发票抬头
  const [leaveMessage, setLeaveMessage] = useState('') // 买家留言
  const [form] = Form.useForm()
  const [forms] = Form.useForm()
  const [show, setShow] = useState(false)
  const [addressArea, setAddressArea] = useState({})
  const [discount, setDiscount] = useState(0) // 优惠金额
  const [orderMoney, setOrderMoney] = useState({})
  const [isAddressModel, setIsAddressModel] = useState(false) // 是否显示添加地址弹框

  useEffect(() => {
    $getAddressList()
    $getCommodityList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  // 选择优惠券价格变化
  useEffect(() => {
    let ress_id = addressList && addressList[addressChecked] && addressList[addressChecked].address_id
    let params = Qs.stringify({
      token,
      channel: 1,
      ress_id,
      user_note: '',
      coupon_id: discount,
      couponCode: '',
      pay_points: '',
      user_money: '',
      act: 'reckon_order',
      invoice_id: '',
      is_pick_up: 0
    })
    axios.post(propsParams.type === 'shopChart' ? 'Cart/cart3' : 'Cart/deal_buy', params).then(res => {
      setOrderMoney(res.result)
    })
  }, [discount, addressList, addressChecked, token, propsParams])

  // 获取地址列表
  const $getAddressList = () => {
    let params = Qs.stringify({
      token,
      channel: 1
    })
    axios.post('User/addressList', params).then(res => {
      let addressList = Array.isArray(res.result) ? res.result : []
      addressList.forEach((element, index) => {
        if (element.is_default === '1') {
          setAddressChecked(index)
        }
      })
      if (!addressList.length) {
        setIsAddressModel(true)
      }
      setAddressList(addressList)
    })
  }

  // 获取订单信息
  const $getCommodityList = () => {
    let url = propsParams.type === 'shopChart' ? 'Cart/cart2' : 'Cart/check_buy'
    let params = Qs.stringify({
      token,
      channel: 1
    })
    axios.post(url, params).then(res => {
      console.log(res.result)
      setOrderInfo(res.result)
    })
  }

  // 买家留言
  const $onInputLeaveMessage = (e) => {
    e.persist()
    let value = e.target.value
    setLeaveMessage(value)
  }

  // 下单
  const $buy = () => {
    // 开发票
    if (isInvoice) {
      let invoiceObj = forms.getFieldValue()
      let invoiceKeys = Object.keys(invoiceObj)
      let invoiceLength = 1
      if (invoiceType === 0) {
        if (invoiceTitle === 1) {
          invoiceLength = 2
        }
      } else {
        invoiceLength = 6
      }
      if (invoiceLength > invoiceKeys.length) {
        message.info('请检查发票信息')
        return
      } else {
        let params = Qs.stringify({
          token,
          channel: 1,
          type: invoiceType,
          ...invoiceObj
        })
        axios.post('User/addInvoice', params).then(res => {
          let invoice_id = res.result.invoice_id
          placeAnOrder(invoice_id)
          console.log('开发票>>>', res)
        })
      }
    } else {
      placeAnOrder()
    }
  }

  // // 下单
  const placeAnOrder = (invoice_id) => {
    let url = propsParams.type === 'shopChart' ? 'Cart/cart3' : 'Cart/deal_buy'
    let params = Qs.stringify({
      token,
      channel: 1,
      ress_id: addressList[addressChecked].address_id,
      user_note: leaveMessage,
      coupon_id: discount,
      couponCode: '',
      pay_points: '',
      user_money: '',
      act: 'submit_order',
      invoice_id: invoice_id || '',
      is_pick_up: 0
    })
    axios.post(url, params).then(res => {
      if (res.status === 1) {
        props.history.push(`/payment?order_sn=${res.result}`)
      } else {
        message.info(res.msg)
      }
    })
  }

  const handleCancel = () => {
    setShow(false)
  }

  // 修改地址
  const onFinish = e => {
    let province
    let json_province = e.event[0]
    for (var key_province in json_province) {
      province = key_province;     //获取key值
    }
    let city
    let json_city = e.event[1]
    for (var key_city in json_city) {
      city = key_city;     //获取key值
    }
    let district
    let json_district = e.event[2]
    for (var key_district in json_district) {
      district = key_district;     //获取key值
    }
    let selectAddress = addressList[addressChecked]
    let token = Cookies.get('token')
    let editorParams = Qs.stringify({
      token,
      channel: 1,
      ...selectAddress,
      ...e,
      province,
      city,
      district
    })
    axios.post('User/edit_address', editorParams).then((res) => {
      $getAddressList()
      setShow(false)
      message.success(res.msg)
    })
  }

  function onChange (e) {
    console.log(`checked = ${e.target.checked}`)
  }

  const editShippingAddress = (e) => {
    let add = []
    add[0] = e.province
    add[1] = e.city
    add[2] = e.district
    setAddressArea(add)
    setShow(true)
    form.setFieldsValue({
      ...e,
    })
  }

  let { cartList = {} } = orderInfo
  return (
    <div className='order'>
      <Modal
        title="提示"
        closable={false}
        footer={null}
        width={300}
        style={{ textAlign: "center" }}
        visible={isAddressModel}
      >
        请先填写收货地址
        <div className="submit-to-address" onClick={() => props.history.push('/manage/shipping-address?pid=4&cid=16')}>确定</div>
      </Modal>
      <div className='context'>
        {/* 地址列表 */}
        <div className='address-list'>
          <div className='address-tips'>
            <span>收货信息</span> 亲，为确保给您送货入户，下单地址务必精确到几幢几楼或几组几号哦~
          </div>
          <div className='address-body'>
            {
              Array.isArray(addressList) && addressList.map((element, index) => {
                return (
                  <div className='address-item' key={element.address_id} onClick={() => setAddressChecked(index)} style={{ backgroundImage: `url(${addressChecked === index ? addressSelect : address})` }}>
                    <div className='address-info'>
                      {element.province_name}{element.city_name}{element.consignee}(收)
                    </div>
                    <div className='address-details'>
                      {element.district_name} {element.address}
                    </div>
                    <div className='user-phone'>
                      {element.mobile}
                    </div>
                    <span className='editor' onClick={() => editShippingAddress(element)}>修改</span>
                  </div>
                )
              })
            }
          </div>
          <div className='address-options'>
            {/* <span>显示全部地址</span> */}
            <span></span>
            <Lnk target="_blank" to="/manage/shipping-address?pid=4&cid=16">管理收货地址</Lnk>
          </div>
        </div>
        {/*  */}
        <Modal
          visible={show}
          footer={null}
          onCancel={handleCancel}
          centered
          width="700px"
          className="add-editor-modal"
        >
          <div className="add-address-content">
            <Form
              form={form}
              onFinish={onFinish}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
            >
              <Form.Item label="地址信息" colon={false}></Form.Item>
              <Form.Item
                label="收货人"
                className="consignee"
                name="consignee"
                rules={[
                  {
                    required: true,
                    message: '收货人不能为空',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="手机号码" className="phone" name="mobile" rules={[
                () => ({
                  validator (rule, value) {
                    if (value && (/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
                      return Promise.resolve()
                    }
                    return Promise.reject('请检查手机号是否正确!')
                  }
                })
              ]}>
                <Input />
              </Form.Item>

              {
                show && (
                  <Form.Item
                    label="所在地区"
                    className="consignee"
                    name="event"
                    rules={[
                      {
                        required: true,
                        message: '所在地区不能为空',
                      },
                    ]}
                  >
                    <AreaSelect
                      type="all"
                      level={2}
                      data={pcaa}
                      defaultArea={addressArea}
                    />
                  </Form.Item>
                )
              }

              <Form.Item
                label="详细地址"
                className="consignee"
                name="address"
                rules={[
                  {
                    required: true,
                    message: '详细地址不能为空',
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Checkbox
                onChange={onChange}
                id="default-address"
                name="is_default"
              >
                设置为默认地址
                  </Checkbox>
              <Button
                className="submit-btn"
                type="primary"
                htmlType="submit"
              >
                确定
                  </Button>
              <Button
                className="call-off"
                type="primary"
                onClick={() => setShow(false)}
              >
                取消
                  </Button>
            </Form>
          </div>
        </Modal>
        {/*  */}
        {/* 商品列表 */}
        <div className='commodity-list'>
          {
            Array.isArray(cartList.cartShop) && cartList.cartShop.map((element, index) => {
              return (
                <table className='commodity-table' key={index}>
                  <tbody>
                    {
                      index === 0 && (
                        <tr>
                          <td>商品信息</td>
                          <td>单价</td>
                          <td>数量</td>
                          <td>小计（元）</td>
                        </tr>
                      )
                    }
                    <tr className='shop-name'>
                      <td colSpan='4'>
                        <img className='shop-img' src={shop} alt="门店" />
                        <span>{element.store_name}</span>
                      </td>
                    </tr>
                    {
                      Array.isArray(element.goodsList) && element.goodsList.map(el => {
                        return (
                          <tr className='table-commodity-item' key={el.id}>
                            <td>
                              <div className='table-commodity-info'>
                                <img className='table-commodity-img' src={el.pic} alt="商品" />
                                {el.goods_name}
                              </div>
                            </td>
                            <td>¥{el.goods_price}</td>
                            <td>{el.goods_num}</td>
                            <td>¥{(el.goods_price * el.goods_num).toFixed(2)}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              )
            })
          }
        </div>
        {/* 发票 */}
        <div className='invoice'>
          <div className='invoice-title'>
            发票/优惠信息
          </div>
          <div className='invoice-item'>
            <Checkbox
              checked={isInvoice}
              onChange={(event) => setIsInvoice(event.target.checked)}
            >开发票</Checkbox>
          </div>
          {
            isInvoice && (
              <div>
                <div className='invoice-item'>
                  <div className='invoice-item-label'>发票类型</div>
                  <div className='invoice-item-value'>
                    <Radio.Group onChange={(event) => setInvoiceType(event.target.value)} value={invoiceType}>
                      <Radio value={0}>电子普通发票</Radio>
                      <Radio value={1}>增值税专用发票</Radio>
                    </Radio.Group>
                  </div>
                </div>
                {
                  invoiceType === 0 && (
                    <div className='invoice-item'>
                      <div className='invoice-item-label'>发票抬头</div>
                      <div className='invoice-item-value'>
                        <Radio.Group onChange={(event) => setInvoiceTitle(event.target.value)} value={invoiceTitle}>
                          <Radio value={0}>个人</Radio>
                          <Radio value={1}>企业</Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  )
                }
                <Form form={forms} className="invoice-item-form" labelCol={{ span: 6 }}>
                  <Form.Item label="发票抬头" name="company">
                    <Input placeholder="请输入发票抬头" />
                  </Form.Item>
                  {
                    (invoiceType === 1 || invoiceTitle === 1) && (
                      <Form.Item label="税务登记证号" name="tax_nums">
                        <Input placeholder="请输入税务登记证号" />
                      </Form.Item>
                    )
                  }
                  {
                    invoiceType === 1 && (
                      <>
                        <Form.Item label="基本开户银行名" name="bank_name">
                          <Input placeholder="请输入基本开户银行名" />
                        </Form.Item>
                        <Form.Item label="基本开户账号" name="bank_cardid">
                          <Input placeholder="请输入基本开户账号" />
                        </Form.Item>
                        <Form.Item label="注册场所地址" name="address">
                          <Input placeholder="请输入注册场所地址" />
                        </Form.Item>
                        <Form.Item label="注册固定电话" name="mobile">
                          <Input placeholder="请输入注册固定电话" />
                        </Form.Item>
                      </>
                    )
                  }
                </Form>
                <div className='invoice-item invoice-intro'>
                  御秀开具的电子发票均为真实有效的合法发票，与传统纸质发票具有同等法律效力，可作为用户维权、保修、报销的有效凭据。
                  电子发票会在确认收货后15天内，将发票代码和发票号码以短信方式发送到收货人手机上。
                  开具成功后，您可登录电子发票查询系统 https://inv-veri.chinatax.gov.cn下载电子发票
                </div>
              </div>
            )
          }
          <div className='invoice-item'>
            <div className='invoice-item-label'>买家留言</div>
            <div className='invoice-item-value'>
              <Input className='invoice-title-code' onChange={$onInputLeaveMessage} style={{ width: '100%' }} type='text' />
            </div>
          </div>
          <div className='invoice-item'>
            <Checkbox
              checked={isCoupon}
              onChange={(event) => {
                let { checked } = event.target
                setDiscount('')
                setIsCoupon(checked)
              }}
            >不使用优惠券</Checkbox>
          </div>
          {
            !isCoupon && (
              <div className='invoice-item coupon'>
                <Radio.Group style={{ width: '100%' }} onChange={(e) => setDiscount(e.target.value)}>
                  {
                    orderInfo.couponList && orderInfo.couponList.map(element => {
                      return (
                        <Radio style={{ marginBottom: 10 }} value={element.id} key={element.id}>
                          <div className="coupon-content">
                            ￥{element.money}
                            <div>（{element.name}）</div>
                          </div>
                        </Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            )
          }
        </div>
        {/* 结算 */}
        <div className='settlement'>
          <div className='settlement-item'>
            <span className='label'>商品合计：</span>
            <span className='value'>￥{cartList.total_price && cartList.total_price.total_fee}</span>
          </div>
          <div className='settlement-item'>
            <span className='label'>运费：</span>
            <span className='value'>￥{cartList.total_price && cartList.total_price.total_shipping}</span>
          </div>
          <div className='settlement-item'>
            <span className='label'>配送至：</span>
            <span className='value'>{addressList[addressChecked] && addressList[addressChecked].province_name + addressList[addressChecked].city_name + addressList[addressChecked].district_name + addressList[addressChecked].address}</span>
          </div>
          <div className='settlement-item'>
            <span className='label'>收货人：</span>
            <span className='value'>{addressList[addressChecked] && addressList[addressChecked].consignee + ' ' + addressList[addressChecked].mobile}</span>
          </div>
          <div className='settlement-item buy-commodity-btn'>
            <span>商品数量：{cartList.total_price && cartList.total_price.num}个</span>
            <span>商品总金额：{orderMoney.goodsFee && orderMoney.goodsFee.toFixed(2)}元</span>
            {
              role === '2'
                ?
                <span>合伙人抵扣：{orderMoney.discount_fee}元</span>
                :
                ''
            }

            <span>优惠券抵扣：{orderInfo.couponFee || orderMoney.couponFee}元</span>
            <span>付款金额：<span className='money'>{orderMoney.payables && orderMoney.payables.toFixed(2)}</span> 元</span>
            <button className='buy-btn' onClick={$buy}>下单</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Order