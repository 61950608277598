import React, { useState, useEffect, useCallback } from 'react'
import './index.scss'
import 'react-area-linkage/dist/index.css'
import { pcaa } from 'area-data'
import { AreaSelect } from 'react-area-linkage'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import { Modal, Button, Form, Input, Checkbox, message } from 'antd'
const ShippingAddress = () => {
  document.title = '收货地址'


  const { TextArea } = Input
  const [form] = Form.useForm()
  const [show, setShow] = useState(false)
  const [addressList, setAddressList] = useState({})
  const [addressArea, setAddressArea] = useState({})
  const [isEditor, setIsEditor] = useState(false)
  const [editorAddressInfo, setAddressInfo] = useState({})
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('User/addressList', params).then((res) => {
      console.log(res.result)
      setAddressList(res.result)
    })
  }, [])
  const handleCancel = () => {
    setShow(false)
  }
  function onChange (e) {
    console.log(`checked = ${e.target.checked}`)
  }

  const onFinish = (e) => {
    // let regPhone = /^1[3-9]\d{9}$/
    // regPhone.test(e.mobile)
    console.log(e.event)
    let province
    let json_province = e.event[0]
    for (var key_province in json_province) {
      province = key_province;     //获取key值
    }
    let city
    let json_city = e.event[1]
    for (var key_city in json_city) {
      city = key_city;     //获取key值
    }
    let district
    let json_district = e.event[2]
    for (var key_district in json_district) {
      district = key_district;     //获取key值
    }


    let token = Cookies.get('token')
    let params = {
      channel: 1,
      token,
      ...e,
      province: province,
      city: city,
      district: district
    }
    isEditor ? $onClickEditAddrerss(params) : $onClickAddAddress(params)
    setShow(false)
  }

  // 修改收货地址显示模态框
  const editShippingAddress = (e) => {
    let add = []
    add[0] = e.province
    add[1] = e.city
    add[2] = e.district
    console.log(add)
    setAddressArea(add)
    setShow(true)
    setIsEditor(true)
    setAddressInfo(e)
    form.setFieldsValue({
      ...e,
    })
  }
  //设为默认地址
  const onClickSetDefaultAddress = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      address_id: id,
      is_default: 1
    })
    axios.post('User/set_address', params).then((res) => {
      message.success(res.msg)
      $getAddressList()
    })
  }
  // 新增地址
  const $onClickAddAddress = (pa) => {
    let params = Qs.stringify(pa)
    axios.post('User/add_address', params).then((res) => {
      $getAddressList()
      message.success(res.msg)
    })
  }
  // 修改地址
  const $onClickEditAddrerss = (params) => {
    let token = Cookies.get('token')
    let editorParams = Qs.stringify({
      token,
      channel: 1,
      ...editorAddressInfo,
      ...params
    })
    axios.post('User/edit_address', editorParams).then((res) => {
      message.success(res.msg)
      $getAddressList()
    })
  }

  // 删除地址
  const deleteAddressHandel = (e) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...e
    })
    axios.post('User/del_address', params).then((res) => {
      message.success(res.msg)
      $getAddressList()
    })
  }

  // 获取地址列表
  const $getAddressList = useCallback(
    () => {
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        token
      })
      axios.post('User/addressList', params).then((res) => {
        setAddressList(res.result)
      })
    },
    []
  )

  return (
    <div className="shippingAddress">
      <div className="shipping-address">
        {/* 收货地址 */}
        <div className="address">
          <span className="my-address">
            已保存收藏地址(地址最多20条，还能保存19条)
          </span>
          <div className="add-address">
            <div type="primary" onClick={() => setShow(true)}>
              +添加地址
            </div>
            <Modal
              visible={show}
              footer={null}
              onCancel={handleCancel}
              centered
              width="700px"
              className="add-editor-modal"
            >
              <div className="add-address-content">
                <Form
                  form={form}
                  onFinish={onFinish}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 18 }}
                >
                  <Form.Item label="地址信息" colon={false}></Form.Item>
                  <Form.Item
                    label="收货人"
                    className="consignee"
                    name="consignee"
                    rules={[
                      {
                        required: true,
                        message: '收货人不能为空',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="手机号码" className="phone" name="mobile" rules={[
                    () => ({
                      validator (rule, value) {
                        if (value && (/^1(3|4|5|6|7|8|9)\d{9}$/.test(value))) {
                          return Promise.resolve()
                        }
                        return Promise.reject('请检查手机号是否正确!')
                      }
                    })
                  ]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="所在地区"
                    className="consignee"
                    name="event"
                    rules={[
                      {
                        required: true,
                        message: '所在地区不能为空',
                      },
                    ]}
                  >
                    <AreaSelect
                      type="all"
                      level={2}
                      data={pcaa}
                      defaultArea={addressArea}
                    />
                  </Form.Item>

                  <Form.Item
                    label="详细地址"
                    className="consignee"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: '详细地址不能为空',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>

                  <Checkbox
                    onChange={onChange}
                    id="default-address"
                    name="is_default"
                  >
                    设置为默认地址
                  </Checkbox>
                  <Button
                    className="submit-btn"
                    type="primary"
                    htmlType="submit"
                  >
                    确定
                  </Button>
                  <Button
                    className="call-off"
                    type="primary"
                    onClick={() => setShow(false)}
                  >
                    取消
                  </Button>
                </Form>
              </div>
            </Modal>
          </div>
        </div>
        {/* 内容 */}
        <div className="shipping">
          <ul className="shipping-title">
            <li>收件人</li>
            <li>地址</li>
            <li>联系方式</li>
            <li>操作</li>
          </ul>
          {Array.isArray(addressList) &&
            addressList.map((element, index) => {
              return (
                <ul className="shipping-title shipping-name" key={index}>
                  <li>{element.consignee}</li>
                  <li>
                    {element.province_name}
                    {element.city_name}
                    {element.district_name}
                    {element.address}
                  </li>
                  <li>{element.mobile}</li>
                  <li className="edit">
                    <span
                      onClick={() => editShippingAddress(element)}
                      className="shipping-edit"
                    >
                      编辑
                    </span>
                    <span className="shipping-delete" onClick={() => deleteAddressHandel(element)}>删除</span>
                    {element.is_default === 1 ? (
                      <span className="shipping-delete">默认地址</span>
                    ) : (
                        <span className="shipping-delete" onClick={() => { onClickSetDefaultAddress(element.address_id) }}>设为默认地址</span>
                      )}
                  </li>
                </ul>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default ShippingAddress
