import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import Lnk from '@/components/lnk'
import Empty from '@/components/empty'

const MyIntegral = () => {
  document.title = '我的积分'
  
  const [integral, setIntegral] = useState({});
  const [integralTab, setIntegralTab] = useState(1) //切换积分规则
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      page: 1
    })
    axios.post('User/my_pointLog',params).then((res) => {
      setIntegral(res.result)
    })
  }, [])

  return (
    <div className='my-integral'>
      <div className='my-integral-content'>
        {/* 头部 */}
        <div className="my-integral-head">
          <div className="available-integral">
            <p className="integral-title">可用积分</p>
            <p className="integral-num">{integral.can_point}</p>
          </div>
          <div className="freezing-integral">
            <p className="integral-title">冻结积分</p>
            <p className="integral-num">0</p>
          </div>
          <div className="details-integral">
            <p className="points-mall">
              <Lnk className="integral-style" target="_blank" to="/integral-mall">积分商城</Lnk>
            </p>
            <p className="details-integral-rule" onClick={() => {setIntegralTab(0)}}>
              积分规则
            </p>
            <p className="points-mall">
              <Lnk className="integral-style" target="_blank" to="/manage/exchange-records">兑换记录</Lnk>
            </p>
          </div>
        </div>
        {/* 积分规则 */}
        <div className="my-integral-rule" style={integralTab === 1 ? {display: 'none'} : {display: 'block'}}>
          <div className="rule-title">
            <span>积分规则</span>
          </div>
          <div className="detailed-content">
            <Empty text="暂无规则" className="status" />
          </div>
        </div>
        {/* 积分明细 */}
        <div className="my-integral-detailed" style={integralTab === 1 ? {display: 'block'} : {display: 'none'}}>
          <div className="detailed-title">
            <span>积分明细</span>
            <span>积分明细说明</span>
          </div>
          <div className="detailed-nav">
            <div className="nav-date">日期</div>
            <div className="nav-source">来源</div>
            <div className="nav-integral">积分</div>
          </div>
          {
            Array.isArray(integral.withdrawals_list) && integral.withdrawals_list.map((element) => {
              return (
                <div className="detailed-nav" key={element.log_id}>
                  <div className="nav-date">{element.change_time}</div>
                  <div className="nav-source">{element.category_name}</div>
                  <div className="nav-integral nav-pay">{element.pay_points}</div>
                </div>
              )
            })
          }
          {(!Array.isArray(integral.withdrawals_list) || !integral.withdrawals_list.length) && (
            <div className="detailed-content">
              <Empty text="暂无明细" className="status" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MyIntegral