import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import { API } from '../../api/config'

import { Tabs, Form, Input, Button, message, Radio, Upload } from 'antd';
import 'moment/locale/zh-cn';
// import locale from 'antd/es/locale/zh_CN';

const Information = (props) => {
  document.title = '我的资料'

  const [form] = Form.useForm()
  const { TabPane } = Tabs;
 
  const validateMessages = {
    types: {
      email: 'email不正确',
    },
  };
  
  const [ information, setInformation ] = useState('')
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
    })
    axios.post('User/pcEditUser', params).then((res) => {
      setInformation(res.result)
      form.setFieldsValue({
        ...res.result
      })
    })
  }, [form])

    const $onFinish = (event) => {
      let token = Cookies.get('token')
      let params = Qs.stringify({
      channel: 1,
      token,
      act: 'edit',
      username: '',
      province: '',
      city: '',
      district: '',
      marriage: '',
      income: '',
      id_card: '',
      education: '',
      industry: '',
      year: '',
      month: '',
      day: '',
      sex: '',
      job: '',
      mobile: '',
      email: '',
      qq: '',
      ...event
    })

    axios.post('User/pcEditUser', params).then((res) => {
      message.success(res.msg)
    })
    .then((res) => {
      let token =Cookies.get('token')
      let p = Qs.stringify({
        channel: 1,
        token,
      })
      axios.post('User/pcUserInfo', p)
    })
    }
  const $onFinish_img = (event) => {
    let token = Cookies.get('token')
    event.head_pic=imageUrl
    let params = Qs.stringify({
      channel: 1,
      token,
      act: 'edit',
      head_pic: '',
      ...event
    })

    axios.post('User/pcEditUser', params).then((res) => {
      message.success(res.msg)
    })
        .then((res) => {
          let token =Cookies.get('token')
          let p = Qs.stringify({
            channel: 1,
            token,
          })
          axios.post('User/pcUserInfo', p)
        })
  }
  //   useEffect(() => {
  //   let token = Cookies.get('token')
  //   let params = Qs.stringify({
  //     channel: 1,
  //     token,
  //   })
  //   axios.post('User/selectJobEducationIncome', params).then((res) => {
  //     console.log(res);
  //     setInformation(res.result)
  //     form.setFieldsValue({
  //       ...res.result
  //     })
  //   })
  // }, [form])

  const [imageUrl, setImageUrl] = useState('')
    function beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    }
    const handleChange = info => {
      if (info.file.status === 'done') {
        let url = info.file.response.result || ''
        setImageUrl(url)
      }
    };
 
  return (
    
    <div className="information">
      <div className="information-content">
        <Tabs className="content">
          <TabPane tab="基本资料" key="1" className="information-top">
            <div className="form-content">
              <Form 
                form={form}
                validateMessages={validateMessages}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 8 }}
                onFinish={$onFinish}
              >
                {/* 用户头像 */}
                <Form.Item label="用户头像">
                  <img src={information.head_pic} alt="" className="head"/>
                </Form.Item>
                {/* 昵称 */}
                <Form.Item name='nickname' label="昵称">
                  <Input />
                </Form.Item>
                {/* 性别 */}
                <Form.Item label="性别" name='sex' >
                  <Radio.Group>
                    <Radio value='0'>男</Radio>
                    <Radio value='1'>女</Radio>
                    <Radio value='2'>保密</Radio>
                  </Radio.Group>
                </Form.Item>

                {/* 出生日期 */}
                {/*<Form.Item label="出生日期" name='birthday' size={300}>*/}
                {/*  <ConfigProvider locale={locale} size={1000}>*/}
                {/*    <DatePicker bordered={false}*/}
                {/*      // mode="year"*/}
                {/*      // placeholder="请选择年份"*/}
                {/*    />*/}
                {/*  </ConfigProvider>*/}
                {/*</Form.Item>*/}

                {/* 职业 */}
                <Form.Item label="职业" name='job'>
                  <Input  />
                </Form.Item>
                {/* 手机号 */}
                <Form.Item label="手机号" name='mobile'>
                  <Input  />
                </Form.Item>
                {/* 邮箱 */}
                <Form.Item name='email' label="邮箱" rules={[{ type: 'email' }]}>
                  <Input value={information.email} />
                </Form.Item>
                {/* QQ */}
                <Form.Item label="QQ" name='qq'>
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button className="submit" type="primary" htmlType="submit">
                    保存
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </TabPane>
          <TabPane tab="头像照片" key="2" className="information-top">
            {/* 头像照片 */}
            <Form onFinish={$onFinish_img}>
	          <Form.Item name="head_pic">
              <div className="head-picture">
                <div className="recommend">推荐头像</div>
                <p className="docs">仅支持JPG、GIF、PNG、JPEG、BMP格式,文件小于4M</p>
	          	  <Upload
                  showUploadList={false}
                  action={API + "User/upload"}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl
                   ? 
                   (
                     <div className="head-portrait">
                       <img src={imageUrl} style={{ width: '100%' }} alt="图片"/> 
                     </div>
                   )
                   : 
                    (
                      <div className="head-portrait">
                        <img src={information.head_pic} alt=""/>
                      </div>
                    )
                  }
                </Upload>

                <Form.Item>
                  <Button className="submit" type="primary" htmlType="submit">
                    保存
                  </Button>
                </Form.Item>
              </div>
	          </Form.Item>
            </Form>
          </TabPane>
          
        </Tabs>
        
      </div>
    </div>
  )
}

export default Information