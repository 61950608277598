import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'

const ExchangeRecordsDetails = (props) => {
  document.title = '查看详情页'

  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [contentDetails, setContentDetails] = useState([])

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...propsParams,
    })
    axios.post('Integral/integralOrderDetail', params).then((res) => {
      console.log('res', res.result)
      setContentDetails(res.result.order_info)
    })
  }, [propsParams]);
  return (
    <div className="record-details">
      <div className="record-details-information">
        {/* 订单信息 */}
        <div className="details-number">
          <span>下单时间: {contentDetails.add_time}</span>
          <span>订单号: {contentDetails.order_sn}</span>
        </div>
        {/* 收货信息 */}
        <div className="goods-information">
          <div className="goods-left">
            <p>收货人 :  {contentDetails.consignee}</p>
            <p>联系方式 :  {contentDetails.mobile}</p>
            <p>收货地址 :  {contentDetails.province}{contentDetails.address}</p>
            <p>配送方式 :  普通配送，快递包邮</p>
          </div>
          <div className="goods-right">
            {
              contentDetails.pay_price === '0.00'
              ?
              <p>商品合计 :  {contentDetails.integral_price}积分</p>
              :
              <p>商品合计 :  {contentDetails.integral_price}积分 + ￥ {contentDetails.pay_price}</p>
            }
            <p>运费 :  ￥{contentDetails.shipping_price}</p>
            <p>状态 :  {contentDetails.order_status_desc}</p>
          </div>
        </div>
        {/* 收货内容 */}
        <div className="shop-name">
          {/* 商品标题 */}
          <div className="shop-content">
            <span>商品名称</span>
            <span>单价</span>
            <span>数量</span>
            <span>实付</span>
            <span>收货人</span>
            <span>状态</span>
          </div>
          {/* 商品内容 */}
          {
            Array.isArray(contentDetails.integral_list) && contentDetails.integral_list.map((el) => {
              return (
                <div className="specific-shop" key={el.rec_id}>
                  <div className="shop-pic">
                    <img src={el.original_img} alt=""/>
                    <span>{el.integral_name}</span>
                  </div>
                  <div className="shop-other shop-price">
                    <p className="price-top">￥{el.integral_price}</p>
                    <p>{el.member_integral_price}积分</p>
                  </div>
                  <p className="shop-other">{el.integral_num}</p>
                  <div className="shop-other shop-price">
                    <p className="price-top">￥{el.integral_price}</p>
                    <p>{el.member_integral_price}积分</p>
                  </div>
                  <p className="shop-other">{contentDetails.consignee}</p>
                  <p className="shop-other">{contentDetails.order_status_desc}</p>
                </div>
              )
            })
          }
        </div>
        
      </div>
      {/* 留言 */}
        <div className="message">
          <div className="message-title">卖家留言</div>
          <div className="message-context">无</div>
        </div>
    </div>
  )
}

export default ExchangeRecordsDetails