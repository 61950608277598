import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import { Modal, Button, Form, Input, Select, message } from 'antd'

import Empty from '@/components/empty'

const Bind = () => {
  document.title = '绑定银行卡'
  const [form] = Form.useForm()
  const [show, setShow] = useState(false)
  const [empty, setEmpty] = useState({})
  const [bind, setBind] = useState({})
  const [editorCardInfo, setEditorCardInfo] = useState({})
  const [isEditor, setIsEditor] = useState(false)
  const { Option } = Select

  useEffect(() => {
    !show && setIsEditor(false)
  }, [show])

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('User/mybank', params).then((res) => {
      console.log(res.result)
      setBind(res.result)
    })
  }, [])

  useEffect((event) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...event
    })
    axios.post('User/bank_select', params).then((res) => {
      // console.log('...', res.result)
      setEmpty(res.result)
    })
  }, [])

  const onFinish = (e) => {
    let params = {
      ...e,
      channel: 1,
      token: Cookies.get('token')
    }
    isEditor ? $onClickEditorCard(params) : $onClickAddCard(params)
    setShow(false)
  }
  // 修改银行卡信息显示弹框
  const editBankCard = (e) => {
    setShow(true)
    setIsEditor(true)
    setEditorCardInfo(e)
    form.setFieldsValue({
      ...e
    })
  }

  // 新增银行卡
  const $onClickAddCard = (pa) => {
    let params = Qs.stringify(pa)
    axios.post('User/addBankcard', params).then((res) => {
      message.success(res.msg)
      axios.post('User/mybank', params).then((res) => {
        setBind(res.result)
      })
    })
  }

  // 编辑银行卡信息
  const $onClickEditorCard = (params) => {
    let token = Cookies.get('token')
    let temp = empty.filter((element) => element.id === params.bank_id)
    let editorParams = Qs.stringify({
      token,
      channel: 1,
      ...editorCardInfo,
      ...params,
      bank_name: temp[0].name
    })
    axios.post('User/editBankcard', editorParams).then((res) => {
      let token = Cookies.get('token')
      let editorParams = Qs.stringify({
        channel: 1,
        token
      })
      axios.post('User/mybank', editorParams).then((res) => {
        setBind(res.result)
      })
    })
  }

  // 删除银行卡信息
  const deleteBindHandel = (e) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      ...e
    })
    axios.post('User/delBankcard', params).then((res) => {
      axios.post('User/mybank', params).then((res) => {
        setBind(res.result)
      })
    })
  }

  return (
    <div className="bindCard">
      <div className="bindCard-content">
        {/* 我的银行卡 */}
        <div className="card">
          <span className="my-card">我的银行卡</span>
          <div className="add-card">
            <div type="primary" onClick={() => setShow(true)}>
              +添加银行卡
            </div>
            <Modal
              visible={show}
              footer={null}
              closable={false}
              centered
              width="700px"
              className="add-editor-modal"
            >
              <div className="popup">
                <Form
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 12 }}
                  onFinish={onFinish}
                  form={form}
                >
                  <Form.Item
                    label="银行卡号"
                    name="bank_card"
                    rules={[
                      {
                        required: true,
                        message: '银行卡号不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="银行"
                    name="bank_id"
                    rules={[
                      {
                        required: true,
                        message: '银行不能为空'
                      }
                    ]}
                  >
                    <Select optionFilterProp="children">
                      {Array.isArray(empty) &&
                        empty.map((element) => {
                          return (
                            <Option key={element.id}>{element.name}</Option>
                          )
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="开户支行"
                    name="opening_bank"
                    rules={[
                      {
                        required: true,
                        message: '开户支行不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="户名"
                    name="account_name"
                    rules={[
                      {
                        required: true,
                        message: '户名不能为空'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="预留手机号"
                    name="mobile"
                    rules={[
                      () => ({
                        validator(rule, value) {
                          if (value && /^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
                            return Promise.resolve()
                          }
                          return Promise.reject('请检查手机号是否正确!')
                        }
                      })
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Button
                    className="submit-btn"
                    type="primary"
                    htmlType="submit"
                  >
                    确定
                  </Button>
                  <Button
                    className="call-off"
                    type="primary"
                    onClick={() => setShow(false)}
                  >
                    取消
                  </Button>
                </Form>
              </div>
            </Modal>
          </div>
        </div>
        {/* 内容 */}
        <div className="bind">
          <ul className="bind-title">
            <li>银行卡号</li>
            <li>开户行</li>
            <li>户名</li>
            <li>预留手机号</li>
            <li>操作时间</li>
            <li>操作</li>
          </ul>
          {Array.isArray(bind) &&
            bind.map((element) => {
              return (
                <ul className="bind-title" key={element.id}>
                  <li>{element.hide_bank_card}</li>
                  <li>
                    {element.bank_name}
                    {element.opening_bank}
                  </li>
                  <li>{element.account_name}</li>
                  <li>{element.hide_mobile}</li>
                  <li>{element.edit_time}</li>
                  <li className="edit">
                    <span
                      onClick={() => editBankCard(element)}
                      className="bind-edit"
                    >
                      编辑
                    </span>
                    <span
                      className="bind-delete"
                      onClick={() => deleteBindHandel(element)}
                    >
                      删除
                    </span>
                  </li>
                </ul>
              )
            })}
          {(!Array.isArray(bind) || !bind.length) && (
            <div className="bind-status">
              <Empty text="暂无银行卡" className="status" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Bind
