import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Layout from './layout'
import KuJiaLe from '@/pages/kujiale'

function RouterConfig() {
  return (
    <Router>
      <Switch>
        <Route path="/kujiale/:dest" exact component={KuJiaLe} />
        <Route component={Layout} />
      </Switch>
    </Router>
  )
}

export default RouterConfig
