import React, {useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Lnk from '../../components/lnk'

import tu from '@/assets/images/card.png'
import card from '@/assets/images/card2.png'

const Cash = () => {
  document.title = '我的提现'
  const [cashDetails, setCashDetail] = useState({})

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('User/myExtension', params).then((res) => {
      console.log(res.result);
      setCashDetail(res.result)
    })
  }, []);
  return (
    <div className="cash">
      <div className="cash-content">
        {/* 我的钱包 */}
        <div className="wallet">我的钱包</div>
        {/* 信息 */}
        <div className="cash-user">
          {/* 用户信息 */}
          <div className="user-info">
            <div className="user-left">
              <div className="user-pic"><img src={cashDetails.head_pic} alt=""/></div>
              <p className="get">
                <Lnk target='_blank' to="/manage/withdrawal" className="smg">提现</Lnk>
              </p>
            </div>
            <div className="user-right">
              <p>账户姓名：{cashDetails.nickname}</p>
              <p>入驻日期：{cashDetails.reg_time}</p>
              <p>我的总额：<span className="money">{cashDetails.distribut_money}</span></p>
              <p>我的余额：<span className="money">{cashDetails.money}</span></p>
            </div>
          </div>
          {/* 购物积分 */}
          <div className="integral">
            <div className="integral-content">
              <div className="shop-integral">
                <p className="shop-word">购物积分:</p>
                <p className="shop-number">{cashDetails.pay_points}</p>
              </div>
              <div className="shop-money">
                <p className="shop-word">我的佣金:</p>
                <p className="shop-number">{cashDetails.share_num}</p>
              </div>
            </div>
          </div>
        </div>
        {/* 我的银行卡 */}
        <div className="bank-card">
          <div className="card-name">
            <img src={tu} alt="" className="name-msg"/>
            <p>我的银行卡</p>
          </div>
          <div className="my-card">
            <div className="card">
              <Lnk target='_blank' to="/manage/bind-card">
                <img src={card} alt="" className="card-msg"/>
                <p className="add-card">添加银行卡</p>
              </Lnk>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Cash