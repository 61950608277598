import React, { useState, useEffect } from 'react'
import Qs from 'qs'
import axios from '@/api'
import './index.scss'

import news1 from '@/assets/images/news1.png'
import news2 from '@/assets/images/news2.png'
import news3 from '@/assets/images/news3.png'
import news4 from '@/assets/images/news4.png'

import Lnk from '@/components/lnk'

const News = () => {
  const [newsList, setNewsList] = useState([])

  useEffect(() => {
    axios.post('Article/news_list', Qs.stringify({
      channel: 3
    })).then(res => {
      Array.isArray(res.result) && res.result.forEach((element, index) => {
        switch (index) {
          case 0:
            element.pic = news1
            break;
          case 1:
            element.pic = news2
            break;
          case 2:
            element.pic = news3
            break;
          case 3:
            element.pic = news4
            break;
          default:
        }
      })
      setNewsList(res.result)
    })
  }, [])

  return (
    <div className='news'>
      <div className='context'>
        <div className='news-title'>
          家的学问
        </div>
        <div className='news-list'>
          {
            newsList && newsList.map(element => {
              return (
                <div className='news-item' key={element.cat_id}>
                  <div className='news-cover'>
                    <img src={element.pic} alt='家的学问' />
                    {/* <div className='news-item-title'>{element.category}</div> */}
                  </div>
                  <div className='news-content'>
                    <div className='news-content-list'>
                      {
                        Array.isArray(element.list) && element.list.map(el => {
                          return (
                            <Lnk target='_blank' to={`/news-details?article_id=${el.article_id}`} key={el.article_id} className='news-content-item'>
                              {el.title}
                            </Lnk>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default News