import React, { useState } from 'react'
import { Form, Button, Col, Row, message, Input } from 'antd'
import axios from '@/api'
import Qs from 'qs'
import Cookie from 'js-cookie'
import './index.scss'

import Lnk from '@/components/lnk'

const Login = (props) => {
  document.title = '登录'

  const [active, setActive] = useState(0)
  const [code, setCode] = useState(0)
  const [codeStatus, setCodeStatus] = useState(false)
  const [phone, setPhone] = useState()
  const [phoneStatus, setPhoneStatus] = useState(false)

  // 发送手机验证码
  const $getPhoneCode = () => {
    if (!phoneStatus) {
      message.error('请输入正确的手机号')
      return
    }
    let params = Qs.stringify({
      mobile: phone,
      type: 2,
      channel: 3
    })
    axios
      .post('User/send_code', params)
      .then((res) => {
        setCodeStatus(true)
        $countDown()
      })
      .catch((e) => {
        message.error('发送手机验证码失败')
        console.log(e)
      })
  }

  // 倒计时
  const $countDown = () => {
    let count = 60
    let interval = setInterval(() => {
      count = count - 1
      setCode(count)
      if (count === 0) {
        clearInterval(interval)
      }
    }, 1000)
  }

  const $onFinish = (params) => {
    if (!active && !codeStatus) {
      message.warning('请先获取验证码')
      return
    }
    params.channel = 1
    axios
      .post('User/loginapi', Qs.stringify(params))
      .then((res) => {
        message.success(res.msg)
        if (res.token) {
          Cookie.set('token', res.token)
          let p = Qs.stringify({
            channel: 1,
            token: res.token,
          })
          axios.post('User/pcUserInfo', p).then((res) => {
            let role = res.result.type
            Cookie.set('role', role)
            if(role) {
              window.location.href = '/'
            } else {
              message.info('获取用户信息失败')
            }
          })
        }
      })
      .catch((e) => {
        message.error('登录失败')
        console.log(e)
      })
  }

  return (
    <div className="login">
      <div className="login-form">
        <div className="form-context">
          <div className="form-title">
            <span
              className={`${active === 0 ? 'active' : ''} quick`}
              onClick={() => setActive(0)}
            >
              快捷登录
            </span>
            <span
              className={`${active === 1 ? 'active' : ''} password`}
              onClick={() => setActive(1)}
            >
              密码登录
            </span>
          </div>
          <div className="form-content">
            <Form
              initialValues={{
                remember: true
              }}
              onFinish={$onFinish}
            >
              <Form.Item
                name="mobile"
                rules={[
                  () => ({
                    validator(rule, value) {
                      if (value && /^1(3|4|5|6|7|8|9)\d{9}$/.test(value)) {
                        setPhone(value)
                        setPhoneStatus(true)
                        return Promise.resolve()
                      }
                      setPhoneStatus(false)
                      return Promise.reject('请检查手机号是否正确!')
                    }
                  })
                ]}
              >
                <Input placeholder="请输入手机号" />
              </Form.Item>

              {!active ? (
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: '请输入验证码'
                    }
                  ]}
                >
                  <Row>
                    <Col className="form-col" span={12}>
                      <Input placeholder="请输入验证码" />
                    </Col>
                    <Col className="form-col" span={12}>
                      <Button
                        disabled={code ? true : false}
                        onClick={$getPhoneCode}
                        type="primary"
                        className="get-code"
                      >
                        {code ? `请${code}s后重试` : '获取验证码'}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              ) : (
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: '请输入密码'
                    }
                  ]}
                >
                  <Input type="password" placeholder="请输入密码" />
                </Form.Item>
              )}

              <Form.Item>
                <Button className="submit" type="primary" htmlType="submit">
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="switch">
          还没有账户？
          <Lnk target="_blank" to="/register">
            立即注册
          </Lnk>
        </div>
      </div>
    </div>
  )
}

export default Login
