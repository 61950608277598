const initialState = {
  isLoginModal: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW':
      return { isLoginModal: true }
    case 'HIDDEN':
      return { isLoginModal: false }
    default:
      return state
  }
}
