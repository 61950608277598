import React, { useState, useEffect, memo } from 'react'
import { message } from 'antd'
import axios from '@/api'
import './index.scss'

import logo from '@/assets/images/logo.png'
import qrCode from '@/assets/images/qr-code.png'
import Lnk from '../lnk'
import Cookie from 'js-cookie'

const Navigation = () => {
  const [searchValue, setSearchValue] = useState('')
  const [typeList, setTypeList] = useState([])
  let token = Cookie.get('token')
  const menuList = [
    {
      id: 0,
      name: '首页',
      path: '/'
    },
    {
      id: 1,
      name: '星推荐',
      path: '/recommend',
      child: [
        {
          id: 0,
          icon: 'icon-xueyuan-dakakecheng',
          text: '设计大咖',
          path: '/design-master'
        },
        {
          id: 1,
          icon: 'icon-renqi',
          text: '人气作品',
          path: '/popular-works'
        }
      ]
    },
    {
      id: 2,
      name: '全屋套餐',
      path: '/set-meal'
    },
    {
      id: 3,
      name: '品牌汇',
      path: '/brand'
    },
    {
      id: 4,
      name: '软装馆',
      path: '/soft'
    },
    {
      id: 5,
      name: '5D设计',
      path: '/design'
    }
  ]

  // 输入搜索内容
  const $onInputSearchValue = (e) => {
    e.persist()
    let value = e.target.value
    setSearchValue(value)
  }

  // 搜索
  const $onClickSearch = () => {
    if (!searchValue) {
      message.info('搜索内容不能为空')
      return
    }
    const w = window.open('about:blank')
    w.location.href = `/commodity-library?searchValue=${searchValue}`
  }

  useEffect(() => {
    axios
      .post('Goods/newGoodsCateList')
      .then((res) => {
        setTypeList(res.result)
      })
      .catch((e) => {
        console.log('error', e)
      })
  }, [])

  return (
    <div className="navigation">
      <div className="navigation-context context">
        <div className="navigation-context-top">
          <img className="logo" src={logo} alt="logo" />
          <div className="search">
            <input
              type="text"
              onInput={$onInputSearchValue}
              placeholder="床、沙发、餐桌、套餐定制...."
              autoComplete="off"
            />
            <div className="search-btn" onClick={$onClickSearch}>
              <i className="iconfont icon-search"></i> 搜索
            </div>
          </div>
          <div className="options">
            <button className="login-btn">
              <Lnk
                target="_blank"
                to={token ? '/manage/my-order?pid=0&cid=1' : '/login'}
              >
                <i className="iconfont icon-Userpersonalinform"></i>{' '}
                {token ? '个人中心' : '登录'}
              </Lnk>
            </button>
            <button className="car-btn">
              <Lnk target="_blank" to="/shop-car">
                <i className="iconfont icon-car"></i> 购物车
              </Lnk>
            </button>
            <div className="qr-code">
              <Lnk target="_blank" to="/manage/my-order?pid=0&cid=1">
                <i className="iconfont icon-QR-code"></i>
              </Lnk>
              <img src={qrCode} alt="二维码" />
            </div>
          </div>
        </div>
        <div className="navigation-context-bottom">
          <div className="all-type">
            <i className="iconfont icon-menu"></i>
            全部分类
            <div className="type-list-block">
              <div className="type-list">
                {typeList &&
                  typeList.map((element) => {
                    return (
                      <div className="nav-type-item" key={element.id}>
                        <div className="icon">
                          <Lnk
                            target="_blank"
                            to={`/commodity-library?cate_id=${element.id}`}
                          >
                            <img src={element.icon} alt="软装分类" />
                            {element.name}
                          </Lnk>
                        </div>
                        <div className="item">
                          {element.children &&
                            element.children.map((el) => {
                              return (
                                <div className="name" key={el.id}>
                                  <Lnk
                                    target="_blank"
                                    to={`/commodity-library?cate_id=${el.id}`}
                                  >
                                    {el.name}
                                  </Lnk>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
          <div className="menu-list">
            {menuList &&
              menuList.map((element) => {
                return (
                  <div className="menu-item" key={element.id}>
                    <Lnk
                      target="_blank"
                      to={element.path}
                      className="word-color"
                    >
                      {element.name}
                    </Lnk>
                    <div className="menu-item-box">
                      {element.child && (
                        <div className="menu-item-child">
                          {element.child.map((el) => {
                            return (
                              <div className="child-item" key={el.id}>
                                <Lnk target="_blank" to={el.path}>
                                  {el.text}
                                </Lnk>
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                    {/* {
                      element.child && <div className='menu-item-child'>
                        {
                          element.child.map(el => {
                            return (
                              <div className='child-item' key={el.id}>
                                <Lnk target='_blank' to={el.path}>
                                  {el.text}
                                </Lnk>
                              </div>
                            )
                          })
                        }
                      </div>
                    } */}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Navigation)
