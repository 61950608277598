import React,{ useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'

import { Pagination } from 'antd'
import BrandList from '@/components/brand-list'
import priceUp from '../../assets/images/red-top1.png'
import priceDown from '../../assets/images/red-down.png'
import sellUp from '../../assets/images/green-top.png'
import sellDown from '../../assets/images/green-down.png'
import banner from '@/assets/images/brand_info_banner.jpg'

const BrandDetails = (props) => {
  document.title = '品牌汇'

  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [brandInfo, setBrandInfo] = useState({});
  const [softList, setSoftList] = useState([])
  const [cateList, setCateList] = useState([]) //分类
  const [cateListMore, setCateListMore] = useState([]) //更多分类
  const [changeCateList, setChangeCateList] = useState() //切换
  const [selectCate, setSelectCate] = useState(0) //点击高亮

  const [cateType, setCateType] = useState('0') //默认选中分类的ID
  const [seriesList, setSeriesList] = useState([]) //系列
  const [seriesType, setSeriesType] = useState('0') //默认选中系列的ID
  const [keyType, setKeyType] = useState('comment_count') //默认选中排序的ID
  const [sortType, setSortType] = useState('asc') 

  const [priceImg, setPriceImg] = useState(0)
  const [sellImg, setSellImg] = useState(0)

  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(1)

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 3,
      token,
      ...propsParams
    })
    axios.post('Index/brandInfo',params).then((res) => {
      let cateId = res.result.cate[0].cate_id
      setCateType(cateId)

      let id = res.result.series[0].series_item_id
      setSeriesType(id)

      setBrandInfo(res.result.info)
      let temp = []
      res.result.cate.forEach((el,index) => {
        // console.log('index', index)
        if (index < 14) {
          temp.push(el)
        }
      })
      setCateList(temp)
      setChangeCateList(temp)
      setCateListMore(res.result.cate)
      setSeriesList(res.result.series)
    })
  }, [propsParams])

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 3,
      token,
      ...propsParams,
      cate_id: cateType,
      series_item_id: seriesType,
      page: current,
      key: keyType,
      sort: sortType
    })
    axios.post('Index/brandGoods', params).then((res) => {
      setTotal(res.result.pages)
      setSoftList(res.result.goods_list || [])
    })
  }, [current, cateType, seriesType, keyType, sortType, propsParams]);

  // 更多
  const $onClickMore = (flag) => {
    if (flag === 1) {
      setSelectCate(0)
      setChangeCateList(cateList)
    } else {
      setSelectCate(1)
      setChangeCateList(cateListMore)
    }
  }
  return (
    <div className="brand-details">
      <div className="context">
        {/* 顶部 */}
        <div className="details-top1">
          {/* banner图 */}
          <div className="banner-img">
            <img src={brandInfo.store_avatar} alt=""/>
          </div>
          {/* 公司介绍 */}
          <div className="details-introduce">
            <div className="details-introduce-title">
              <img src={brandInfo.store_logo} alt=""/>
            </div>
            <div className="details-introduce-content">
              <div className="details-introduce-big">{brandInfo.store_info}</div>
              <div className="details-introduce-small">{brandInfo.store_description}</div>
            </div>
          </div>
        </div>

        {/* 详情头部 */}
        {/* <div className="details-top"> */}
          {/* 头部左侧 */}
          {/* <div className="details-img">
            <img src={brandInfo.store_avatar} alt=""/>
          </div> */}
          {/* 头部右侧 */}
          {/* <div className="details-introduce">
            <div className="details-introduce-top">
              <div className="top-img">
                <img src={brandInfo.store_logo} alt=""/>
              </div>
              <div className="top-title-info">
                <p className="big-title">{brandInfo.store_name}</p>
                <p className="small-title">在售商品{brandInfo.total_goods}个</p>
              </div>
            </div>
            <div className="details-introduce-bottom">
              <p>{brandInfo.store_description}</p>
            </div>
          </div> */}
        {/* </div> */}
      </div>
        
        {/* 分类 */}
      <div className="context">
        <div className="brand-cate-list">
          <div className="brand-cate">
            <span className='brand-cate-label'>分类</span>
            <ul className="brand-cate-item">
              {
                Array.isArray(changeCateList) && changeCateList.map((element) => {
                  return (
                    <li key={element.cate_id} className={`brand-cate-name ${element.cate_id === cateType ? 'active' : ''}`} onClick={() => setCateType(element.cate_id)}>
                      {element.name}
                    </li>
                  )
                })
              }
              {selectCate === 1 ?
                (<li onClick={() => $onClickMore(1)} className="brand-cate-name more-btn">收起<i className="iconfont icon-down-copy-copy"></i></li>)
                :
                (<li onClick={() => $onClickMore(0)} className="brand-cate-name more-btn">更多<i className="iconfont icon-down-copy"></i></li>)
              }
              
            </ul>
          </div>
        </div>

        {/* 系列 */}
        {/* <div className="brand-series-list">
          <div className="brand-series">
            <span className='brand-series-label'>系列</span>
            <ul className="brand-item">
              {
                Array.isArray(seriesList) && seriesList.map((element) => {
                  return(
                      <li key={element.series_item_id} className={`brand-item-name ${element.series_item_id === seriesType ? 'active' : ''}`} onClick={() => setSeriesType(element.series_item_id)}>
                        {element.item_name}
                      </li>
                  )
                })
              }
            </ul>
          </div>
        </div> */}

        {/* 排序 */}
        <div className="brand-sort-list">
          <div className="brand-sort">
            <span className='brand-sort-label'>排序</span>
            <ul className="brand-sort-item">
              <li className={`${'comment_count' === keyType ? 'active' : ''}`} onClick={() => setKeyType('comment_count')}>综合</li>
              <li className={`${'sales_sum' === keyType ? 'active' : ''}`} onClick={() => {
                setKeyType('sales_sum')
                sellImg === 0 ? setSellImg(1) : setSellImg(0)
                sellImg === 0 ? setSortType("asc") : setSortType("desc")
              }}>
                销量
                <img src={`${sellImg === 0 ? priceUp : priceDown}`} className="arrow-green-img" alt='' />
              </li>
              <li className={`${'shop_price' === keyType ? 'active' : ''}`} onClick={() => {
                setKeyType('shop_price')
                priceImg === 0 ? setPriceImg(1) : setPriceImg(0)
                priceImg === 0 ? setSortType("asc") : setSortType("desc")
              }}>价格<img src={`${priceImg === 0 ? sellUp : sellDown}`} className="arrow-img" alt='' /></li>
            </ul>
            {
            
            }
          </div>
        </div>

        {/* 内容 */}
        <BrandList list={softList} />
        <Pagination className='soft-page' showSizeChanger={false} current={current} total={total * 10} onChange={(page) => {
          setCurrent(page)
          window.scrollTo(0, 0)
        }} />
      </div>
    </div>
  )
}

export default BrandDetails