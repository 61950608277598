import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import { Tabs } from 'antd';
import Empty from '@/components/empty'
import Lnk from '@/components/lnk'


const MyPlan = () => {
  document.title = '我的方案'

  const { TabPane } = Tabs;
  const [designPlan, setDesignPlan] = useState({})
  const [hoseType, setHouseType] = useState({})
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 4,
      status: 1,
      num: 100,
      start: 0,
    })
    axios.post('Designer/getDesign', params).then((res) => {
      console.log('设计师方案',res.result.result)
      setDesignPlan(res.result.result)
    })
  }, []);
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 4,
      status: 0,
      num: 100,
      start: 0,
    })
    axios.post('Designer/getDesign', params).then((res) => {
      console.log('户型',res.result.result)
      setHouseType(res.result.result)
    })
  }, []);
  return (
    <div className="myPlan">
      <div className="my-plan">
        <Tabs defaultActiveKey="1">
          <TabPane tab="我的方案" key="1">
            <div className="design-plan">
              {
                Array.isArray(designPlan) && designPlan.map((element,index) => {
                  return (

                      <div className="plan-item"  key={index}>
                        <div className="plan-item-img">
                          <img src={element.planPic} alt=""/>
                        </div>
                        <div className="plan-item-title">{element.name}</div>
                        <div className="plan-item-detail">
                          <span>{element.specName}</span>
                          <span className="item-time">{element.modifiedTime}</span>
                        </div>
                        <div className="plan-item-renovation">
                          <div className="go-renovation">
                            <Lnk className="go-renovation-style" target="_blank" to="">去装修</Lnk>
                          </div>
                          <div className="renovation-more">•••</div>
                        </div>
                      </div>
                  )
                })
              }
              {(!Array.isArray(designPlan) || !designPlan.length) && (
                <div className="not-yet">
                  <Empty text="暂无方案" className="status" />
                </div>
              )}
            </div>
          </TabPane>
          {/* <TabPane tab="我的户型" key="2">
            <div className="design-plan">
              {
                Array.isArray(hoseType) && hoseType.map((element,index) => {
                  return (
                      <div className="plan-item"  key={index}>
                        <div className="plan-item-img">
                          <img src={element.planPic} alt=""/>
                        </div>
                        <div className="plan-item-title">{element.name}</div>
                        <div className="plan-item-detail">
                          <span>{element.specName}</span>
                          <span className="item-time">{element.modifiedTime}</span>
                        </div>
                        <div className="plan-item-renovation">
                          <div className="go-renovation">
                            <Lnk className="go-renovation-style" target="_blank" to="">改户型</Lnk>
                          </div>
                          <div className="renovation-more">•••</div>
                        </div>
                      </div>
                  )
                })
              }
              {(!Array.isArray(hoseType) || !hoseType.length) && (
                <div className="not-yet">
                  <Empty text="暂无户型" className="status" />
                </div>
              )}
            </div>
          </TabPane> */}
        </Tabs>
      </div>
    </div>
  )
}

export default MyPlan