import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import Empty from '@/components/empty'
import Lnk from '@/components/lnk'
import { message } from 'antd'

const Invoice = (props) => {
  document.title = '我的增值税发票'
  const [invoiceInformation, setInvoiceInformation] = useState({})
  useEffect((e) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      type: 4,
      ...e
    })
    axios.post('User/invoice', params).then((res) => {
      console.log(res.result)
      setInvoiceInformation(res.result)
    })
  }, [])

  // 删除发票信息
  const deleteInvoiceInformation = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      invoice_id: id,
    })
    axios.post('User/delInvoice', params).then((res) => {
      message.success(res.msg)
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        token,
        type: 4,
      })
      axios.post('User/invoice',params).then((res) => {
        setInvoiceInformation(res.result)
      })
    })
  }

  return (
    <div className="invoice">
      <div className="vat-invoice">
        {/* 我的发票 */}
        <div className="my-invoice">我的发票</div>
        <div className="invoice-content">
          <ul className="invoice-title">
            <li>抬头</li>
            <li>税号</li>
            <li>注册地址</li>
            <li>开户行</li>
            <li>银行账户</li>
            <li>操作</li>
          </ul>
          <div className="invoice-status">
            {Array.isArray(invoiceInformation) &&
            invoiceInformation.map((element) => {
              return (
                <ul className="bind-title" key={element.id}>
                  <li>{element.company || '---'}</li>
                  <li>{element.tax_nums || '---'}</li>
                  <li>{element.address || '---'}</li>
                  <li>{element.bank_name || '---'}</li>
                  <li>{element.bank_cardid || '---'}</li>
                  <li className="edit">
                    <Lnk to={`/manage/edit-invoice?invoice_id=${element.id}`} className="edit">
                      <span 
                        className="bind-edit"
                      >
                        编辑
                      </span>
                    </Lnk>
                    <span
                      className="bind-delete"
                      onClick={() => deleteInvoiceInformation(element.id)}
                    >删除</span>
                  </li>
                </ul>
              )
            })}
            {(!Array.isArray(invoiceInformation) || !invoiceInformation.length) && (
              <div>
                <Empty text="暂无发票" className="status" />
              </div>
            )}
          </div>
          <div className="add-invoice">
            <Lnk to="/manage/invoice-information" className="add-invoiceInformation">
              <p>新增发票</p>
            </Lnk>
          </div>
          
        </div>
      </div>
    </div>
  )
}
export default Invoice
