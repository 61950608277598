import React, {useRef} from 'react'
import Swiper from 'react-id-swiper'
import 'swiper/swiper.scss';
import propTypes from 'prop-types'
import './index.scss'

const MousewheelControl = (props) => {
  const ref = useRef(null);
  const pageUp = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
    }
  }
  const pageDown = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev();
    }
  }
  const params = {
    direction: 'vertical',
    slidesPerView: 3,
    spaceBetween: 30,
    mousewheel: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  }

  return (
    <div className="banner-test">
      <i className="iconfont icon-top shang" onClick={pageDown}></i>
      <Swiper {...params} ref={ref}>
        {props.list.map((element, index) => {
          return (
            <div className="swiper-item" key={index}>
              <div className="swiper-item-context">
                {/* <img onClick={() => props.onChange(index)} src={element} alt="icon" /> */}
                <img className={props.activeIndex === index ? 'active': ''} onClick={() => props.onChange(index)} src={element} alt="icon"  />
              </div>
            </div>
          )
        })}
      </Swiper>
      <i className="iconfont icon-bottom xia" onClick={pageUp}></i>
    </div>
  )
}

MousewheelControl.propTypes = {
  list: propTypes.array,
  activeIndex: propTypes.number
}

MousewheelControl.defaultProps = {
  list: [],
  activeIndex: 0
}

export default MousewheelControl
