import React, { useState, useEffect } from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import Empty from '@/components/empty'

import Lnk from '@/components/lnk'
import { Tabs, } from 'antd';


const Collection = () => {
  document.title = '我的收藏'
  const { TabPane } = Tabs;
  const [collectList, setCollectList] = useState({});
  const [designerList, setDesignerList] = useState({})
  const [worksCollectionList, setWorksCollectionList] = useState({});
  const [goodList, setGoodsList] = useState(0) //商品收藏个数
  const [worksCollection, setWorksCollection] = useState(0) //作品收藏个数
  const [designerFollow, setDesignerFollow] = useState(0) //设计师关注个数
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token
    })
    axios.post('User/collect_list', params).then((res) => {
      console.log('商品收藏',res.result);
      setCollectList(res.result)
      setGoodsList(res.result.goodsList.length)
    })
  }, []);
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      page: 1
    })
    axios.post('User/collectProductList',params).then((res) => {
      console.log('作品收藏',res.result);
      setWorksCollectionList(res.result)
      setWorksCollection(res.result.productList.length)
    })
  }, []);
  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      num:6
    })
    axios.post('User/collectDesignerList',params).then((res) => {
      console.log('设计师关注',res.result);
      setDesignerList(res.result)
      setDesignerFollow(res.result.list.length)
    })
  }, []);

  // 取消商品收藏
  const onClickCancel = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      collect_id: id
    })
    axios.post('User/delGoodsCollect',params).then((res) => {
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        token
      })
      axios.post('User/collect_list', params).then((res) => {
        console.log('商品收藏',res.result);
        setCollectList(res.result)
        setGoodsList(res.result.goodsList.length)
      })
    })
  }
  // 取消作品收藏
  const onClickcollectProductList = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      id: id
    })
    axios.post('Product/collectProduct',params).then((res) => {
      // message.success(res.result)
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        token,
        num:6
      })
      axios.post('User/collectProductList',params).then((res) => {
        console.log('作品收藏',res.result);
        setWorksCollectionList(res.result)
        setWorksCollection(res.result.productList.length)
      })
    })
  }
  // 取消设计师关注
  const onClickCancelAttention = (id) => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      channel: 1,
      token,
      collect_id: id
    })
    axios.post('User/delDesignerCollect',params).then((res) => {
      // message.success(res.result)
      let token = Cookies.get('token')
      let params = Qs.stringify({
        channel: 1,
        token,
        num:6
      })
      axios.post('User/collectDesignerList',params).then((res) => {
        console.log('设计师关注',res.result);
        setDesignerList(res.result)
        setDesignerFollow(res.result.list.length)
      })
    })
  }
  return (
    <div className="collection">
      <div className="collection-content">
        <Tabs defaultActiveKey="1" className="content">
          <TabPane tab={`商品收藏(${goodList})`} key="1" className="content-top">
             <div className="commodity-collection">
                <div className="commodity-collection-content">
                  {
                    Array.isArray(collectList.goodsList) && collectList.goodsList.map((element,index) => {
                      return (
                        <ul className="collection-detail" key={index}>
                          <li>
                            <p className="shop-img">
                              <img src={element.pic} alt=""/>
                            </p>
                            <p className="shop-name">{element.goods_name}</p>
                            <p className="shop-money">￥{element.shop_price}</p>
                            <p className="shop-btn">
                             <span className="cancel" onClick={() => onClickCancel(element.collect_id)}>取消收藏</span>
                             <span className="buy">
                               <Lnk target="_blank" to={`/commodity-details?goods_id=${element.goods_id}`}  className="buy-color">
                                  去购买
                                </Lnk>
                             </span>
                            </p>
                          </li>
                        </ul>
                      )
                    })
                  }
                  {(!Array.isArray(collectList.goodsList) || !collectList.goodsList.length) && (
                    <div>
                      <Empty text="暂无收藏" className="status" />
                    </div>
                  )}
                </div>
             </div>
          </TabPane>
          
          <TabPane tab={`作品收藏(${worksCollection})`} key="2" className="content-top">
             <div className="works-collection">
               <div className="works-collection-content">
                 {
                   Array.isArray(worksCollectionList.productList) && worksCollectionList.productList.map((element,index) => {
                     return (
                       <div className="works" key={index}>
                         <div className='works-item-top'>
                            <Lnk className='works-item-img' target='_blank' to={`/home-design-details?id=${element.product_id}`}>
                              <img src={element.image} alt='作品' />
                            </Lnk>
                          </div>
                          <div className='works-item-bottom'>
                            <div className='user-info'>
                              <img className='head' src={element.head_pic} alt='头像' />
                              <span className='username'>{element.nickname}</span>
                            </div>
                            <div className='commodity-option'>
                              <span>{element.title}</span>

                            </div>
                            <div className='room-info'>
                              <span>{element.product_style}</span>
                              <span>{element.skills}</span>
                              <span className='middle'>{element.room_type}</span>
                              <span>{element.room_size}㎡</span>
                              <span className='middle' onClick={() => onClickcollectProductList(element.product_id)}>取消收藏</span>
                            </div>
                          </div>
                       </div>
                     )
                   })
                 }
                 {(!Array.isArray(worksCollectionList.productList) || !worksCollectionList.productList.length) && (
                    <div>
                      <Empty text="暂无收藏" className="status" />
                    </div>
                  )}
               </div>
             </div>
          </TabPane>

          <TabPane tab={`设计师关注(${designerFollow})`} key="3" className="content-top">
             <div className="designer">
                <div className="designer-content">
                    {
                      Array.isArray(designerList.list) && designerList.list.map((element,index) => {
                       return (
                         <div className="designer-follow" key={index}>
                           <div className="designer-top">
                             <div className="top-img">
                               <img src={element.head_pic} alt=""/>
                             </div>
                           </div>
                           <div className="designer-bottom">
                             <div className="designer-word">
                               <span className="words-left">{element.nickname}</span>
                               <span className="words-right">{element.level}</span>
                             </div>
                             <div className="other">
                               <span className="other-left">其他风格</span>
                               <span className="other-center">作品({element.works_num})</span>
                               <span className="right" onClick={() => onClickCancelAttention(element.collect_id)}>取消关注</span>
                             </div>
                           </div>
                         </div>
                       )
                      })
                    }
                    {(!Array.isArray(designerList.list) || !designerList.list.length) && (
                      <div>
                        <Empty text="暂无关注" className="status" />
                      </div>
                    )}
                </div>
             </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default Collection
