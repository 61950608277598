import React, {useState, useEffect} from 'react'
import './index.scss'
import queryString from 'querystring'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'

import {
  Form,
  Input,
  Button,
  Select,
} from 'antd';
const EditInvoice = (props) => {

  const { Option } = Select
  const [form] = Form.useForm()
  const [invoiceType, setInvoiceType] = useState()
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))

  const onChangeInvoiceType = (value) => {
    setInvoiceType(value)
  }
  

  useEffect(() => {
    let token = Cookies.get('token')
    let params = Qs.stringify({
      ...propsParams,
      channel: 1,
      token,
    })
    console.log('>>>',propsParams);
    axios.post('/User/invoiceDetail', params)
    .then((res) => {
      setInvoiceType(res.result.type)
      form.setFieldsValue({
        ...res.result,
      })
    })
  }, [propsParams, form])
  const onFinish = (event) => {
    let token = Cookies.get('token')
    console.log(propsParams)
    let params = Qs.stringify({
      channel: 1,
      token,
      ...propsParams,
      tax_nums: '',
      address: '',
      mobile: '',
      bank_name: '',
      bank_cardid: '',
      ...event,
    })
    axios.post('User/editInvoice',params).then((res) => {
      props.history.push('/manage/vat-invoice?pid=4&cid=15')
    })
  }
  
  return (
      <div className="editInvoice">
        <div className="edit-invoice">
          <div className="edit-invoice-info">编辑增票资质信息</div>

          <div className="invoice-content">
          <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 7 }}
            onFinish={onFinish}
            className="invoice-content-lists"
          >
            <Form.Item label="发票类型" className="invoice-label" name="type">
              <Select onChange={onChangeInvoiceType} disabled>
                <Option value="0">个人</Option>
                <Option value="1">公司</Option>
                <Option value="2">专用发票</Option>
              </Select>
            </Form.Item>
            <Form.Item label="抬头" className="invoice-label" name="company"
                       rules={[
                         {
                           required: true,
                           message: '抬头不能为空',
                         },
                       ]}>
                <Input placeholder="请输入发票抬头" />
            </Form.Item>
            {
              invoiceType !== '0'&&(
                <Form.Item label="税号" className="invoice-label" name="tax_nums"
                           rules={[
                             {
                               required: true,
                               message: '税号不能为空',
                             },
                           ]}>
                  <Input placeholder="请输入税号"/>
                </Form.Item>
              )
            }
            {
              invoiceType !== '0' && invoiceType !== '1' && (
                <>
                  <Form.Item label="注册地址" className="invoice-label" name="address"
                             rules={[
                               {
                                 required: true,
                                 message: '注册地址不能为空',
                               },
                             ]}>
                    <Input placeholder="请输入注册地址" />
                  </Form.Item>
                  <Form.Item label="注册电话" className="invoice-label" name="mobile"
                             rules={[
                               {
                                 required: true,
                                 message: '注册电话不能为空',
                               },
                             ]}>
                    <Input placeholder="请输入注册电话" />
                  </Form.Item>
                  <Form.Item label="开户银行" className="invoice-label" name="bank_name"
                             rules={[
                               {
                                 required: true,
                                 message: '开户银行不能为空',
                               },
                             ]}>
                    <Input placeholder="请输入开户银行" />
                  </Form.Item>
                  <Form.Item label="银行账户" className="invoice-label" name="bank_cardid"
                             rules={[
                               {
                                 required: true,
                                 message: '银行账户不能为空',
                               },
                             ]}>
                    <Input placeholder="请输入银行账户" />
                  </Form.Item>
                </>
              )
            }
              <Button
                className="preservation-btn" 
                type="primary"
                htmlType="submit"
              >保存</Button>
          </Form>
        </div>

        </div>
      </div>
  )
}
export default EditInvoice