import React, { useState, useEffect} from 'react'
import './index.scss'
import Qs from 'qs'
import axios from '@/api'
import Cookies from 'js-cookie'
import queryString from 'querystring'

import addressSelect from '@/assets/images/address.png'
import address from '@/assets/images/address1.png'

import { Checkbox, Radio, Input, message } from 'antd'
import Lnk from '@/components/lnk'

const OrderIntegral = (props) => {
  document.title = '提交订单'

  let token = Cookies.get('token') || ''
  const [propsParams] = useState(queryString.parse(props.location.search.substr(1)))
  const [addressList, setAddressList] = useState([])
  const [addressChecked, setAddressChecked] = useState(0) // 选择的地址索引
  const [orderInfo, setOrderInfo] = useState({})
  const [orderCartList, setOrderCartList] = useState({}) //商品信息内容
  const [isInvoice, setIsInvoice] = useState(false) // 是否需要发票
  const [invoiceType, setInvoiceType] = useState(0) // 发票类型
  const [invoiceTitle, setInvoiceTitle] = useState(0) // 发票抬头
  const [leaveMessage, setLeaveMessage] = useState('') // 买家留言

   useEffect(() => {
    $getAddressList()
    $getCommodityList()
  }, [token])

  // 获取地址列表
  const $getAddressList = () => {
    let params = Qs.stringify({
      token,
      channel: 1
    })
    axios.post('User/addressList', params).then(res => {
      let addressList = Array.isArray(res.result) ? res.result : []
      addressList.forEach((element, index) => {
        if (element.is_default === '1') {
          setAddressChecked(index)
        }
      })
      setAddressList(addressList)
    })
  }
  // 获取订单信息
  const $getCommodityList = () => {
    let params = Qs.stringify({
      channel: 1,
      token,
    })
    axios.post('Integral/checkBuy', params).then((res) => {
      setOrderInfo(res.result)
      setOrderCartList(res.result.cartList)
    })
  }
  // 发票抬头
  const $onInputInvoiceCode = (e) => {
    e.persist()
    // let value = e.target.value
    // setInvoiceTitleCode(value)
  }

  // 买家留言
  const $onInputLeaveMessage = (e) => {
    e.persist()
    let value = e.target.value
    setLeaveMessage(value)
  }

  // 下单
  const $buy = () => {
    let params = Qs.stringify({
      token,
      channel: 1,
      ress_id: addressList[addressChecked].address_id,
      user_note: leaveMessage,
      coupon_id: '',
      couponCode: '',
      pay_points: '',
      user_money: '',
      act: 'submit_order',
      invoice_id: '',
      is_pick_up: 0
    })
    axios.post('Integral/dealBuy', params).then(res => {
      console.log('res',res)
      let params = Qs.stringify({
        channel: 1,
        token,
        order_sn: res.result.order_sn,
        pay_type: 'alipayPc'
      })
      axios.post('Integral/dealOrder',params).then((res) => {
        message.success(res.msg)
        props.history.push('/manage/exchange-records')
      })
    })
    
  }
  let { cartList = {} } = orderInfo

  return (
    <div className="order-integral">
      <div className="context">
        {/* 导航 */}
        <div className="order-navigation">
          <span className="navigation-left">
            <Lnk target="_blank" to="" className="navigation-left">首页</Lnk>
          </span>
          <span className="navigation-icon">></span>
          <span className="navigation-right">提交订单</span>
        </div>

        {/* 地址列表 */}
        <div className="order-address-list">
          <div className='address-tips'>
            <span>收货信息</span> 亲，为确保给您送货入户，下单地址务必精确到几幢几楼或几组几号哦~
          </div>
          <div className='address-body'>
            {
              Array.isArray(addressList) && addressList.map((element, index) => {
                return (
                  <div className='address-item' key={element.address_id} onClick={() => setAddressChecked(index)} style={{ backgroundImage: `url(${addressChecked === index ? addressSelect : address})` }}>
                    <div className='address-info'>
                      {element.province_name}{element.city_name}{element.consignee}(收)
                    </div>
                    <div className='address-details'>
                      {element.district_name} {element.address}
                    </div>
                    <div className='user-phone'>
                      {element.mobile}
                    </div>
                    <span className='editor'>修改</span>
                  </div>
                )
              })
            }
          </div>
          <div className='address-options'>
            <span>显示全部地址</span>
            <span>
              <Lnk target="_blank" to="manage/shipping-address?pid=4&cid=16" className="manage-address">管理收货地址</Lnk>
            </span>
          </div>
        </div>

        {/* 商品信息 */}
        <div className="order-goods-info">
          <div className="goods-info">商品信息</div>
          <div className="goods-other">单价</div>
          <div className="goods-other">数量</div>
          <div className="goods-other">小计(元)</div>
        </div>
        {/* 商品信息内容 */}
        <div>
          {
            orderCartList && Array.isArray(orderCartList.cartList) && orderCartList.cartList.map((element,index) => {
              return (
                <div className="goods-info-content" key={index}>
                  <div className="content-img">
                    <img src={element.pic} alt=""/>
                    <span>{element.integral_name}</span>
                  </div>
                  <div className="content-other">{element.old_exchange_integral}积分</div>
                  <div className="content-other">{element.integral_num}</div>
                  {
                    element.integral_price === '0.00'
                    ?
                    <div className="content-other">{element.integral_fee}积分</div>
                    :
                    <div className="content-other">{element.integral_fee}积分 + ￥{(element.integral_price * element.integral_num).toFixed(2)}</div>
                  }
                </div>
              )
            })
          }
        </div>
        

        {/* 发票 */}
        <div className="order-invoice">
          {
            cartList.total_price && cartList.total_price.total_fee !== 0
            ?
            (
              <>
                <div className='invoice-title'>
                  发票/优惠信息
                </div>
                <div className='invoice-item'>
                  <Checkbox
                    checked={isInvoice}
                    onChange={(event) => setIsInvoice(event.target.checked)}
                    className="invoice-style"
                  >开发票</Checkbox>
                  {
                  isInvoice && (
                    <div>
                      <div className='invoice-item'>
                        <div className='invoice-item-label'>发票类型</div>
                        <div className='invoice-item-value'>
                          <Radio.Group onChange={(event) => setInvoiceType(event.target.value)} value={invoiceType}>
                            <Radio value={0}>电子普通发票</Radio>
                            <Radio value={1}>增值税专用发票</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className='invoice-item'>
                        <div className='invoice-item-label'>发票抬头</div>
                        <div className='invoice-item-value'>
                          <Radio.Group onChange={(event) => setInvoiceTitle(event.target.value)} value={invoiceTitle}>
                            <Radio value={0}>个人</Radio>
                            <Radio value={1}>企业</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                      <div className='invoice-item'>
                        <div className='invoice-item-label'>发票抬头</div>
                        <div className='invoice-item-value'>
                          <Input className='invoice-title-code' onChange={$onInputInvoiceCode} type='text' />
                        </div>
                      </div>
                      <div className='invoice-item invoice-intro'>
                        御秀开具的电子发票均为真实有效的合法发票，与传统纸质发票具有同等法律效力，可作为用户维权、保修、报销的      有效凭据。
                        电子发票会在确认收货后15天内，将发票代码和发票号码以短信方式发送到收货人手机上。
                        开具成功后，您可登录电子发票查询系统 https://inv-veri.chinatax.gov.cn下载电子发票
                      </div>
                    </div>
                  )
                  }
                </div>
              </>
            )
            :
            <div></div>
          }
          <div className='invoice-item'>
            <div className='invoice-item-label'>买家留言</div>
            <div className='invoice-item-value'>
              <Input className='invoice-title-code' onChange={$onInputLeaveMessage} style={{ width: '100%' }}type='text' />
            </div>
          </div>
        </div>

        {/* 结算 */}
        <div className='order-settlement'>
          <div className='settlement-item'>
            <span className='label'>商品合计：</span>
            {
              cartList.total_price && cartList.total_price.total_fee === 0
              ?
              (<span className='value'>{cartList.total_price && cartList.total_price.total_point}积分</span>)
              :
              (<span className='value'>{cartList.total_price && cartList.total_price.total_point}积分 + ¥{cartList.total_price && cartList.total_price.total_fee}</span>)
            }
            
          </div>
          <div className='settlement-item'>
            <span className='label'>运费：</span>
            <span className='value'>￥{cartList.total_price && cartList.total_price.total_shipping}</span>
          </div>
          <div className='settlement-item'>
            <span className='label label-other'>配送至：</span>
            <span className='value'>{addressList[addressChecked] && addressList[addressChecked].province_name + addressList[addressChecked].city_name + addressList[addressChecked].district_name + addressList[addressChecked].address}</span>
          </div>
          <div className='settlement-item'>
            <span className='label label-other'>收货人：</span>
            <span className='value'>{addressList[addressChecked] && addressList[addressChecked].consignee + ' ' + addressList[addressChecked].mobile}</span>
          </div>
          <div className='settlement-item buy-commodity-btn'>
            <span>商品数量：{cartList.total_price && cartList.total_price.num}个</span>
            <span>商品总金额：{cartList.total_price && cartList.total_price.total_point}积分</span>
            <span>付款金额：<span className='money'>{cartList.total_price && cartList.total_price.total_fee.toFixed(2)}</span> 元</span>
            <button className='buy-btn' onClick={$buy}>下单</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderIntegral
