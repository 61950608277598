import { combineReducers } from 'redux';

import login from './login'

// import hello from './Reducer2'

const rootReducer = combineReducers({
    login,
    // hello
})

export default rootReducer