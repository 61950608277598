import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { StoreContext } from 'redux-react-hook'
import { store } from './redux/store'
import './index.css'
import './assets/css/iconfont.css'
import RouterConfig from './router'
import * as serviceWorker from './serviceWorker'


ReactDOM.render(
  <StoreContext.Provider value={store}>
    <RouterConfig />
  </StoreContext.Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
