import React, { memo } from 'react'
import './index.scss'

const Title = ({ title, describe }) => {
  return (
    <div className='title'>
      <div className='lv1'>{title}</div>
      <div className='lv2'>{describe}</div>
    </div>
  )
}

export default memo(Title)